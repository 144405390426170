import styled from 'styled-components';
import MEDIA_QUERY from '../../../constants/mediaQuery';
import { useLocation } from 'react-router-dom';

const TopButton = () => {
  const navigate = useLocation();
  const IS_ADMIN = navigate.pathname.includes('/ilsang-admin');

  const handleScrollTopClick = () => {
    document.documentElement.scrollTop = 0;
  };

  return (
    // 어드민 페이지에서 보이지 않게 처리
    <>{!IS_ADMIN && <ScrollTopButton src="/images/topButton.svg" alt="상단바 버튼" onClick={handleScrollTopClick} />}</>
  );
};

export default TopButton;

const ScrollTopButton = styled.img`
  position: fixed;
  right: 20%;
  bottom: 5.4rem;
  cursor: pointer;

  ${MEDIA_QUERY.md} {
    right: 5%;
  }

  ${MEDIA_QUERY.sm} {
    width: 5.5rem;
    bottom: 3rem;
  }

  ${MEDIA_QUERY.xs} {
    bottom: 1rem;
  }
`;
