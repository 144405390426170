import styled from 'styled-components';
import ContactForm from '../../../components/common/ContactForm';
import { Helmet } from 'react-helmet-async';
import MEDIA_QUERY from '../../../constants/mediaQuery';

const PartnershipsPage = () => {
  return (
    <>
      <Helmet>
        <title>Partnerships — 눈부신 일상</title>
      </Helmet>

      <Container>
        <OuterWrapper>
          <InnerWrapper>
            <ContactForm />
          </InnerWrapper>
        </OuterWrapper>
      </Container>
    </>
  );
};

export default PartnershipsPage;

const Container = styled.div`
  height: 100%;
  background-color: #fff;
`;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.lg} {
    margin-top: -3rem;
  }
`;
