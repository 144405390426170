import { css } from 'styled-components';

export const fonts = css`
  @font-face {
    font-family: 'MapoFlowerIsland';
    src: local('MapoFlowerIsland'),
      url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/MapoFlowerIslandA.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gulim';
    src: local('Gulim'), url('https://db.onlinewebfonts.com/t/d9521b14999b76104e98b3d2f96079a1.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'ArgentumSans';
    src: local('ArgentumSans'),
      url('https://db.onlinewebfonts.com/t/c66b3ec05e21dfd6e3f9dfe5fc376d3c.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'ArgentumSansLight';
    src: local('ArgentumSansLight'), url('/fonts/ArgentumSans-Regular.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'ArgentumSansRegular';
    src: local('ArgentumSansRegular'), url('/fonts/ArgentumSans-Regular.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'ArgentumSansBold';
    src: local('ArgentumSansBold'), url('/fonts/ArgentumSans-Bold.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'ArgentumSansExtraBold';
    src: local('ArgentumSansExtraBold'), url('/fonts/ArgentumSans-ExtraBold.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'S-CoreDream-3Light';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff') format('woff');
}

  @font-face {
    font-family: 'NanumSquareNeoLight';
    src: local('NanumSquareNeoLight'), url('/fonts/NanumSquareNeoTTF-aLt.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'NanumSquareNeoRegular';
    src: local('NanumSquareNeoRegular'), url('/fonts/NanumSquareNeoTTF-bRg.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'NanumSquareNeoBold';
    src: local('NanumSquareNeoBold'), url('/fonts/NanumSquareNeoTTF-cBd.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'NanumSquareNeoExtraBold';
    src: local('NanumSquareNeoExtraBold'), url('/fonts/NanumSquareNeoTTF-dEb.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'Gowun Batang', serif;
    src: 'https://fonts.googleapis.com/css2?family=Gowun+Batang:wght@400;700&display=swap';
  }

  @font-face {
    font-family: 'Pretendard-Light';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

  @font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

  @font-face {
    font-family: 'Pretendard-Medium';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

  @font-face {
    font-family: 'Pretendard-Bold';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

  @font-face {
    font-family: 'NEXON Lv1 Gothic OTF';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/NEXON Lv1 Gothic OTF.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

  @font-face {
    font-family: 'NEXON Lv1 Gothic OTF Bold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/NEXON Lv1 Gothic OTF Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
`;
