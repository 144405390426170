import styled from 'styled-components';
import MEDIA_QUERY from '../../../constants/mediaQuery';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '../../../hook/useMediaQuery';

const MainTitleCard = () => {
  const navigate = useLocation();

  // 페이지 별 분기처리
  const IS_PORTFOLIO = navigate.pathname.includes('/portfolio');
  const IS_PRICE = navigate.pathname.includes('/price');
  const IS_BRANCHES = navigate.pathname.includes('/branches');
  const IS_CONTACT = navigate.pathname.includes('/contact');

  // 스크린 사이즈 감지
  const { isSm } = useMediaQuery();

  const HIDDEN_MAIN_TITLE_CARD = isSm && (IS_PORTFOLIO || IS_PRICE || IS_BRANCHES || IS_CONTACT);

  return !HIDDEN_MAIN_TITLE_CARD ? (
    <MainTitleWrapper>
      <MainTitleInnerWrapper>
        <MainText>오늘도 당신의 눈부신 일상...</MainText>
      </MainTitleInnerWrapper>
    </MainTitleWrapper>
  ) : null;
};

export default MainTitleCard;

const MainTitleWrapper = styled.div`
  width:90%;
  margin:0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainTitleInnerWrapper = styled.div`
  width: 67.5rem;
  display: flex;
  justify-content: right;
  align-items: center;

  ${MEDIA_QUERY.lg} {
    margin: 14rem 0 10rem;
  }

  ${MEDIA_QUERY.md} {
    margin: 16.5rem 0 10rem;
  }

  ${MEDIA_QUERY.sm} {
    justify-content: center;
    margin: 11.4rem 0 8.4rem;
  }
`;

const MainText = styled.span`
  font-family: 'MapoFlowerIsland';
  color: #424530;
  font-size: 1.2rem;
  letter-spacing: 0.96px;
  line-height: 15px;

  ${MEDIA_QUERY.sm} {
    font-size: 1.3rem;
    letter-spacing: 1.04px;
  }
`;
