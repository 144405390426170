import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const userAtom = atom({});

export const tokenAtom = atomWithStorage('Token', '');

export const mainTokenAtom = atomWithStorage('mainToken', '');

export const isAdminModeAtom = atomWithStorage('mode', '');
