import { ReactNode, Ref, forwardRef, useEffect, useRef, useState } from 'react';
import { frnachiseeHTML } from '../franchisee/franchiseeHTML';
import styled, { keyframes } from 'styled-components';

const FranchiseeMain = () => {
  const [open, setOpen] = useState<boolean>(false);

  const introRef = useRef<HTMLDivElement>(null);

  const brandIntroRef = useRef<HTMLDivElement>(null);

  const supportRef = useRef<HTMLDivElement>(null);

  const sectionsRef = [introRef, brandIntroRef, supportRef]

  useEffect(() => {
    // IntersectionObserver 객체 생성
    let riseObserver = new IntersectionObserver((iEls, iObserver) => {
      iEls.forEach((iEl) => {
        if (iEl.isIntersecting) {
          // animate 클래스 추가
          iEl.target.classList.add('g_rise_up');
          // 검사 중지
          iObserver.unobserve(iEl.target);
          // } else {
          //     iEl.target.classList.remove("g_rise_up");
        }
      });
    });

    // rade-up 클래스를 가진 요소들을 모두 찾아서 observer에 등록
    document.querySelectorAll('.g_animate.g_rise').forEach((iEl) => {
      riseObserver.observe(iEl);
    });

    // 스크롤 이벤트
    document.querySelectorAll('.l_menu').forEach((iEl) => {
      iEl.addEventListener('click', (e) => {
        if (!(e.target instanceof HTMLDivElement)) return;

        let scroll_id = e.target.getAttribute('data-scroll_id');
        if (scroll_id === null) return;

        let scroll_el = document.getElementById(scroll_id);
        if (scroll_el) {
          scroll_el.scrollIntoView({ behavior: 'smooth' });
        }
      });
    });

    // 스크롤시 해당 요소에 보이는 퍼센트가 큰 것의 id와 동일한 .l_menu에 data-scroll_id 값에 해당하는 요소에 .selected 클래스 추가
    let indicateObserver = new IntersectionObserver((iEls, iObserver) => {
      iEls.forEach((iEl) => {
          if ( iEl.isIntersecting ) {
              let scroll_id = iEl.target.getAttribute("id");
              if ( scroll_id ) {
                  document.querySelectorAll(".l_menu").forEach((iEl) => {
                      if ( iEl.getAttribute("data-scroll_id") === scroll_id ) {
                          iEl.classList.add("selected");
                      } else {
                          iEl.classList.remove("selected");
                      }
                  });
              }
          }
      });
  }, {
      threshold: 0.5
  });

  document.querySelectorAll(".g_section").forEach((iEl) => {
      indicateObserver.observe(iEl);
  });

    let fadeObserver = new IntersectionObserver((iEls, iObserver) => {
      iEls.forEach((iEl) => {
        if (iEl.isIntersecting) {
          // animate 클래스 추가
          iEl.target.classList.add('g_fade_in');
          // 검사 중지
          iObserver.unobserve(iEl.target);
        }
      });
    });

    document.querySelectorAll('.g_animate.g_fade').forEach((iEl) => {
      fadeObserver.observe(iEl);
    });
  }, []);


  const onIntro = () => {
    introRef.current?.scrollIntoView({ behavior: 'smooth' });
    setOpen((open) => !open)
  };

  const onBrandIntro = () => {
    brandIntroRef.current?.scrollIntoView({ behavior: 'smooth' });
    setOpen((open) => !open)
  }

  const onSupportRefIntro = () => {
    supportRef.current?.scrollIntoView({ behavior: 'smooth' });
    setOpen((open) => !open)
  }


  const StyledHeader = styled.header`
&.g_header {
  background-color: #fff;
  height: 130px;
  position: sticky;
  top: 0;
  z-index: 1;

  .g_content_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    inset: 0;
    margin: auto;
    max-width: 1169px;
    padding: 0 1rem;
  }

  .g_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .g_logo img {
    height: 72px;
    max-width: 100%;
    vertical-align: top;
  }

  .g_logo img {
    height: 72px;
    max-width: 100%;
    vertical-align: top;
  }
  .g_main_menu {
    font-family: "Pretendard-Bold";
    font-size: 1.6rem;
    letter-spacing: -0.4px;
    line-height: 2.2;
  }
  .g_main_menu ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    list-style: none;
  }
  .g_main_menu ul li button {
    border: 0;
    background-color: transparent;
    padding: 10px;
    color: #b9b9b9;
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
  }
  .g_main_menu ul li button:hover {
    color: #1E1F18;
  }
  .g_main_menu ul li button.selected {
    color: #000;
  }
  .g_button {
    all: unset;
    display: none;
    background-size: 30px 30px;
    background-position: center;
  }
  .g_button:hover {
    cursor: pointer;
  }
}

// 모바일 헤더
&.g_mobile {
  height: 108px;

  .g_content_wrap {
    max-width: 686px;
    display: block;

    .g_logo {
      text-align: center;
      width: 8.64rem;
      height: 4.5rem;
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: center;

       img {
        object-fit: contain;
        max-width: 100%;

       }
    }

    .g_main_menu {
      display: none;
    }

    .g_button {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 30px;
      background-image: url('${process.env.PUBLIC_URL}/images/common/header_menu.png');
      background-repeat: no-repeat;
      background-position: center center;
      padding: 0 2rem;

    } 
  }
}

&.g_mobile.open {
  .g_main_menu {
    display: flex;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    align-items: center;

    ul {
    display: flex;
    max-height: 722px;
    max-width: 506px;
    list-style: none;
    align-items: flex-start;
    height: auto;
    flex-direction: column;
    padding: 0 4rem;
    width: 100%;
    justify-content: space-between;
    gap: 3.3rem;
    margin: 120px auto auto auto;

     li button {
      font-size: 1.41rem;
      white-space: nowrap;
     }
    }

  }

  .g_button {
    background-image: url('${process.env.PUBLIC_URL}/images/common/header_exit.png');


  } 
}

`

  const isMobile = () => {
    const mobileCheck = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    if (mobileCheck) console.log("모바일입니다.");

    return mobileCheck
  }
  const mobileView = isMobile();

  console.log(introRef)

  return (
    <StyledMain className={`${mobileView ? 'g_mobile' : ''}`}>
      {!mobileView && <>

        <StyledHeader className='g_header'>
          <div className="g_content_wrap">
            <a className="g_logo" style={{ display: 'block' }} href="https://ilsangst.com/">
              <img src="/images/common/logo.png" alt="눈부신 일상 로고" />
            </a>
            <nav className="g_main_menu">
              <ul>
                <li>
                  <NavButton content="브랜드 소개" onClick={() => onIntro()} />
                </li>
                <li>
                  <NavButton content="창업 경쟁력" onClick={() => onBrandIntro()} />
                </li>
                <li>
                  <NavButton content="창업지원" onClick={() => onSupportRefIntro()} />
                </li>
              </ul>
            </nav>
            <button type="button" className="g_button" id="header-btn" onClick={() => setOpen(!open)}></button>
          </div>
        </StyledHeader>

        {/* <div dangerouslySetInnerHTML={{ __html: frnachiseeHTML }}></div> */}

        <ContentPage imgUrl="/images/section1/section_1.png" bgUrl="/images/section1/franchisee_bg1.png" padding=''/>
        <IntroPage ref={introRef} bgUrl="/images/section2/franchisee_bg2.png" imgUrl="/images/section2/section_2.png" />
        <Banner imgUrl="/images/common/section_banner.png" bgUrl="" />
        <ContentPage imgUrl="/images/section16/section_16.png" bgUrl="/images/section16/franchisee_bg16.png" />
        <ContentPage imgUrl="/images/section17/section_17.png" bgUrl="/images/section17/franchisee_bg17.png" />
        <BrandPage ref={brandIntroRef} imgUrl="/images/section3/section_3.png" bgUrl="/images/section3/franchisee_bg3.png" />
        <ContentPage imgUrl="/images/section4/section_4.png" bgUrl="/images/section4/franchisee_bg4.png" />
        <ContentPage imgUrl="/images/section5/section_5.png" bgUrl="/images/section5/franchisee_bg5.png" />
        <ContentPage imgUrl="/images/section6/section_6.png" bgUrl="/images/section6/franchisee_bg6.png" />
        <ContentPage imgUrl="/images/section7/section_7.png" bgUrl="/images/section7/franchisee_bg7.png" />
        <ContentPage imgUrl="/images/section8/section_8.png" bgUrl="/images/section8/franchisee_bg8.png" />
        <ContentPage imgUrl="/images/section9/section_9.png" bgUrl="/images/section9/franchisee_bg9.png" />
        <ContentPage imgUrl="/images/section10/section_10_1.png" bgUrl="/images/section10/franchisee_bg10.png" inline="100vh" />


        <ContentPage imgUrl="/images/section11/section_11.png" bgUrl="/images/section11/franchisee_bg11.png" />
        <SupportPage ref={supportRef} imgUrl="/images/section12/section_12.png" bgUrl="/images/section12/franchisee_bg12.png" />

        <ContentPage imgUrl="/images/section13/section_13.png" bgUrl="/images/section13/franchisee_bg13.png" />
        <ContentPage imgUrl="/images/section14/section_14.png" bgUrl="/images/section14/franchisee_bg14.png" />
        <ContentPage imgUrl="/images/section15/section_15.png" bgUrl="/images/section15/franchisee_bg15.png" last={true}/>
        <Footer imgUrl="/images/common/section_footer.png" bgUrl="" />
      </>
      }

      {!!mobileView && <>
        <StyledHeader className={`${open && 'open'} g_header g_mobile`}>
          <StyledWrapper className="g_content_wrap">
            <a className="g_logo" href="https://ilsangst.com/">
              <img src="/images/common/logo.png" alt="눈부신 일상 로고" />
            </a>
            <nav className="g_main_menu">
              <ul>
                <li>
                  <NavButton content="브랜드 소개" onClick={() => onIntro()} />
                </li>
                <li>
                  <NavButton content="창업 경쟁력" onClick={() => onBrandIntro()} />
                </li>
                <li>
                  <NavButton content="창업지원" onClick={() => onSupportRefIntro()} />
                </li>
              </ul>
            </nav>
            <button type="button" className="g_button" id="header-btn" onClick={() => setOpen(!open)}></button>
          </StyledWrapper>

        </StyledHeader>

        <ContentPage imgUrl="/images/section1/mobile_1.png" bgUrl="/images/section1/franchisee_bg1_m.png" />
        <IntroPage ref={introRef} bgUrl="/images/section2/franchisee_bg2_m.png" imgUrl="/images/section2/mobile_2.png" mobileView={mobileView} />
        <Banner imgUrl="/images/common/section_banner.png" bgUrl="" />
        <ContentPage imgUrl="/images/section16/mobile_16.png" bgUrl="/images/section16/franchisee_bg16_m.png" />
        <ContentPage imgUrl="/images/section17/mobile_17.png" bgUrl="/images/section17/franchisee_bg17_m.png" />
        <BrandPage ref={brandIntroRef} imgUrl="/images/section3/mobile_3.png" bgUrl="/images/section3/franchisee_bg3_m.png" mobileView={mobileView} />
        <ContentPage imgUrl="/images/section4/mobile_4.png" bgUrl="/images/section4/franchisee_bg4_m.png" />
        <ContentPage imgUrl="/images/section5/mobile_5.png" bgUrl="/images/section5/franchisee_bg5_m.png" />
        <ContentPage imgUrl="/images/section6/mobile_6.png" bgUrl="/images/section6/franchisee_bg6_m.png" />
        <ContentPage imgUrl="/images/section7/mobile_7.png" bgUrl="/images/section7/franchisee_bg7_m.png" />
        <ContentPage imgUrl="/images/section8/mobile_8.png" bgUrl="/images/section8/franchisee_bg8_m.png" />
        <ContentPage imgUrl="/images/section9/mobile_9.png" bgUrl="/images/section9/franchisee_bg9_m.png" />
        <ContentPage imgUrl="/images/section10/mobile_10.png" bgUrl="/images/section10/franchisee_bg10_m.png" />
        <ContentPage imgUrl="/images/section11/mobile_11.png" bgUrl="/images/section11/franchisee_bg11_m.png" />
        <SupportPage ref={supportRef} imgUrl="/images/section12/mobile_12.png" bgUrl="/images/section12/franchisee_bg12_m.png" mobileView={mobileView} />

        <ContentPage imgUrl="/images/section13/mobile_13.png" bgUrl="/images/section13/franchisee_bg13_m.png" />
        <ContentPage imgUrl="/images/section14/mobile_14.png" bgUrl="/images/section14/franchisee_bg14_m.png" />
        <ContentPage imgUrl="/images/section15/mobile_15.png" bgUrl="/images/section15/franchisee_bg15_m.png" />
        <Footer imgUrl="/images/common/mobile_footer.png" bgUrl="" />

      </>}


      <FloatButton />


    </StyledMain>
  );
};

type pageProps = {
  bgUrl: string;
  imgUrl: string;
  padding?: string;
  mobileView?: boolean;
  inline?: string;
  last?: boolean;
};

const ContentPage = ({ bgUrl, imgUrl, padding, inline, last }: pageProps) => {
  return (
    <StyledSection className="g_section section_2" bgImg={bgUrl} inline={inline ? inline : undefined}>
      <StyledWrapper className="g_content_wrap" padding={padding && ''}>
        <img src={imgUrl}  />
      </StyledWrapper>
    </StyledSection>
  );
};

const Footer = ({ bgUrl, imgUrl, padding }: pageProps) => {
  return (
    <div className="g_section" style={{ backgroundColor: '#222222' }}>
      <StyledWrapper className="g_content_wrap footer">
        <img src={imgUrl} style={{ verticalAlign: 'top', overflow: 'hidden' }} />
      </StyledWrapper>
    </div>
  );
};

const Banner = ({ bgUrl, imgUrl, padding }: pageProps) => {
  return (
    <StyledBannerSection>
      <StyledWrapper>
        <img src={imgUrl} className='banner_origin' />
        <img src={imgUrl} className='banner_clone' />
      </StyledWrapper>
    </StyledBannerSection>
  );
};

const IntroPage = forwardRef<HTMLDivElement, pageProps>(({ bgUrl, imgUrl, mobileView }, ref) => {
  return (
    <StyledSection bgImg={bgUrl} ref={mobileView ? undefined : ref} id='intro'>
      <StyledWrapper className="g_content_wrap" >
        {mobileView && <div ref={ref} className='mobile-sight'></div>}
        <img src={imgUrl} />
      </StyledWrapper>
    </StyledSection>
  );
});

const BrandPage = forwardRef<HTMLDivElement, pageProps>(({ bgUrl, imgUrl, padding, mobileView }, ref) => {
  return (
    <>
      <StyledSection bgImg={bgUrl} ref={mobileView ? undefined : ref}>
        <StyledWrapper className="g_content_wrap">
          {mobileView && <div ref={ref} className='mobile-sight'></div>}
          <img src={imgUrl} />
        </StyledWrapper>
      </StyledSection>
    </>
  );
});

const SupportPage = forwardRef<HTMLDivElement, pageProps>(({ bgUrl, imgUrl, padding, mobileView }, ref) => {
  return (
    <StyledSection bgImg={bgUrl} ref={mobileView ? undefined : ref}>
      <StyledWrapper className="g_content_wrap">
        {mobileView && <div ref={ref} className='mobile-sight'></div>}
        <img src={imgUrl}  />
      </StyledWrapper>
    </StyledSection>
  );
});

const FloatButton = () => {
  return <StyledButton><a className='g_btn g_link' href='https://docs.google.com/forms/d/e/1FAIpQLSek9gMak1XGMLpcYN6xFjKpKb_3z_sRMT87g4qqVjiB0fIrKA/viewform'>
    </a></StyledButton>
}

const StyledButton = styled.div`
position: fixed;
bottom: 5.8rem;
z-index: 1;
display: flex;
justify-content: center;
width: 100vw;


& .g_btn {
  cursor: pointer;
  &.g_link {
    font-family: "NanumSquare Neo Bold";
    font-size: 1.6rem;
    letter-spacing: -0.4px;
    // background: linear-gradient(270deg, #16CE9C, #14E6EE);
    background: transparent;
    background-image: url(${process.env.PUBLIC_URL}/images/common/footer_button.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 7.5rem;
    display: inline-block;
    width: 22rem;
    background-position: center center;
    color: #fff;
    text-decoration: none;
    border-radius: 44px;
    border: none;
  }
}
`

const StyledWrapper = styled.div<{padding?: string;}>`
  padding: 9px 45px;
  padding: ${(props) => props.padding && '' };

  & img {
    vertical-align: top;
    width: 1800px;
  }

  & .mobile-sight {
    position: absolute;
    height: 150px;
    opacity: 0;
    top: -80px;
    left: 0;
  }

  &.footer {
    padding: 0;

    img {
      width: 1200px;
    }
  }
`;

const StyledSection = styled.section<{ bgImg: string, inline?: string; }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.inline ? '' : '100vh'};
  min-height: ${(props) => props.inline ? '1000px' : ''};
  background-size: cover;
  background-image: url(${(props) => props.bgImg});
  overflow: hidden;

  @media only screen and (max-width: 1201px) { 
    height: auto;
    img {
      // width: 1170px;
    }

    ${StyledWrapper} img {
      max-width: 100%;
      transform: 0;
    }
  }

  @media only screen and (min-width: 1205px) { 
    min-height: 100vh;
    img {
      // width: 1170px;
    }

    ${StyledWrapper} img {
      max-width: 100%;
      transform: 0;
    }
  }
`;

const kfbanner_1 = keyframes`
0% {
  transform: translateX(0%);
}
50% {
  transform: translateX(-100%);
}
50.1% {
  transform: translateX(100%);
}
100% {
  transform: translateX(0%);
}

`

const kfbanner_2 = keyframes`
0% {
  transform: translateX(0%);
}
100% {
  transform: translateX(-200%);
}

`

const StyledBannerSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 11rem;
  background-color: #Ffff;
  overflow-x: hidden;
  overflow-y: hidden;
  
  
  & > ${StyledWrapper} {
    max-width: 1740px;
    display: flex;
  }

  & img {
    object-fit: contain;
    max-height: 11rem;
  }

  & .banner_origin {
    // position: relative;
    display: flex;
    animation: 90s linear infinite ${kfbanner_1};
  }

  & .banner_clone {
    animation: 90s linear infinite ${kfbanner_2};
    display: flex;
  }
`;




const StyledMain = styled.main`
& ${StyledSection} {
  background-repeat: no-repeat;
  background-position: center;
}

&.g_mobile {

  ${StyledSection} {
    height: auto;
    background-position: center center;
    width: 100%;
  }

  img {
  }

  ${StyledWrapper} {
    max-width: 506px;
  }

  ${StyledBannerSection} {
    
    & > ${StyledWrapper} {
      max-width: 1740px;
    }

    img {
      max-height: 11rem;
      object-fit: cover;
    }
  }
  
  ${StyledWrapper}.footer {
    max-width: 506px;
    margin: 0 auto;

     img {
      width: auto;
      object-position: left center;
      transform: translateX(0);
      max-width: 100%;
     }
  }
}
`

// const

const NavButton = ({ content, onClick }: { content: string; onClick: () => void }) => {

  return (
    <button className="l_menu" type="button" onClick={onClick} id='intro'>
      {content}
    </button>
  );
};

export default FranchiseeMain;
