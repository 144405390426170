import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import useMediaQuery from '../../../../hook/useMediaQuery';
import MEDIA_QUERY from '../../../../constants/mediaQuery';
import client from '../../../../api/axios';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../../../stores';
import { message } from 'antd';
import { checkValidationImage } from '../../../../utils';
import { PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export type PortfolioDataType = {
  id: number;
  img_url: string;
  is_cancel: string;
  order_num: number;
  portfolio_id: number;
  direction: string;
};

export type uploadImageDataType = {
  category: string;
  img_url: string;
};

export type ResultsType = {
  [key: string]: PortfolioDataType[];
};

const renderImage = (
  dropId: string,
  photoItems: any[],
  isHover: boolean[],
  isActiveEditImage: boolean[],
  fileUrl: string[],
) => {
  return photoItems.map((image, idx) => (
    <Draggable draggableId={`${dropId}-${idx}`} index={idx} key={idx}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{ position: 'relative' }}
        >
          <PhotoItem
            src={isActiveEditImage[idx] && fileUrl[idx] ? fileUrl[idx] : image.img_url}
            isHover={isHover[idx]}
            isEditing={!isActiveEditImage[idx] && isHover[idx]}
          />
        </div>
      )}
    </Draggable>
  ));
};

const AdminBabyFamilyPage = () => {
  const { isSm, isLg } = useMediaQuery();

  const [userToken] = useAtom(tokenAtom);

  // 이미지 추가
  const [isLeftCreateMode, setIsLeftCreateMode] = useState(false);
  const [isRightCreateMode, setIsRightCreateMode] = useState(false);

  // 이미지 미리보기 (이미지 추가)
  const [leftSingleFileUrl, setLeftSingleFileUrl] = useState<string>('');
  const [rightSingleFileUrl, setRightSingleFileUrl] = useState<string>('');

  const [isVisibleCreateInput, setIsVisibleCreateInput] = useState(false);

  const [createModeLeftIndex, setCreateModeLeftIndex] = useState(1);
  const [createModeRightIndex, setCreateModeRightIndex] = useState(1);

  const [isEditingMode, setIsEditingMode] = useState(false);

  const [leftPhotoItems, setLeftPhotoItems] = useState<PortfolioDataType[]>([]);
  const [rightPhotoItems, setRightPhotoItems] = useState<PortfolioDataType[]>([]);

  // 이미지 검증할 때 formData를 위한 state
  const [image, setImage] = useState<any>();
  const [fileUrl, setFileUrl] = useState('');

  const imgRef = useRef<HTMLInputElement>(null);
  const [leftFileUrl, setLeftFileUrl] = useState<string[]>([]);
  const [rightFileUrl, setRightFileUrl] = useState<string[]>([]);

  const [hoveredLeftImageIndex, setHoveredLeftImageIndex] = useState<number | null>(null);
  const [hoveredRightImageIndex, setHoveredRightImageIndex] = useState<number | null>(null);

  const [isActiveEditLeftImage, setIsActiveEditLeftImage] = useState<Record<number, boolean>>({});
  const [isActiveEditRightImage, setIsActiveEditRightImage] = useState<Record<number, boolean>>({});

  // 이미지 순서 변경
  const [imageIdItems, setImageIdItems] = useState<number[]>([]);
  const [items, setItems] = useState(imageIdItems);

  const droppableArr = ['left', 'right'];

  // 재정렬 함수
  const reorder = (list: any, startIndex: any, endIndex: any): any => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 8;
  const getItemStyle = (draggableStyle: any, isDragging: any) => ({
    userSelect: 'none',
    padding: grid * 2,
    marginBottom: grid,
    background: isDragging ? 'lightgreen' : 'grey',
    ...draggableStyle,
  });
  const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    width: 250,
  });

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    // 시작 인덱스와 종료 인덱스
    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    console.log('Started at', startIndex, 'and ended at', endIndex);

    const newItems = reorder(items, startIndex, endIndex);
    setItems(newItems);
  };

  // 페이지의 첫 4개의 사진만 프리로딩
  const imagesToPreload = [
    '/images/portfolio/2.baby&family/bf (2).jpg',
    '/images/portfolio/2.baby&family/bf (4).jpg',
    '/images/portfolio/2.baby&family/bf (1).jpg',
    '/images/portfolio/2.baby&family/bf (3).jpg',
  ];

  const handleChangeLeftCreateMode = () => {
    setIsLeftCreateMode((prev) => !prev);
  };

  const handleChangeRightCreateMode = () => {
    setIsRightCreateMode((prev) => !prev);
  };

  // 포트폴리오 전체 데이터 조회
  const handleQueryPorflioImages = async () => {
    try {
      const response = await client.get('api/v1/portfolios/portfolio/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      let resultsWithMCategories: ResultsType = {};

      Object.keys(response.data.results2).forEach((key: string) => {
        let filteredResults = response.data.results2[key].filter(
          (item: PortfolioDataType) => item.portfolio_id === 4 && item.is_cancel === 'N',
        ); // 카테고리마다 portfolio_id가 다름

        if (filteredResults.length > 0) {
          resultsWithMCategories[key] = filteredResults;
        }
      });

      if (Object.keys(resultsWithMCategories).length > 0) {
        const allItems = Object.values(resultsWithMCategories).flat();

        const filteredLeftItems = allItems.filter((item: PortfolioDataType) => item.direction === 'left');
        const filteredRightItems = allItems.filter((item: PortfolioDataType) => item.direction === 'right');
        const orders = allItems.map((item: PortfolioDataType) => item.id);

        setLeftPhotoItems(filteredLeftItems);
        setRightPhotoItems(filteredRightItems);

        setImageIdItems(orders); // 이미지 id 저장
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 이미지 미리보기 (이미지 추가)
  const onChangeSingleFile = (isLeft: boolean) => (e: ChangeEvent<HTMLInputElement>) => {
    const isValid = checkValidationImage(e.target.files?.[0]);
    if (!isValid) return;

    try {
      if (imgRef.current?.files) {
        const file = imgRef.current.files[0];
        setImage(file);
        const reader = new FileReader();
        if (e.target.files) {
          reader.readAsDataURL(e.target.files[0]);
          reader.onloadend = () => {
            if (typeof reader.result === 'string') {
              if (isLeft) {
                setLeftSingleFileUrl(reader.result);
              } else {
                setRightSingleFileUrl(reader.result);
              }
            }
          };
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 이미지 미리보기
  const onChangeFile = (index: number, isLeft: boolean) => (e: ChangeEvent<HTMLInputElement>) => {
    const isValid = checkValidationImage(e.target.files?.[0]);
    if (!isValid) return;

    try {
      if (imgRef.current?.files) {
        const file = imgRef.current.files[0];
        setImage(file);
        const reader = new FileReader();
        if (e.target.files) {
          reader.readAsDataURL(e.target.files[0]);
          reader.onloadend = () => {
            if (typeof reader.result === 'string') {
              if (isLeft) {
                setLeftFileUrl((prev: any) => {
                  const newFileUrl = [...prev];
                  newFileUrl[index] = reader.result;
                  return newFileUrl;
                });
              } else {
                setRightFileUrl((prev: any) => {
                  const newFileUrl = [...prev];
                  newFileUrl[index] = reader.result;
                  return newFileUrl;
                });
              }
            }
          };
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 이미지 추가
  const handleCreateImage = async (isLeft: boolean) => {
    try {
      const formData = new FormData();
      formData.append('image', image);
      formData.append('tag', 'BABY/FAMILY'); // tag는 페이지에 따라 다르게 입력

      // 이미지 검증 및 스토리지 저장
      const response = await client.post('api/v1/images/image/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      const newFileUrl = response.data.img_url;
      // setFileUrl(newFileUrl);

      const data = {
        category: 'BABY/FAMILY',
        img_url: newFileUrl,
        direction: isLeft ? 'left' : 'right',
      };

      // 이미지 추가
      await client.post(`api/v1/portfolios/portfolio/`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      setIsLeftCreateMode(false);
      setIsRightCreateMode(false);

      handleQueryPorflioImages();

      message.success({ content: 'Portfolio 페이지의 이미지 추가가 완료되었습니다.' });
    } catch (error) {
      console.error(error);
    }
  };

  // 이미지 수정
  const handleEditPortfolioImage = async (index: number, imageId: number, isLeft: boolean) => {
    try {
      const formData = new FormData();
      formData.append('image', image);
      formData.append('tag', 'BABY/FAMILY'); // tag는 페이지에 따라 다르게 입력

      // 이미지 검증 및 스토리지 저장
      const response = await client.post('api/v1/images/image/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      if (isLeft) {
        const newFileUrl = response.data.img_url;
        setLeftFileUrl(newFileUrl);

        const uploadImageData = { img_url: newFileUrl };

        await client.post(`api/v1/portfolios/portfolio/${imageId}/`, uploadImageData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${userToken}`,
          },
          withCredentials: true,
        });

        setIsActiveEditLeftImage({ ...isActiveEditLeftImage, [index]: false });
        setHoveredLeftImageIndex(null);
        setLeftFileUrl([]);
      } else {
        const newFileUrl = response.data.img_url;
        setRightFileUrl(newFileUrl);

        const uploadImageData = { img_url: newFileUrl };

        await client.post(`api/v1/portfolios/portfolio/${imageId}/`, uploadImageData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${userToken}`,
          },
          withCredentials: true,
        });

        setIsActiveEditRightImage({ ...isActiveEditRightImage, [index]: false });
        setHoveredRightImageIndex(null);
        setRightFileUrl([]);
      }

      setIsEditingMode(false);
      setFileUrl('');

      handleQueryPorflioImages();

      message.success({ content: 'Portfolio 페이지 이미지 수정이 완료되었습니다.' });
    } catch (error) {
      console.error(error);
    }
  };

  // 이미지 전체 삭제
  const handleAllImageDelete = async () => {
    try {
      await client.delete('api/v1/portfolios/portfolio/', {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });

      handleQueryPorflioImages();

      message.success({ content: '이미지 삭제가 완료되었습니다.' });
    } catch (error) {
      console.error(error);
    }
  };

  // 이미지 순서 변경
  const handleChangeImageOrder = async () => {
    try {
      const data = {
        id: 75,
        id2: 109,
      };

      await client.post(`api/v1/portfolios/portfolio_change/`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      handleQueryPorflioImages();

      message.success({ content: 'Portfolio 페이지의 이미지 순서 변경이 완료되었습니다.' });
    } catch (error) {
      console.error(error);
    }
  };

  // 이미지 개별 삭제
  const handleImageDelete = async (index: number) => {
    try {
      await client.delete(`api/v1/portfolios/portfolio/${index}/`, {
        data: { index },
        headers: {
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      handleQueryPorflioImages();

      message.success({ content: '선택하신 이미지가 삭제되었습니다.' });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    imagesToPreload.forEach((imagePath) => {
      const img = new Image();
      img.src = imagePath;
    });
  }, []);

  useEffect(() => {
    handleQueryPorflioImages();
  }, []);

  return (
    <>
      <Helmet>
        <title>Portfolio — 눈부신 일상</title>
      </Helmet>

      {/* pc 화면 */}
      {isLg && !isSm && (
        <Container>
          <OuterWrapper>
            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
              <PhotoLeftWrapper>
                <TitleWrapper>
                  <PortfolioTitle>BABY / FAMILY</PortfolioTitle>
                </TitleWrapper>
                {leftPhotoItems.map((image, index) => (
                  <>
                    <div
                      key={index}
                      onMouseEnter={() => setHoveredLeftImageIndex(index)}
                      onMouseLeave={() => setHoveredLeftImageIndex(null)}
                      style={{ position: 'relative' }}
                    >
                      <PhotoItem
                        src={isActiveEditLeftImage[index] && leftFileUrl[index] ? leftFileUrl[index] : image.img_url}
                        isHover={hoveredLeftImageIndex === index}
                        isEditing={!isActiveEditLeftImage[index] && hoveredLeftImageIndex === index}
                      />
                      {isActiveEditLeftImage[index] && (
                        <>
                          <ImageEditInputWrapper>
                            <ImageEditInput
                              type="file"
                              ref={imgRef}
                              accept="image/*"
                              onChange={onChangeFile(index, true)}
                            />
                          </ImageEditInputWrapper>
                          <EditButtonWrapper>
                            <CancelButton
                              onClick={() => {
                                setIsActiveEditLeftImage({ ...isActiveEditLeftImage, [index]: false });
                                setHoveredLeftImageIndex(null);
                                setIsEditingMode(false);
                                setLeftFileUrl([]);
                                setRightFileUrl([]);
                              }}
                            >
                              취소
                            </CancelButton>
                            <SaveButton onClick={() => handleEditPortfolioImage(index, image.id, true)}>
                              저장
                            </SaveButton>
                          </EditButtonWrapper>
                        </>
                      )}
                      {!isActiveEditLeftImage[index] && hoveredLeftImageIndex === index && !isEditingMode && (
                        <ImageEditButtonWrapper>
                          <ImageEditButton
                            onClick={() => {
                              setIsActiveEditLeftImage({ ...isActiveEditLeftImage, [index]: true });
                              setIsEditingMode(true);
                            }}
                          >
                            수정
                          </ImageEditButton>
                          <ImageDeleteButton onClick={() => handleImageDelete(image.id)}>삭제</ImageDeleteButton>
                        </ImageEditButtonWrapper>
                      )}
                    </div>
                  </>
                ))}
                {/* 이미지 추가 모드 */}
                {isLeftCreateMode && (
                  <CreateModeWrapper>
                    {/* 이미지 미리보기 */}
                    <CreateImage src={leftSingleFileUrl ? leftSingleFileUrl : image} />
                    <ImageCreateInput
                      type="file"
                      ref={imgRef}
                      accept="image/*"
                      onChange={onChangeSingleFile(true)}
                      onClick={() => setIsVisibleCreateInput(false)}
                    />
                    <CreateModeButtonWrapper>
                      <CreateModeSaveButton onClick={() => handleCreateImage(true)}>저장</CreateModeSaveButton>
                      <CreateModeCancelButton onClick={() => setIsLeftCreateMode(false)}>취소</CreateModeCancelButton>
                    </CreateModeButtonWrapper>
                  </CreateModeWrapper>
                )}
                {!isLeftCreateMode && !isVisibleCreateInput && (
                  <CreateImageButton onClick={handleChangeLeftCreateMode}>
                    <CreateImageButtonIcon />
                  </CreateImageButton>
                )}
              </PhotoLeftWrapper>
              <PhotoRightWrapper>
                {rightPhotoItems.map((image, index) => (
                  <div
                    key={index}
                    onMouseEnter={() => setHoveredRightImageIndex(index)}
                    onMouseLeave={() => setHoveredRightImageIndex(null)}
                    style={{ position: 'relative' }}
                  >
                    <PhotoItem
                      src={isActiveEditRightImage[index] && rightFileUrl[index] ? rightFileUrl[index] : image.img_url}
                      isHover={hoveredRightImageIndex === index}
                      isEditing={!isActiveEditRightImage[index] && hoveredRightImageIndex === index}
                    />
                    {isActiveEditRightImage[index] && (
                      <>
                        <ImageEditInputWrapper>
                          <ImageEditInput
                            type="file"
                            ref={imgRef}
                            accept="image/*"
                            onChange={onChangeFile(index, false)}
                          />
                        </ImageEditInputWrapper>
                        <EditButtonWrapper>
                          <CancelButton
                            onClick={() => {
                              setIsActiveEditRightImage({ ...isActiveEditRightImage, [index]: false });
                              setHoveredRightImageIndex(null);
                              setIsEditingMode(false);
                              setLeftFileUrl([]);
                              setRightFileUrl([]);
                            }}
                          >
                            취소
                          </CancelButton>
                          <SaveButton onClick={() => handleEditPortfolioImage(index, image.id, false)}>저장</SaveButton>
                        </EditButtonWrapper>
                      </>
                    )}
                    {!isActiveEditRightImage[index] && hoveredRightImageIndex === index && !isEditingMode && (
                      <ImageEditButtonWrapper>
                        <ImageEditButton
                          onClick={() => {
                            setIsActiveEditRightImage({ ...isActiveEditRightImage, [index]: true });
                            setIsEditingMode(true);
                          }}
                        >
                          수정
                        </ImageEditButton>
                        <ImageDeleteButton onClick={() => handleImageDelete(image.id)}>삭제</ImageDeleteButton>
                      </ImageEditButtonWrapper>
                    )}
                  </div>
                ))}
                {/* 이미지 추가 모드 */}
                {isRightCreateMode && (
                  <CreateModeWrapper>
                    {/* 이미지 미리보기 */}
                    <CreateImage src={rightSingleFileUrl ? rightSingleFileUrl : image} />
                    <ImageCreateInput
                      type="file"
                      ref={imgRef}
                      accept="image/*"
                      onChange={onChangeSingleFile(false)}
                      onClick={() => setIsVisibleCreateInput(false)}
                    />
                    <CreateModeButtonWrapper>
                      <CreateModeSaveButton onClick={() => handleCreateImage(false)}>저장</CreateModeSaveButton>
                      <CreateModeCancelButton onClick={() => setIsRightCreateMode(false)}>취소</CreateModeCancelButton>
                    </CreateModeButtonWrapper>
                  </CreateModeWrapper>
                )}
                {!isRightCreateMode && !isVisibleCreateInput && (
                  <CreateImageButton onClick={handleChangeRightCreateMode}>
                    <CreateImageButtonIcon />
                  </CreateImageButton>
                )}
              </PhotoRightWrapper>
            </DragDropContext>
          </OuterWrapper>
        </Container>
      )}
      {/* 모바일 화면 */}
      {isSm && (
        <Container>
          <OuterWrapper>
            <PhotoLeftWrapper>
              <TitleWrapper>
                <PortfolioTitle>BABY / FAMILY</PortfolioTitle>
              </TitleWrapper>
              {leftPhotoItems.map((image, index) => (
                <div
                  key={index}
                  onMouseEnter={() => setHoveredLeftImageIndex(index)}
                  onMouseLeave={() => setHoveredLeftImageIndex(null)}
                  style={{ position: 'relative' }}
                >
                  <PhotoItem
                    src={isActiveEditLeftImage[index] && leftFileUrl[index] ? leftFileUrl[index] : image.img_url}
                    isHover={hoveredLeftImageIndex === index}
                    isEditing={
                      !isActiveEditLeftImage[index] &&
                      (hoveredLeftImageIndex === index || hoveredRightImageIndex === index)
                    }
                  />
                  {isActiveEditLeftImage[index] && (
                    <ImageEditInputWrapper>
                      <ImageEditInput type="file" ref={imgRef} accept="image/*" onChange={onChangeFile(index, true)} />
                    </ImageEditInputWrapper>
                  )}
                  {!isActiveEditLeftImage[index] && hoveredLeftImageIndex === index && (
                    <ImageEditButton
                      onClick={() => setIsActiveEditLeftImage({ ...isActiveEditLeftImage, [index]: true })}
                    >
                      수정
                    </ImageEditButton>
                  )}
                </div>
              ))}
            </PhotoLeftWrapper>
            <PhotoRightWrapper>
              {rightPhotoItems.map((image, index) => (
                <div
                  key={index}
                  onMouseEnter={() => setHoveredRightImageIndex(index)}
                  onMouseLeave={() => setHoveredRightImageIndex(null)}
                  style={{ position: 'relative' }}
                >
                  <PhotoItem
                    src={isActiveEditRightImage[index] && rightFileUrl[index] ? rightFileUrl[index] : image.img_url}
                    isHover={hoveredRightImageIndex === index}
                    isEditing={
                      !isActiveEditRightImage[index] &&
                      (hoveredLeftImageIndex === index || hoveredRightImageIndex === index)
                    }
                  />
                  {isActiveEditRightImage[index] && (
                    <ImageEditInputWrapper>
                      <ImageEditInput type="file" ref={imgRef} accept="image/*" onChange={onChangeFile(index, false)} />
                    </ImageEditInputWrapper>
                  )}
                  {!isActiveEditRightImage[index] && hoveredRightImageIndex === index && (
                    <ImageEditButton
                      onClick={() => setIsActiveEditRightImage({ ...isActiveEditRightImage, [index]: true })}
                    >
                      수정
                    </ImageEditButton>
                  )}
                </div>
              ))}
            </PhotoRightWrapper>
          </OuterWrapper>
        </Container>
      )}
    </>
  );
};

export default AdminBabyFamilyPage;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ${MEDIA_QUERY.lg} {
    margin-top: -6.5rem;
  }

  ${MEDIA_QUERY.md} {
    margin-top: 0;
    margin: 0;
  }

  ${MEDIA_QUERY.sm} {
    margin-top: 0;
    padding: 0 2.8rem;
  }
`;

const OuterWrapper = styled.div`
  width: 70rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(32rem, 0fr));
  justify-content: center;

  ${MEDIA_QUERY.md} {
    margin: 0;
  }

  ${MEDIA_QUERY.sm} {
    grid-template-columns: repeat(1, minmax(32rem, 1fr));
  }
`;

const TitleWrapper = styled.div`
  width: 35.8rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.lg} {
  margin: 3rem 0 2.6rem;
  }

  ${MEDIA_QUERY.sm} {
  width: 100%;
  margin: 0;
  }
`;

const PortfolioTitle = styled.span`
  color: #474747;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 2.4px;
  line-height: 15px;
  text-align: center;

  ${MEDIA_QUERY.sm} {
    font-size: 1.3rem;
    letter-spacing: 2.6px;
    margin: 7.8rem 0 3.2rem;
  }
`;

const PhotoLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${MEDIA_QUERY.sm} {
    align-items: center;
    flex-direction: column;

    margin: 0;
  }
`;

const PhotoRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  ${MEDIA_QUERY.sm} {
    display: flex;
    flex-direction: column;

    margin: 0;
  }
`;

interface photoItemProps {
  isHover: boolean;
  isEditing: boolean;
}

const PhotoItem = styled.img<photoItemProps>`
  width: 100%;
  padding: 1.5rem;
  box-sizing: border-box;

  ${(props) =>
    (props.isHover || props.isEditing) &&
    css`
      opacity: 0.3;
    `}

  ${MEDIA_QUERY.sm} {
    width: 100%;
    max-width:320px;
    padding: 1.5rem 0;
    box-sizing: content-box;
  }

  ${MEDIA_QUERY.xs} {
    width: 25rem;
  }
`;

const ImageEditButtonWrapper = styled.div`
  width: 20rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ImageEditButton = styled.button`
  width: 5rem;
  height: 2.5rem;
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  border: none;
  color: #fff;
  background-color: #000;
  opacity: 1;
  cursor: pointer;
`;

const ImageDeleteButton = styled.button`
  width: 5rem;
  height: 2.5rem;
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  border: none;
  color: #fff;
  background-color: #ce2c30;
  opacity: 1;
  cursor: pointer;
`;

const ImageEditInputWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
`;

const ImageEditInput = styled.input`
  position: relative;
  top: 30%;
  left: 65%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

const EditButtonWrapper = styled.div`
  position: absolute;
  top: 30%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const CancelButton = styled.button`
  width: 6rem;
  height: 3rem;
  border: 1px solid #000;
  margin-right: 1.5rem;
  color: #000;
  background-color: #fff;
  cursor: pointer;
`;

const SaveButton = styled.button`
  width: 6rem;
  height: 3rem;
  border: none;
  color: #fff;
  background-color: #000;
  cursor: pointer;
`;

const CreateImageButton = styled.div`
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #000;
  z-index: 2;
  cursor: pointer;

  &:hover {
    opacity: 0.3;
  }
`;

const ImageCreateInput = styled.input`
  cursor: pointer;
`;

const CreateImageButtonIcon = styled(PlusOutlined)`
  color: #fff;
  font-size: 2rem;
  z-index: 3;
`;

const CreateModeWrapper = styled.div`
  margin-top: 2rem;
  padding: 2rem;
`;

const CreateModeButtonWrapper = styled.div`
  width: 13rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`;

const CreateModeCancelButton = styled.button`
  width: 5rem;
  height: 3rem;
  border: 1px solid #000;
  margin-right: 1.5rem;
  color: #000;
  background-color: #fff;
  cursor: pointer;
`;

const CreateModeSaveButton = styled.button`
  width: 5rem;
  height: 3rem;
  border: none;
  color: #fff;
  background-color: #000;
  cursor: pointer;
`;

const CreateImage = styled.img`
  width: 32rem;
`;
