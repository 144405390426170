import { Alert, Button, Form, Input, Modal } from 'antd';
import client from '../../api/axios';
import { Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../stores';
import { AxiosError } from 'axios';

type SignUpType = {
  username: string;
  password1: string;
  password2: string;
};

const AdminSignUpPage = () => {
  const [userToken] = useAtom(tokenAtom);
  const [visible, setVisible] = useState(false);

  const { Header, Footer } = Layout;

  const [password, SetPassword] = useState('');

  const navigate = useNavigate();

  const handleSignupSubmit = async (data: SignUpType) => {
    try {
      await client.post('api/v1/accounts/signup/', data, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  const handleOk = async () => {
    await handleCheckPassword();
    setVisible(false);
  };

  const handleCheckPassword = async () => {
    try {
      await client.post(
        'api/v1/contacts/affiliate_check/1/',
        { password },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${userToken}`,
          },
          withCredentials: true,
        },
      );
    } catch (error) {
      const { response } = error as unknown as AxiosError;

      // TODO: msg === 'fail'이면 alert창 띄우기
      // if (response?.data?.msg === 'fail') {
      //   alert('올바른 비밀번호를 입력해주세요.');
      // }
    }
  };

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <>
      <Modal title="관리자 비밀번호를 입력해주세요." open={visible} onOk={handleOk} onCancel={() => setVisible(false)}>
        <Input
          name="password"
          placeholder="1234"
          value={password}
          autoComplete="off"
          onChange={(e) => SetPassword(e.target.value)}
          onPressEnter={handleOk}
        />
      </Modal>

      <Layout
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          backgroundColor: '#fff',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Form
            name="basic"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 20 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={handleSignupSubmit}
            autoComplete="off"
          >
            <h2 style={{ display: 'flex', justifyContent: 'center', marginBottom: '5rem' }}>회원 가입</h2>

            <Form.Item<SignUpType>
              label="아이디"
              name="username"
              rules={[{ required: true, message: '아이디를 입력해주세요' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item<SignUpType>
              label="비밀번호"
              name="password1"
              rules={[{ required: true, message: '비밀번호를 입력해주세요' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item<SignUpType>
              label="비밀번호 확인"
              name="password2"
              rules={[{ required: true, message: '다시 한번 비밀번호를 입력해주세요' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 11, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    </>
  );
};

export default AdminSignUpPage;
