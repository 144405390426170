import styled, { css } from 'styled-components';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import useMediaQuery from '../../../../hook/useMediaQuery';
import useMoveScroll from '../../../../hook/useMoveScroll';
import MEDIA_QUERY from '../../../../constants/mediaQuery';
import client from '../../../../api/axios';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../../../stores';
import { Form, Input, Space, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type TipsDataType = {
  id: number;
  title?: string;
  content?: string;
  created_at?: string;
  img_url?: string;
  is_cancel?: string;
};

const AdminTipsPage = () => {
  const { isXs, isSm, isLg } = useMediaQuery();

  const [userToken] = useAtom(tokenAtom);

  const navigate = useNavigate();

  // Tips 페이지 전체 데이터
  const [tipsData, setTipsData] = useState<TipsDataType[] | null>();

  // Tips active 데이터
  const [activeTipsData, setActiveTipsData] = useState<TipsDataType | null>();

  console.log('111', activeTipsData);

  // 관리자 수정 모드
  const [isHoverTipsButtons, setIsHoverTipsButtons] = useState(false);
  const [isHoverFirstArticle, setIsHoverFirstArticle] = useState(false);
  const [isHoverSecondArticle, setIsHoverSecondArticle] = useState(false);
  const [isHoverThirdArticle, setIsHoverThirdArticle] = useState(false);
  const [isHoverFourthArticle, setIsHoverFourthArticle] = useState(false);

  // 아티클 상태 변경 함수
  const hoverState = [isHoverFirstArticle, isHoverSecondArticle, isHoverThirdArticle, isHoverFourthArticle];

  const hoverStateSetters = [
    setIsHoverFirstArticle,
    setIsHoverSecondArticle,
    setIsHoverThirdArticle,
    setIsHoverFourthArticle,
  ];

  const [isActiveEditTipsButtons, setIsActiveEditButtons] = useState(false);
  const [isActiveEditFirstArticle, setIsActiveEditFirstArticle] = useState(false);
  const [isActiveEditSecondArticle, setIsActiveEditSecondArticle] = useState(false);
  const [isActiveEditThirdArticle, setIsActiveEditThirdArticle] = useState(false);
  const [isActiveEditFourthArticle, setIsActiveEditArticle] = useState(false);

  const IS_EDIT_MODE =
    !isActiveEditTipsButtons &&
    !isActiveEditFirstArticle &&
    !isActiveEditSecondArticle &&
    !isActiveEditThirdArticle &&
    !isActiveEditFourthArticle;

  const IS_TIPS_BUTTONS_EDIT_MODE =
    !isActiveEditFirstArticle && !isActiveEditSecondArticle && !isActiveEditThirdArticle && !isActiveEditFourthArticle;

  const [tipsButtonsValue, setTipsButtonsValue] = useState({
    button1: '',
    button2: '',
    button3: '',
    button4: '',
  });

  const handleTipsButtonChange = (buttonNumber: string) => (e: any) => {
    setTipsButtonsValue({
      ...tipsButtonsValue,
      [buttonNumber]: e.target.value,
    });
  };

  // 아티클 수정 모드
  const [isEditing, setIsEditing] = useState(false);

  // 텍스트 에디터
  const [tipsArticleData, setTipsArticleData] = useState({
    id: 0,
    content: '',
  });

  const [editorValue, setEditorValue] = useState('');

  const modules = {
    toolbar: [[{ header: [1, 2, 3, false] }], ['bold', 'italic', 'underline', 'strike']],
  };
  const formats = ['header', 'bold', 'italic', 'underline', 'strike'];

  // 해당되는 촬영 설명으로 스크롤 이동
  const shootingRefs = [useMoveScroll(), useMoveScroll(), useMoveScroll(), useMoveScroll()];

  const [activeIndex, setActiveIndex] = useState(0);

  // 촬영 설명 - 모바일 화면
  const mobileShootingDescriptions = [
    {
      title: '만삭 촬영 - 촬영 준비',
      description: `<b>1. 헤어 메이크업 스타일링을 위해 촬영 예약 시간 45분 <br />&nbsp;&nbsp;&nbsp;전까지 도착해 주세요.</b> <br />
      <b>2. 헤어는 촬영 당일 샴푸 후 완전히 말려주세요.</b>
      <br /> &nbsp;&nbsp;&nbsp;*유분기 있는 컨디셔너는 피해주세요. <br />
      <b>3. 메이크업은 색조까지(마스카라 제외) 전체 메이크업을 <br />&nbsp;&nbsp;&nbsp;&nbsp;하고 오세요.</b>
      <br /> &nbsp;&nbsp;&nbsp;*전문가의 리터치가 제공됩니다(노메이크업으로 오시<br />&nbsp;&nbsp;&nbsp;면 풀 메이크업 비용으로 5만원이 추가될 수 있어요.)
      <br />
      <b>
        4. 흰색/피부색 속옷을 착용해 주시고 어머님 의상은 <br /> &nbsp;&nbsp;&nbsp;&nbsp;갈아입기 편한 남방류로 입고 오시면 편하게 진행할 수 <br />
        &nbsp;&nbsp;&nbsp;&nbsp;있어요.
      </b>
      <br />
      &nbsp;&nbsp;&nbsp;*임산부용 속옷은 피해주세요.
      <br /> <b>5. 바디 로션/바디 오일은 피해주세요.</b>
      <br /> <b>6. 겨드랑이 제모를 잊지 마세요.</b>
      <br /> <b>7. 기념이 될만한 물건을 챙겨오시면 좋아요</b> <br />&nbsp;&nbsp;&nbsp;(예 : 아기 옷, 신발, 초음파 사진/영상, 결혼반지 등) 
      <br />
      <br />
      <DescriptionTitle><b>만삭 촬영 - 방문 전 필수 체크</b><br /></DescriptionTitle>
      <br />
      <b>1. 초음파 동영상을 보내주세요.</b> <br />&nbsp;&nbsp;&nbsp;- 촬영 당일 업그레이드된 모니터링을 위해 <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;초음파 동영상 1개만
      보내주세요. <br />&nbsp;&nbsp;&nbsp;- 카카오톡 채널 ‘눈부신 일상 oo점’ 추가 <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[접수] 만삭 초음파 영상 <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·용도 : 만삭 촬영 영상 콜라보 제작 <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·분량 : 심박동 소리 있는 원본(1분 이상) <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·기한 : 촬영 3일 전까지 <br />
      <br /> <b>2. 아버님 준비사항</b> <br />&nbsp;&nbsp;&nbsp;- 의상 준비 : 고르신 테마에 따라 아버님 의상은 <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;직접 준비해 주세요.
      <br />&nbsp;&nbsp; - 드레스 세미 정장 테마를 고르시면<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(정장, 긴팔 와이셔츠, 구두, 검정 양말)
      <br /> &nbsp;&nbsp;&nbsp;- 캐주얼 테마를 고르시면(흰 셔츠 긴팔, 면바지, 청바<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;지), 아버님 의상은 꼭 긴 팔, 긴 바지로 준비해
      <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;주세요.
      <br />&nbsp;&nbsp; - 평일 혜택 : 메이크업 제공. 헤어 스타일링(수정) <br /> <br />
      <b>3. 자녀가 있으실 경우</b> <br />&nbsp;&nbsp;&nbsp;- 엄마, 아빠와 함께 촬영할 의상 준비해 주세요,<br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;보통 3세~6세 턱시도
      드레스는 준비되어 있으나 <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;대여를 원하실 경우 꼭 촬영 지점에 문의해 주세요<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(아이들 사이즈에 맞지 않을 수도
      있어요.)
      <br />&nbsp;&nbsp; - 캐주얼한 테마를 선택하실 경우, <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;별도로 자녀 의상을 준비해 주세요.
      <br /> &nbsp;&nbsp;- 주니어 헤어 메이크업은 "유료"입니다. <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;추가를 원하시면 어머님 메이크업 시작 전에<br />&nbsp;&nbsp;&nbsp;&nbsp; 미리 문의해 주세요
      (헤어 스타일링 제품은 무료로 <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;사용 가능합니다.)`,
    },
    {
      title: '50일 촬영 준비',
      description: `<b>1. 예약 시간에 늦지 않게 도착해 주세요.</b> <br />- 예약 시간 10분 전에 미리 도착하셔서 아기 컨디션
      등<br />&nbsp;&nbsp;&nbsp;&nbsp;을 체크하며 대기하시는 게 좋습니다. <br /> <br />
      <b>2. 촬영 당일 아기 목욕은 피해주세요.</b>
      <br /> &nbsp;&nbsp;&nbsp;- 나른한 상태, 혹은 잠투정의 원인이 될 수 있어요<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(촬영전까지 충분히 재워서 최상의 컨디션으로)
      <br />
      <br />
      <b>3. 가족 모두 촬영할 준비 후 방문해 주세요.</b>
      <br /> &nbsp;&nbsp;&nbsp;- 아기와 함께하는 가족 기념 컷을 간단하게<br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;진행해 드려요.
      <br /> &nbsp;&nbsp;&nbsp;- 큰 아이가 있다면, 꼭 동행해 주세요.
      <br /> &nbsp;&nbsp;&nbsp;- 가족 모두 깔끔한 복장으로 오시면 됩니다<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(가벼운 촬영 일지라도 트레이닝복은 피해주세요.)
      <br />
      <br />
      <b>4. 준비물을 잊지 마세요.</b>
      <br /> &nbsp;&nbsp;&nbsp;- 분유, 기저귀, 가제수건, 공갈젖꼭지
      <br /> &nbsp;&nbsp;&nbsp;- 평소 외출 시보다 조금 넉넉하게 준비해 주세요.
      <br />
      <br />
      <b>
        ※ 50일 촬영 적정 시기 : 생후 45일 ~ 최대 80일
        <br /> ※ 아기가 예정일보다 일찍 태어났나요?
      </b>
      <br />
      &nbsp;&nbsp;&nbsp;아기의 발달은 생일 기준이 아닌,<br /> &nbsp;&nbsp;&nbsp;출산 예정일을 기준으로 이루어집니다. <br />&nbsp;&nbsp;&nbsp;생일과 출산 예정일의 차이가 7일
      이상이라면,
      <br />
      &nbsp;&nbsp;&nbsp;예정일에 맞춰 일정을 예약해 주세요.<br /> &nbsp;&nbsp;&nbsp;발달 시기에 맞춘 보다 예쁜 아기 사진을<br /> &nbsp;&nbsp;&nbsp;담으실 수 있답니다.
      <br /><br />
      <b>※ 파노라마 촬영</b>
      <br /> &nbsp;&nbsp;&nbsp;&nbsp;평일 방문 시, 파노라마 사진을 추가로 담아 드려요<br />&nbsp;&nbsp;&nbsp;&nbsp;(평일 전용 테마)`,
    },
    {
      title: '100일 촬영 준비',
      description: `<b>1. 예약 시간에 늦지 않게 도착해 주세요.</b>
      <br />- 예약 시간 15분 전 도착 후, 아기가 적응할 수 있도록<br /> &nbsp;&nbsp;&nbsp;&nbsp;도와주세요.
      <br /> <br />
      <b>2. 촬영 당일 아기 목욕은 피해주세요.</b> <br />- 나른한 상태, 혹은 잠투정의 원인이 된답니다. <br />
      <br />
      <b>3. 아기 컨디션을 최상으로 맞춰주기 위한 연습</b> <br />- 충분한 수면은 좋은 컨디션의 출발이에요.
      <br /> - 엎드려서 목 가누는 연습을 충분히 해주세요<br />&nbsp;&nbsp;&nbsp;(5분 이상 엎드려 놀 수 있으면 가장 좋습니다.) <br />-
      엎드려서 팔을 모으는 연습도 해주세요<br />&nbsp;&nbsp;&nbsp;(다양한 포즈와 표정 연출을 위해 꼭 필요한 자세예요.) <br />- 잠깐씩
      기대어 앉혀 놓은 모습도 찍는 경우가 있으니,<br /> &nbsp;&nbsp;&nbsp;집에서도 종종 기대어 앉는 연습도 해주세요.
      <br /> - 예방주사는 촬영 전 최소 일주일 전에 맞춰주셔야 아기<br />&nbsp;&nbsp;&nbsp;가 힘들지 않아요.
      <br /> - 촬영 코디의 완성은 모자!! 모자 쓰는 연습도 자주 해줘<br />&nbsp;&nbsp;&nbsp;야 아기들이 불편해하지 않아요.
      <br /> &nbsp;&nbsp;&nbsp;TIP. 촬영 전 1개월부터 연습을 조금씩 시켜주셔도 좋<br />&nbsp;&nbsp;&nbsp;아요. <br />
      <br /> <b>4. 준비물을 잊지 마세요.</b> <br />- 분유, 기저귀, 가제수건, 공갈젖꼭지
      <br /> - 평소 외출 시보다 조금 넉넉하게 준비해 주세요. <br />
      <br /> <b>5. 촬영하실 테마를 미리 선택해 두세요.</b> <br />- 당일 현장에서 테마 선택 시, 촬영 시간이
      단축될 수<br /> &nbsp;&nbsp;&nbsp;있어요.
      <br /> - 테마당 의상 1벌, 액세사리 1가지로 촬영이<br /> &nbsp;&nbsp;&nbsp;진행된답니다.
      <br /> - 촬영 소요시간은 아기 컨디션에 따라 다소 변경될 수<br /> &nbsp;&nbsp;&nbsp;있어요. <br />
      <br /> <b>※ 100일 촬영 적정 시기 : 생후 120일 ~ 130일</b>
      <b>
        <br />※ 평균 30분 안팎의 시간이 촬영에 소요되어요.<br /> 1시간 이상 경과 시 아기가 힘들 수 있어요.
      </b>`,
    },
    {
      title: '돌 촬영 준비',
      description: `<b>1. 예약 시간에 늦지 않게 도착해 주세요.</b> <br />- 예약시간 15분 전 도착 후, 아기가 적응할 수 있도록<br />
      &nbsp;&nbsp;&nbsp;&nbsp;도와주세요(낯가림이 심하면 조금 더 일찍 오세요.)
      <br /> - 가족 촬영 예약 고객 중, 헤어&메이크업 서비스를 추<br />&nbsp;&nbsp;&nbsp;가하셨나요? 1시간 전에 방문해 주세요. <br />
      <br /> <b>2. 아기 컨디션을 최상으로 맞춰주세요.</b> <br />- 충분한 수면은 좋은 컨디션의 출발이에요.
      <br /> - 촬영 당일 아기 목욕은 피해주세요.
      <br /> - 촬영 전 7일 이내, 예방접종을 피해주세요.
      <br /> - 모자 쓰는 연습도 미리미리 해주세요.
      <br /> - 아기 낮잠 시간을 피해 촬영 예약시간을 조절해 주세요<br />&nbsp;&nbsp;&nbsp;(카카오톡 채널 이용) <br />
      <br /> <b>3. 준비물을 잊지 마세요.</b> <br />- 분유, 기저귀, 가제수건
      <br /> - 익숙한 장난감, 즐겨먹는 간식
      <br /> - 평소 외출 시보다 조금 넉넉하게 준비해 주세요. <br />
      <br /> <b>4. 가족 촬영 함께 예약되어 있나요?</b> <br />- 통일감 있는 패밀리룩으로 맞춰 입으면 좋아요.
      <br /> - 드레스&정장으로 준비하셨다면, 신발&양말까지 <br />&nbsp;&nbsp;&nbsp;준비하세요.
      <br /> - 원색 계열의 의상, 후드티셔츠, 프린트 셔츠는 <br />&nbsp;&nbsp;&nbsp;추천하지 않아요.
      <br /> - 의상은 미리 입고 방문해 주세요. <br />
      <br /> <b>[헤어&메이크업 / 의상 대여 서비스를 신청하신 고객<br />님 준비사항]</b> <br />&nbsp;· 헤어는 샴푸 후 완전히
      말리고 오세요.
      <br /> &nbsp;· 메이크업은 피부 화장만 하고 오세요(쿠션 or 비비크<br />&nbsp;&nbsp;&nbsp;림)
      <br /> &nbsp;· 어머님 의상은 갈아입기 편한 남방류로 입고 오시면<br /> &nbsp;&nbsp;&nbsp;좋아요.
      <br /> &nbsp;· 의상 대여는 당일 구비된 의상 중 대여가 가능해요. <br />
      <br /> <b>5. 형제/자매/남매 촬영이 함께 예약되어 있나요?</b> <br />- 통일감 있는 의상으로 준비해 주세요.
      <br /> - 패밀리룩으로 이어서 촬영하실 수 있어요. <br />
      <br /> <b>※ 돌 촬영 적정 시기</b> <br />&nbsp;· 생후 300일 ~ 330일
      <br /> &nbsp;· 사물을 잡은 채 혼자 서있을 수 있어요.
      <br /> &nbsp;· 돌잔치 DP용 액자가 필요하신가요?<br /> &nbsp;&nbsp;사진 선택 후 약 6주가 소요되니, 촬영 시기를 체크해<br /> &nbsp;&nbsp;보세요.​
      <br />
      <br />
      <b>TIP. 종종 바깥 외출을 통해 낯선 환경, 자극이 무섭지<br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;않게 도와주세요.</b>`,
    },
  ];

  // 촬영 설명 - fordable 기기 화면
  const fordableShootingDescriptions = [
    {
      title: '만삭 촬영 - 촬영 준비',
      description: `<b>1. 헤어 메이크업 스타일링을 위해 촬영 <br />&nbsp;&nbsp; 예약 시간 45분 전까지 도착해 주세요.</b> <br />
        <b>2. 헤어는 촬영 당일 샴푸 후 완전히 <br />&nbsp;&nbsp;&nbsp;&nbsp;말려주세요.</b>
        <br /> &nbsp;&nbsp;&nbsp;*유분기 있는 컨디셔너는 피해주세요. <br />
        <b>3. 메이크업은 색조까지(마스카라 제외) 전체 <br /> &nbsp;&nbsp;&nbsp;&nbsp;메이크업을 하고 오세요.</b>
        <br /> &nbsp;&nbsp;&nbsp;*전문가의 리터치가 제공됩니다(노메이크업 <br />&nbsp;&nbsp;&nbsp;으로 오시면 풀 메이크업 비용으로 5만원이 <br />&nbsp;&nbsp;&nbsp;추가될 수 있어요.)
        <br />
        <b>
          4. 흰색/피부색 속옷을 착용해 주시고 어머님 <br />&nbsp;&nbsp;&nbsp;의상은 갈아입기 편한 남방류로 입고 오시면 <br /> &nbsp;&nbsp;&nbsp;편하게 진행할 수 있어요.
        </b>
        <br />
        &nbsp;&nbsp;&nbsp;*임산부용 속옷은 피해주세요.
        <br /> <b>5. 바디 로션/바디 오일은 피해주세요.</b>
        <br /> <b>6. 겨드랑이 제모를 잊지 마세요.</b>
        <br /> <b>7. 기념이 될만한 물건을 챙겨오시면 좋아요</b> <br />&nbsp;&nbsp;&nbsp;&nbsp;(예 : 아기 옷, 신발, 초음파 사진/영상, <br />&nbsp;&nbsp;&nbsp;&nbsp;결혼반지 등) 
        <br />
        <br />
        <DescriptionTitle><b>만삭 촬영 - 방문 전 필수 체크</b><br /></DescriptionTitle>
        <br />
        <b>1. 초음파 동영상을 보내주세요.</b> <br />&nbsp;&nbsp;&nbsp;- 촬영 당일 업그레이드된 모니터링을 위해 <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;초음파 동영상 1개만
        보내주세요. <br />&nbsp;&nbsp;&nbsp;- 카카오톡 채널 ‘눈부신 일상 oo점’ 추가 <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[접수] 만삭 초음파 영상 <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·용도 : 만삭 촬영 영상 콜라보 제작 <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·분량 : 심박동 소리 있는 원본(1분 이상) <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·기한 : 촬영 3일 전까지 <br />
        <br /> <b>2. 아버님 준비사항</b> <br />&nbsp;&nbsp;&nbsp;- 의상 준비 : 고르신 테마에 따라 아버님 <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;의상은 직접 준비해 주세요.
        <br />&nbsp;&nbsp; - 드레스 세미 정장 테마를 고르시면<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(정장, 긴팔 와이셔츠, 구두, 검정 양말)
        <br /> &nbsp;&nbsp;&nbsp;- 캐주얼 테마를 고르시면(흰 셔츠 긴팔, <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;면바지, 청바지), 아버님 의상은 꼭 긴 팔, <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;긴 바지로 준비해주세요.
        <br />&nbsp;&nbsp; - 평일 혜택 : 메이크업 제공. 헤어 스타일링<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(수정) <br /> <br />
        <b>3. 자녀가 있으실 경우</b> <br />&nbsp;&nbsp;- 엄마, 아빠와 함께 촬영할 의상 준비해 <br />&nbsp;&nbsp;&nbsp;&nbsp; 주세요,<br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;보통 3세~6세 턱시도
        드레스는 준비되어 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;있으나 대여를 원하실 경우 꼭 촬영 지점에 <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;문의해 주세요(아이들 사이즈에 맞지 않을 <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 수도
        있어요.)
        <br />&nbsp;&nbsp; - 캐주얼한 테마를 선택하실 경우, <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;별도로 자녀 의상을 준비해 주세요.
        <br /> &nbsp;&nbsp;- 주니어 헤어 메이크업은 "유료"입니다. <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;추가를 원하시면 어머님 메이크업 시작 전 <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;에 미리 문의해 주세요
        (헤어 스타일링 제<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;품은 무료로 사용 가능합니다.)`,
    },
    {
      title: '50일 촬영 준비',
      description: `<b>1. 예약 시간에 늦지 않게 도착해 주세요.</b> <br />- 예약 시간 10분 전에 미리 도착하셔서 아기 <br />&nbsp;&nbsp;&nbsp;컨디션
        등을 체크하며 대기하시는 게 좋습니 <br />&nbsp;&nbsp;&nbsp;다. <br /> <br />
        <b>2. 촬영 당일 아기 목욕은 피해주세요.</b>
        <br /> &nbsp;&nbsp;&nbsp;- 나른한 상태, 혹은 잠투정의 원인이 될 수 <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 있어요 (촬영 전까지 충분히 재워서 최상<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;의 컨디션으로)
        <br />
        <br />
        <b>3. 가족 모두 촬영할 준비 후 방문해 주세요.</b>
        <br /> &nbsp;&nbsp;&nbsp;- 아기와 함께하는 가족 기념 컷을 간단하게<br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;진행해 드려요.
        <br /> &nbsp;&nbsp;&nbsp;- 큰 아이가 있다면, 꼭 동행해 주세요.
        <br /> &nbsp;&nbsp;&nbsp;- 가족 모두 깔끔한 복장으로 오시면 됩니다<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(가벼운 촬영 일지라도 트레이닝복은 피해<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;주세요.)
        <br />
        <br />
        <b>4. 준비물을 잊지 마세요.</b>
        <br /> &nbsp;&nbsp;&nbsp;- 분유, 기저귀, 가제수건, 공갈젖꼭지
        <br /> &nbsp;&nbsp;&nbsp;- 평소 외출 시보다 조금 넉넉하게 준비해 주<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;세요.
        <br />
        <br />
        <b>
          ※ 50일 촬영 적정 시기: 생후 45일~최대 80일
          <br /> ※ 아기가 예정일보다 일찍 태어났나요?
        </b>
        <br />
        &nbsp;&nbsp;&nbsp;아기의 발달은 생일 기준이 아닌,<br /> &nbsp;&nbsp;&nbsp;출산 예정일을 기준으로 이루어집니다. <br />&nbsp;&nbsp;&nbsp;생일과 출산 예정일의 차이가 7일
        이상이라<br />&nbsp;&nbsp;&nbsp;면, 예정일에 맞춰 일정을 예약해 주세요.<br /> &nbsp;&nbsp;&nbsp;발달 시기에 맞춘 보다 예쁜 아기 사진을<br /> &nbsp;&nbsp;&nbsp;담으실 수 있답니다.
        <br /><br />
        <b>※ 파노라마 촬영</b>
        <br /> &nbsp;&nbsp;&nbsp;&nbsp;평일 방문 시, 파노라마 사진을 추가로 담아 <br /> &nbsp;&nbsp;&nbsp;&nbsp;드려요(평일 전용 테마)`,
    },
    {
      title: '100일 촬영 준비',
      description: `<b>1. 예약 시간에 늦지 않게 도착해 주세요.</b>
        <br />- 예약 시간 15분 전 도착 후, 아기가 적응할 <br />&nbsp;&nbsp;&nbsp;수 있도록 도와주세요.
        <br /> <br />
        <b>2. 촬영 당일 아기 목욕은 피해주세요.</b> <br />- 나른한 상태, 혹은 잠투정의 원인이 된답니<br />&nbsp;&nbsp;&nbsp;다. 
        <br />
        <b>3. 아기 컨디션을 최상으로 맞춰주기 위한 연습</b> <br />- 충분한 수면은 좋은 컨디션의 출발이에요.
        <br /> - 엎드려서 목 가누는 연습을 충분히 해주세요<br />&nbsp;&nbsp;&nbsp;(5분 이상 엎드려 놀 수 있으면 가장 좋습니<br />&nbsp;&nbsp;&nbsp;다.) <br />-
        엎드려서 팔을 모으는 연습도 해주세요<br />&nbsp;&nbsp;&nbsp;(다양한 포즈와 표정 연출을 위해 꼭 필요한 <br />&nbsp;&nbsp;&nbsp;자세예요.) <br />- 잠깐씩
        기대어 앉혀 놓은 모습도 찍는 경우<br />&nbsp;&nbsp;&nbsp;가 있으니, 집에서도 종종 기대어 앉는 연습<br />&nbsp;&nbsp;&nbsp;도 해주세요.
        <br /> - 예방주사는 촬영 전 최소 일주일 전에 맞춰 <br />&nbsp;&nbsp;&nbsp;주셔야 아기가 힘들지 않아요.
        <br /> - 촬영 코디의 완성은 모자!! 모자 쓰는 연습도 <br />&nbsp;&nbsp;&nbsp;자주 해줘야 아기들이 불편해하지 않아요.
        <br /> &nbsp;&nbsp;&nbsp;TIP. 촬영 전 1개월부터 연습을 조금씩 시켜<br />&nbsp;&nbsp;&nbsp;주셔도 좋아요. <br />
        <br /> <b>4. 준비물을 잊지 마세요.</b> <br />- 분유, 기저귀, 가제수건, 공갈젖꼭지
        <br /> - 평소 외출 시보다 조금 넉넉하게 준비해 <br />&nbsp;&nbsp;&nbsp;주세요. <br />
        <br /> <b>5. 촬영하실 테마를 미리 선택해 두세요.</b> <br />- 당일 현장에서 테마 선택 시, 촬영 시간이
        단<br />&nbsp;&nbsp;&nbsp;축될 수 있어요.
        <br /> - 테마당 의상 1벌, 액세사리 1가지로 촬영이<br /> &nbsp;&nbsp;&nbsp;진행된답니다.
        <br /> - 촬영 소요시간은 아기 컨디션에 따라 다소 <br />&nbsp;&nbsp; 변경될 수 있어요. <br />
        <br /> <b>※ 100일 촬영 적정 시기 : 생후 120일~130일</b>
        <b>
          <br />※ 평균 30분 안팎의 시간이 촬영에 소요되어 <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;요. 1시간 이상 경과 시 아기가 힘들 수<br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;있어요.
        </b>`,
    },
    {
      title: '돌 촬영 준비',
      description: `<b>1. 예약 시간에 늦지 않게 도착해 주세요.</b> <br />- 예약시간 15분 전 도착 후, 아기가 적응할 수<br /> &nbsp;&nbsp;&nbsp;있도록
        도와주세요(낯가림이 심하면 조금 더 <br />&nbsp;&nbsp;&nbsp;일찍 오세요.)
        <br /> - 가족 촬영 예약 고객 중, 헤어&메이크업 <br /> &nbsp;&nbsp;&nbsp;서비스를 추가하셨나요? 1시간 전에 방문<br />&nbsp;&nbsp;&nbsp;해 주세요. <br />
        <br /> <b>2. 아기 컨디션을 최상으로 맞춰주세요.</b> <br />- 충분한 수면은 좋은 컨디션의 출발이에요.
        <br /> - 촬영 당일 아기 목욕은 피해주세요.
        <br /> - 촬영 전 7일 이내, 예방접종을 피해주세요.
        <br /> - 모자 쓰는 연습도 미리미리 해주세요.
        <br /> - 아기 낮잠 시간을 피해 촬영 예약시간을 <br />&nbsp;&nbsp; 조절해 주세요 (카카오톡 채널 이용) <br />
        <br /> <b>3. 준비물을 잊지 마세요.</b> <br />- 분유, 기저귀, 가제수건
        <br /> - 익숙한 장난감, 즐겨먹는 간식
        <br /> - 평소 외출 시보다 조금 넉넉하게 준비해 <br />&nbsp;&nbsp;&nbsp;주세요. <br />
        <br /> <b>4. 가족 촬영 함께 예약되어 있나요?</b> <br />- 통일감 있는 패밀리룩으로 맞춰 입으면 좋아<br />&nbsp;&nbsp;&nbsp;요.
        <br /> - 드레스&정장으로 준비하셨다면, 신발&양말<br />&nbsp;&nbsp;&nbsp;까지 준비하세요.
        <br /> - 원색 계열의 의상, 후드티셔츠, 프린트 셔츠 <br />&nbsp;&nbsp;&nbsp;는 추천하지 않아요.
        <br /> - 의상은 미리 입고 방문해 주세요. <br />
        <br /> <b>[헤어&메이크업 / 의상 대여 서비스를 신청하신 고객님 준비사항]</b> <br />&nbsp;· 헤어는 샴푸 후 완전히
        말리고 오세요.
        <br /> &nbsp;· 메이크업은 피부 화장만 하고 오세요 <br />&nbsp;&nbsp;&nbsp;(쿠션 or 비비크림)
        <br /> &nbsp;· 어머님 의상은 갈아입기 편한 남방류로 입고 <br />&nbsp;&nbsp;&nbsp;오시면 좋아요.
        <br /> &nbsp;· 의상 대여는 당일 구비된 의상 중 대여가 <br /> &nbsp;&nbsp; 가능해요.
        <br /> <b>5. 형제/자매/남매 촬영이 함께 예약되어 <br /> &nbsp;&nbsp;&nbsp;&nbsp;있나요?</b> <br />- 통일감 있는 의상으로 준비해 주세요.
        <br /> - 패밀리룩으로 이어서 촬영하실 수 있어요. <br />
        <br /> <b>※ 돌 촬영 적정 시기</b> <br />&nbsp;· 생후 300일 ~ 330일
        <br /> &nbsp;· 사물을 잡은 채 혼자 서있을 수 있어요.
        <br /> &nbsp;· 돌잔치 DP용 액자가 필요하신가요?<br /> &nbsp;&nbsp;사진 선택 후 약 6주가 소요되니, 촬영 시기를 <br />&nbsp;&nbsp;체크해보세요.​
        <br />
        <br />
        <b>TIP. 종종 바깥 외출을 통해 낯선 환경, 자극이 <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;무섭지 않게 도와주세요.</b>`,
    },
  ];

  // tips 전체 데이터 조회
  const handleQueryTipsList = async () => {
    try {
      const response = await client.get('api/v1/articles/tips/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      setTipsData(response.data.results);
    } catch (error) {
      console.error(error);
    }
  };

  // tips 버튼 업데이트
  const handleUpdateTipsButtons = async () => {
    try {
      const buttonData = (Object.keys(tipsButtonsValue) as ('button1' | 'button2' | 'button3' | 'button4')[]).map(
        (buttonKey, index) => {
          return {
            id: index + 1,
            title: tipsButtonsValue[buttonKey],
          };
        },
      );

      const requests = buttonData.map((data) =>
        client.post(`api/v1/articles/tips/${data.id}/`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${userToken}`,
          },
          withCredentials: true,
        }),
      );

      await Promise.all(requests);

      message.success({ content: '버튼 수정이 완료되었습니다.' });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancleEdit = () => {
    setIsActiveEditFirstArticle(false);
    setIsActiveEditSecondArticle(false);
    setIsActiveEditThirdArticle(false);
    setIsActiveEditArticle(false);
    setIsEditing(false);
  };

  // tips 아티클 수정
  const handleArticleEdit = () => {
    setTipsArticleData({
      ...tipsArticleData,
      id: activeIndex,
    });

    if (tipsArticleData.content === '') {
      setIsActiveEditButtons(false);
      setIsHoverTipsButtons(false);
      return;
    }

    try {
      client.post(`api/v1/articles/tips/${activeIndex}/`, tipsArticleData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      message.success({ content: '아티클 수정이 완료되었습니다.' });
      navigate('/ilsang-admin/about/tips');
    } catch (error) {
      console.error(error);
    }
  };

  // tips 전체 데이터 불러오기
  useEffect(() => {
    handleQueryTipsList();
  }, []);

  useEffect(() => {
    console.log(activeTipsData);
  }, [activeTipsData, activeIndex]);

  return (
    <>
      <Helmet>
        <title>관리자 | Tips — 눈부신 일상</title>
      </Helmet>

      <Container>
        <OuterWrapper>
          <InnerWrapper>
            <TipsWrapper>
              {IS_EDIT_MODE && <TipsTitle>TIPS</TipsTitle>}
              <TipsItemsWrapper
                isHoverTipsButtons={isHoverTipsButtons}
                isActiveEditTipsButtons={isActiveEditTipsButtons}
                onMouseEnter={() => setIsHoverTipsButtons(true)}
                onMouseLeave={() => setIsHoverTipsButtons(false)}
              >
                {IS_EDIT_MODE &&
                  shootingRefs.map((shootingRef, index) => (
                    <TipsItem
                      key={index}
                      style={{
                        backgroundColor: activeIndex === index ? '#1E1F18' : '#fff',
                      }}
                    >
                      <TipsText
                        style={{
                          color: activeIndex === index ? '#fff' : '#000',
                        }}
                      >
                        {['만삭 촬영', '50일 촬영', '100일 촬영', '돌 촬영'][index]}
                      </TipsText>
                    </TipsItem>
                  ))}
                {isHoverTipsButtons && IS_EDIT_MODE && !isEditing && (
                  <TipsEditButton onClick={() => setIsActiveEditButtons((prev) => !prev)}>수정</TipsEditButton>
                )}
                {/* TIPS 버튼 수정 모드 */}
                {isActiveEditTipsButtons && IS_TIPS_BUTTONS_EDIT_MODE && (
                  <TipsEditWrapper>
                    <h2>TIPS 버튼 텍스트 수정</h2>
                    <Space direction="vertical" size="middle">
                      <Space.Compact>
                        <Input
                          style={{
                            width: '28%',
                          }}
                          defaultValue="1번 버튼"
                          readOnly
                        />
                        <Input
                          style={{
                            width: '72%',
                          }}
                          placeholder="만삭 촬영"
                          value={tipsButtonsValue.button1}
                          onChange={handleTipsButtonChange('button1')}
                        />
                      </Space.Compact>
                      <Space.Compact>
                        <Input
                          style={{
                            width: '28%',
                          }}
                          defaultValue="2번 버튼"
                          readOnly
                        />
                        <Input
                          style={{
                            width: '72%',
                          }}
                          placeholder="50일 촬영"
                          value={tipsButtonsValue.button2}
                          onChange={handleTipsButtonChange('button2')}
                        />
                      </Space.Compact>
                      <Space.Compact>
                        <Input
                          style={{
                            width: '28%',
                          }}
                          defaultValue="3번 버튼"
                          readOnly
                        />
                        <Input
                          style={{
                            width: '72%',
                          }}
                          placeholder="100일 촬영"
                          value={tipsButtonsValue.button3}
                          onChange={handleTipsButtonChange('button3')}
                        />
                      </Space.Compact>
                      <Space.Compact>
                        <Input
                          style={{
                            width: '28%',
                          }}
                          defaultValue="4번 버튼"
                          readOnly
                        />
                        <Input
                          style={{
                            width: '72%',
                          }}
                          placeholder="돌 촬영"
                          value={tipsButtonsValue.button4}
                          onChange={handleTipsButtonChange('button4')}
                        />
                      </Space.Compact>
                    </Space>
                    <TipsEditButtonWrapper>
                      <CancelButton
                        onClick={() => {
                          setIsActiveEditButtons(false);
                          setIsHoverTipsButtons(false);
                        }}
                      >
                        취소
                      </CancelButton>
                      <SaveButton onClick={handleUpdateTipsButtons}>저장</SaveButton>
                    </TipsEditButtonWrapper>
                  </TipsEditWrapper>
                )}
              </TipsItemsWrapper>
            </TipsWrapper>
          </InnerWrapper>
          {IS_EDIT_MODE &&
            shootingRefs.map((shootingRef, index) => (
              <DescriptionContainer
                key={index}
                ref={shootingRef.element}
                hoverState={hoverState[index]}
                onMouseEnter={() => hoverStateSetters[index](true)}
                onMouseLeave={() => hoverStateSetters[index](false)}
              >
                <DivideLine />
                <DescriptionWrapper>
                  {IS_EDIT_MODE && isLg && !isSm && (
                    <>
                      <DescriptionTitle>{tipsData?.[index]?.title}</DescriptionTitle>
                      <StyledDescription dangerouslySetInnerHTML={{ __html: tipsData?.[index]?.content || '' }} />

                      {hoverState[index] && IS_EDIT_MODE && (
                        <TipsArticleEditButton
                          onClick={() => {
                            setIsActiveEditFirstArticle((prev) => !prev);
                            setActiveIndex(index + 1);
                            setIsEditing(true);
                          }}
                        >
                          수정
                        </TipsArticleEditButton>
                      )}
                    </>
                  )}

                  {IS_EDIT_MODE && isSm && !isXs && (
                    <>
                      <DescriptionTitle>{tipsData?.[index]?.title}</DescriptionTitle>
                      <StyledDescription dangerouslySetInnerHTML={{ __html: tipsData?.[index]?.content || '' }} />

                      {hoverState[index] && IS_EDIT_MODE && (
                        <TipsArticleEditButton
                          onClick={() => {
                            setIsActiveEditFirstArticle((prev) => !prev);
                            setActiveIndex(index + 1);
                            setIsEditing(true);
                          }}
                        >
                          수정
                        </TipsArticleEditButton>
                      )}
                    </>
                  )}

                  {IS_EDIT_MODE && isXs && (
                    <>
                      <DescriptionTitle>{tipsData?.[index]?.title}</DescriptionTitle>
                      <StyledDescription dangerouslySetInnerHTML={{ __html: tipsData?.[index]?.content || '' }} />

                      {hoverState[index] && IS_EDIT_MODE && (
                        <TipsArticleEditButton
                          onClick={() => {
                            setIsActiveEditFirstArticle((prev) => !prev);
                            setActiveIndex(index + 1);
                            setIsEditing(true);
                            setActiveTipsData(tipsData ? tipsData[activeIndex] : null);
                          }}
                        >
                          수정
                        </TipsArticleEditButton>
                      )}
                    </>
                  )}
                </DescriptionWrapper>
              </DescriptionContainer>
            ))}
          {/* Tips 아티클 수정 모드 */}
          {isEditing && (
            <TipsArticleEditWrapper>
              <h2>Tips 페이지 수정</h2>
              <EditDescription>- 제목과 내용 둘 중 하나만 수정해도 됩니다.</EditDescription>
              <Form name="basic" onFinish={handleArticleEdit} autoComplete="off">
                <Form.Item label="제목" name="title">
                  <EditModeInput value={activeTipsData?.title || ''} />
                </Form.Item>
                <Form.Item label="내용" name="content">
                  <TextEditor
                    className="form-control text-editor"
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    // TODO: 기존 데이터 가져오기
                    value={editorValue || activeTipsData?.content}
                    onChange={(content, delta, source, editor) => {
                      setEditorValue(editor.getHTML());
                      setTipsArticleData({ ...tipsArticleData, content: editor.getHTML() });
                    }}
                  />
                </Form.Item>
                <EditButtonWrapper>
                  <CancelButton type="button" onClick={handleCancleEdit}>
                    취소
                  </CancelButton>
                  <SaveButton type="submit">저장</SaveButton>
                </EditButtonWrapper>
              </Form>
            </TipsArticleEditWrapper>
          )}
        </OuterWrapper>
      </Container>
    </>
  );
};
export default AdminTipsPage;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ${MEDIA_QUERY.lg} {
    padding: 0 19.5rem 0 21.4rem;
  }

  ${MEDIA_QUERY.sm} {
    padding: 0 3.4rem;
    margin-bottom: -13rem;
  }

  ${MEDIA_QUERY.xs} {
    padding: 0 1.5rem;
  }
`;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${MEDIA_QUERY.lg} {
    width: 67.5rem;
    margin-bottom: 13rem;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TipsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TipsTitle = styled.span`
  font-family: 'EB Garamond', serif;
  font-size: 1.7rem;
  letter-spacing: 8.4px;
  color: #1e1f18;
  margin-bottom: 1rem;
`;

interface TipsItemsWrapperProps {
  isHoverTipsButtons: boolean;
  isActiveEditTipsButtons: boolean;
}

const TipsItemsWrapper = styled.div<TipsItemsWrapperProps>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 3.9rem;
  gap: 10px;

  ${(props) =>
    props.isHoverTipsButtons &&
    !props.isActiveEditTipsButtons &&
    css`
      opacity: 0.3;
    `}

  ${MEDIA_QUERY.sm} {
    width:100%;
  }

  ${MEDIA_QUERY.xs} {
    width: 23rem;
  }
`;

const TipsItem = styled.div`
  height: 2.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c1c1c1;
  border-radius: 1.4rem;
  margin-right: 1rem;
  cursor: pointer;
  white-space:nowrap;
  
  ${MEDIA_QUERY.lg} {
    padding: 1.2rem 0.8rem 1.2rem;
  }

  ${MEDIA_QUERY.sm} {
    margin-right: 0;
  }

  ${MEDIA_QUERY.xs} {
    padding: 0.5rem;
  }
`;

const TipsText = styled.span`
  font-family: ArgentumSansRegular;
  color: #1e1f18;
  line-height: 19px;

  ${MEDIA_QUERY.lg} {
    font-size: 1rem;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1.2rem;
  }

  ${MEDIA_QUERY.xs} {
    font-size: 1rem;
  }
`;

interface DescriptionContainerProps {
  hoverState: boolean;
}

const DescriptionContainer = styled.div<DescriptionContainerProps>`
  ${(props) =>
    props.hoverState &&
    css`
      opacity: 0.3;
    `}
`;

const DescriptionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2.5rem 0;
`;

const DivideLine = styled.div`
  width: 11rem;
  border-top: 1px solid #707070;

  ${MEDIA_QUERY.xs} {
    margin-left: 1rem;
  }
`;

const DescriptionTitle = styled.span`
  color: #1e1f18;
  font-family: 'NanumSquareBold';
  font-size: 1.3rem;
  margin-bottom: 1.6rem;
`;

const Description = styled.p`
  color: #1e1f18;
  font-family: 'Nanum Myeongjo', serif;
  letter-spacing: -0.26px;
  line-height: 22px;
  font-size: 1.3rem;
  margin: 0;
`;

// 관리자 스타일
const TipsEditButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
`;

const TipsEditButton = styled.button`
  width: 5rem;
  height: 2.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  color: #fff;
  background-color: #000;
  opacity: 1;
  cursor: pointer;
`;

const TipsArticleEditButton = styled.button`
  width: 5rem;
  height: 2.5rem;
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  border: none;
  color: #fff;
  background-color: #000;
  opacity: 1;
  cursor: pointer;
`;

const CancelButton = styled.button`
  width: 10rem;
  height: 3rem;
  border: 1px solid #000;
  color: #000;
  background-color: #fff;
  cursor: pointer;
`;

const SaveButton = styled.button`
  width: 10rem;
  height: 3rem;
  border: none;
  color: #fff;
  background-color: #000;
  cursor: pointer;
`;

const TipsEditWrapper = styled.div``;

const TipsArticleEditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const TextEditor = styled(ReactQuill)`
  .ql-container {
    height: 40rem;
    font-family: 'Gowun Batang', serif;
  }
`;

const EditButtonWrapper = styled.div`
  width: 22rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: 2rem;
`;

const StyledDescription = styled(Description)`
  p {
    margin: 0;
  }
`;

const EditModeInput = styled(Input)`
  font-family: 'Gowun Batang', serif;
  font-size: 1.3rem;
`;

const EditDescription = styled.span`
  color: #1f1818;
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;
