import styled from 'styled-components';
import MEDIA_QUERY from '../../../constants/mediaQuery';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '../../../hook/useMediaQuery';

const MainFooter = () => {
  const navigate = useLocation();
  const IS_BRANCHES = navigate.pathname.includes('/branches');

  const { isSm, isLg } = useMediaQuery();

  return (
    <>
      <Container>
        <ContactItems>
          <a
            href="https://www.instagram.com/studio_ilsang/"
            style={{ display: 'flex', alignItems: 'center', marginRight: '1.2rem' }} // 인라인 스타일 추가
            target="_blank" // 새 창에서 열기
            rel="noopener noreferrer" // 보안 강화
          >
            <ContactItem src="/images/insta.svg" alt="인스타 바로가기 버튼" />
          </a>

          <ContactItem src="/images/youtube.svg" alt="유튜브 바로가기 버튼" />
          {/* <ContactItem src="/images/facebook.svg" alt="페이스북 바로가기 버튼" /> */}
          <a
            href="https://cafe.naver.com/ilsangstudio"
            style={{ display: 'flex', alignItems: 'center' }}
            target="_blank" // 새 창에서 열기
            rel="noopener noreferrer" // 보안 강화// 인라인 스타일 추가
          >
            <ContactItem
              src="/images/naver.png"
              alt="네이버 바로가기 버튼"
              style={{ width: '16px', marginRight: '1.2rem' }}
            />
          </a>

          <ContactItem src="/images/mail.svg" alt="메일 바로가기 버튼" />
        </ContactItems>
        <FooterText>눈부신 일상</FooterText>
        <CompanyInfo>
          <CompanyInfoText>
            대표 : 박혜령 <br />
            사업자 번호 : 556-88-02815 <br />
            서울특별시 강서구 공항대로 247, C동 712호 일부(마곡동, 퀸즈 파크나인)
          </CompanyInfoText>
        </CompanyInfo>
        <CopyRightWrapper>
          <CopyRightTopWrapper>
            <CopyRight>COPYRIGHT@ </CopyRight>
            <CompanyName>2024 ILSANG STUDIO</CompanyName>
          </CopyRightTopWrapper>
          <CopyRight>ALL RIGHTS RESERVED</CopyRight>
          <CompanyName>시스템개발은 (주)럽맘(사랑해엄마)에서 이루어 졌습니다.</CompanyName>
        </CopyRightWrapper>
      </Container>

      {/* 모바일 화면에서 branches 페이지 스타일 조정이 필요해서 분기 처리 */}
      {!isLg && isSm && IS_BRANCHES && (
        <Container style={{ marginTop: '3.7rem' }}>
          <ContactItems>
            <img src="/images/insta.svg" alt="인스타 바로가기 버튼" />
            <img src="/images/youtube.svg" alt="유튜브 바로가기 버튼" />
            <img src="/images/facebook.svg" alt="페이스북 바로가기 버튼" />
            <img src="/images/mail.svg" alt="메일 바로가기 버튼" />
          </ContactItems>
          <FooterText>눈부신 일상</FooterText>
          <CompanyInfo>
            <CompanyInfoText>
              대표 : 박혜령 <br />
              사업자 번호 : 556-88-02815 <br />
              서울특별시 강서구 공항대로 247, 씨동 712호 일부(마곡동, 퀸즈 파크나인)
            </CompanyInfoText>
          </CompanyInfo>
          <CopyRightWrapper>
            <CopyRightTopWrapper>
              <CopyRight>COPYRIGHT@ </CopyRight>
              <CompanyName>2024 ILSANG STUDIO</CompanyName>
            </CopyRightTopWrapper>
            <CopyRight>ALL RIGHTS RESERVED</CopyRight>
            <CopyRight>시스템개발은 (주)럽맘(사랑해엄마)에서 이루어 졌습니다.</CopyRight>
          </CopyRightWrapper>
        </Container>
      )}
    </>
  );
};

export default MainFooter;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  opacity: 1;
  padding: 0 33.3rem 6.9rem;

  ${MEDIA_QUERY.md} {
    padding: 4.8rem;
  }

  ${MEDIA_QUERY.sm} {
    height: 41.5rem;
    margin-top: 10.4rem;
    padding: 0 8.1rem 0 8.2rem;
    background-color: #fafafa;

    font-size: 1.3rem;
  }

  ${MEDIA_QUERY.xs} {
    padding: 0 3rem;
  }
`;

const ContactItems = styled.div`
  width: 14rem;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-bottom: 3.1rem;

  order: 1;

  ${MEDIA_QUERY.lg} {
    margin: 13rem 0 14.7rem;
  }

  ${MEDIA_QUERY.sm} {
    order: 2;

    margin: 0 0 3.1rem;
  }
`;

const ContactItem = styled.img`
  margin-right: 1.2rem;

  &:last-child {
    margin-right: 0;
  }
`;

const FooterText = styled.span`
  color: #424530;
  font-family: 'NanumSquareNeo';
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.96px;
  margin-bottom: 2.8rem;

  order: 2;

  ${MEDIA_QUERY.sm} {
    order: 1;
    font-size: 1.2rem;
    margin-bottom: 7.5rem;
  }
`;

const CompanyInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  order: 3;
`;

const CompanyInfoText = styled.p`
  color: #424530;
  font-family: 'NanumSquareNeoLight';
  font-size: 1rem;
  font-weight: 200;
  text-align: center;
  margin-bottom: 1.5rem;

  ${MEDIA_QUERY.lg} {
    letter-spacing: 0.32px;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1rem;
  }
`;

const CopyRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  order: 4;
`;

const CopyRightTopWrapper = styled.div`
  display: flex;
  margin-bottom: 0.2rem;
`;

const CopyRight = styled.span`
  color: #424530;
  text-align: center;
  font-family: 'EB Garamond', serif;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1rem;

  ${MEDIA_QUERY.md} {
    letter-spacing: 0.32px;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1rem;
    letter-spacing: 0.4px;
  }
`;

const CompanyName = styled.span`
  color: #424530;
  font-family: 'NanumSquareNeoRegular';
  letter-spacing: 0.32px;
  font-size: 0.8rem;
  line-height: 1rem;

  ${MEDIA_QUERY.sm} {
    font-size: 1rem;
    letter-spacing: 0.4px;
  }
`;
