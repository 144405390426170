import styled from 'styled-components';
import MEDIA_QUERY from '../../../constants/mediaQuery';
import { Link, NavLink } from 'react-router-dom';
import { useState } from 'react';
import HamburgerTapMenu from '../../common/HamburgerTapMenu';

const AdminHeader = () => {
  // 메뉴 드롭다운
  const [actvieMenuDropDown, setActvieMenuDropDown] = useState('');

  // 모바일 & 태블릿 메뉴 (햄버거 버튼)
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  // 메뉴 hover 스타일
  const [activeMenu, setActiveMenu] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (menu: any) => {
    setActiveMenu(menu);
    setIsHovered(true);

    if (menu !== 'about' && menu !== 'portfolio' && menu !== 'contact') {
      setActvieMenuDropDown('');
    }
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setActiveMenu(null);
      setIsHovered(false);
    }, 2000);
  };

  const closeHoverMenu = () => {
    setActvieMenuDropDown('');
  };

  return (
    <>
      <Container>
        <Wrapper>
          <InnerWrapper>
            <Link to="/ilsang-admin/home">
              <MainLogoImage src="/images/logo.svg" alt="눈부신 일상 로고" />
            </Link>
            <MainMenuWrapper onMouseLeave={handleMouseLeave}>
              <MainMenuItem>
                <MainMenuLink
                  to="/ilsang-admin/about/ilsang"
                  name="about"
                  active={activeMenu}
                  hovered={isHovered}
                  onMouseEnter={() => handleMouseEnter('about')}
                  onMouseOver={() => setActvieMenuDropDown('about')}
                >
                  ABOUT
                </MainMenuLink>
                {actvieMenuDropDown === 'about' && (
                  <DropdownMenu
                    onMouseLeave={closeHoverMenu}
                    style={{
                      width: '6.3rem',
                      height: '5.3rem',
                      padding: '1rem 1.6rem 0 1.2rem',
                      marginTop: '0.6rem',
                    }}
                  >
                    <HoverMenuItem to="/ilsang-admin/about/ilsang" style={{ marginBottom: '0.6rem' }}>
                      ilsang
                    </HoverMenuItem>
                    <HoverMenuItem to="/ilsang-admin/about/tips">tips</HoverMenuItem>
                  </DropdownMenu>
                )}
              </MainMenuItem>
              <MainMenuItem>
                <MainMenuLink
                  to="/ilsang-admin/portfolio/maternity/newborn"
                  name="portfolio"
                  active={activeMenu}
                  hovered={isHovered}
                  onMouseEnter={() => handleMouseEnter('portfolio')}
                  onMouseOver={() => setActvieMenuDropDown('portfolio')}
                >
                  PORTFOLIO
                </MainMenuLink>
                {actvieMenuDropDown === 'portfolio' && (
                  <DropdownMenu
                    onMouseLeave={closeHoverMenu}
                    style={{
                      width: '15.9rem',
                      height: '12.8rem',
                      padding: '1rem 1.3rem 0 1rem',
                      marginTop: '0.6rem',
                      boxSizing: 'border-box',
                    }}
                  >
                    <HoverMenuItem to="/ilsang-admin/portfolio/maternity/newborn" style={{ marginBottom: '0.6rem' }}>
                      MATERNITY/NEWBORN
                    </HoverMenuItem>
                    <HoverMenuItem to="/ilsang-admin/portfolio/baby/family" style={{ marginBottom: '0.6rem' }}>
                      BABY/FAMILY
                    </HoverMenuItem>
                    <HoverMenuItem to="/ilsang-admin/portfolio/profile/junior" style={{ marginBottom: '0.6rem' }}>
                      PROFILE/JUNIOR
                    </HoverMenuItem>
                    <HoverMenuItem to="/ilsang-admin/portfolio/family" style={{ marginBottom: '0.6rem' }}>
                      FAMILY
                    </HoverMenuItem>
                    <HoverMenuItem
                      to="/ilsang-admin/portfolio/profile/event/photography"
                      style={{ marginBottom: '0.6rem' }}
                    >
                      EVENT PHOTOGRAPHY
                    </HoverMenuItem>
                    <HoverMenuItem to="/ilsang-admin/portfolio/product">PRODUCT</HoverMenuItem>
                  </DropdownMenu>
                )}
              </MainMenuItem>
              <MainMenuItem>
                <MainMenuLink
                  to="/ilsang-admin/price"
                  name="price"
                  active={activeMenu}
                  hovered={isHovered}
                  onMouseOver={() => handleMouseEnter('price')}
                >
                  PRICE
                </MainMenuLink>
              </MainMenuItem>
              <MainMenuItem>
                <MainMenuLink
                  to="/ilsang-admin/branches/korea"
                  name="branches"
                  active={activeMenu}
                  hovered={isHovered}
                  onMouseOver={() => handleMouseEnter('branches')}
                >
                  BRANCHES
                </MainMenuLink>
              </MainMenuItem>
              <MainMenuItem>
                <MainMenuLink
                  to="/ilsang-admin/contact"
                  name="contact"
                  active={activeMenu}
                  hovered={isHovered}
                  onMouseEnter={() => handleMouseEnter('contact')}
                  onMouseOver={() => setActvieMenuDropDown('contact')}
                >
                  CONTACT
                </MainMenuLink>
                {actvieMenuDropDown === 'contact' && (
                  <DropdownMenu
                    onMouseLeave={closeHoverMenu}
                    style={{
                      width: '7.5rem',
                      height: '6.7rem',
                      padding: '1rem',
                      marginTop: '0.6rem',
                    }}
                  >
                    <HoverMenuItem
                      to="/ilsang-admin/contact"
                      style={{
                        marginBottom: '0.5rem',
                        fontFamily: 'NanumSquareNeoBold',
                        fontSize: '0.9rem',
                        fontWeight: '400',
                      }}
                    >
                      예약 및 문의
                    </HoverMenuItem>
                    <HoverMenuItem
                      to="/ilsang-admin/contact/partnerships"
                      style={{
                        marginBottom: '0.5rem',
                        fontFamily: 'NanumSquareNeoBold',
                        fontSize: '0.9rem',
                        fontWeight: '400',
                      }}
                    >
                      제휴문의
                    </HoverMenuItem>
                    <HoverMenuItem
                      to="/ilsang-admin/contact/franchisee"
                      style={{ fontFamily: 'NanumSquareNeoBold', fontSize: '0.9rem', fontWeight: '400' }}
                    >
                      가맹
                    </HoverMenuItem>
                  </DropdownMenu>
                )}
              </MainMenuItem>
            </MainMenuWrapper>
          </InnerWrapper>

          {/* 태블릿, 모바일 메뉴 버튼*/}
          <MenuButton
            src="/images/hamburgerBtn.svg"
            alt="메뉴 버튼"
            onClick={() => setOpenMobileMenu((prev) => !prev)}
          />
          {openMobileMenu && <HamburgerTapMenu setOpenMobileMenu={setOpenMobileMenu} />}
        </Wrapper>
      </Container>
    </>
  );
};

export default AdminHeader;

const Container = styled.div`
  width: 100%;
  background-color: #fff;

  ${MEDIA_QUERY.lg} {
    padding: 7.9rem 20rem 0;
  }

  ${MEDIA_QUERY.md} {
    padding: 7.9rem 0 0;
  }

  ${MEDIA_QUERY.sm} {
    height: 7.2rem;
    padding: 2.7rem 2.1rem 0;
    margin-bottom: 1rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${MEDIA_QUERY.lg} {
    width: 67.5rem;
  }

  ${MEDIA_QUERY.md} {
    width: 64rem;
  }
`;

const MainLogoImage = styled.img`
  ${MEDIA_QUERY.lg} {
    width: 11rem;
    height: 6rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 8.1rem;
    height: 4.4rem;
  }
`;

const MainMenuWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* 모바일, 태블릿일 때 보이지 않게 */
  ${MEDIA_QUERY.md} {
    display: none;
  }
`;

const MainMenuItem = styled.div`
  position: relative;
  margin-right: 1.5rem;
  color: #424530;
  font-family: 'ArgentumSans';
  letter-spacing: 0.8px;
  line-height: 13px;
  cursor: pointer;

  /* 모바일, 태블릿일 때 보이지 않게 */
  ${MEDIA_QUERY.md} {
    display: none;
  }
`;

interface MainMenuLinkProps {
  active: string | null;
  hovered: boolean;
  name: string;
}

const MainMenuLink = styled(NavLink)<MainMenuLinkProps>`
  color: ${(props) => (props.hovered && props.name !== props.active ? '#c1c1c1' : '#000')};
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;

  -webkit-font-smoothing: subpixel-antialiased;
  transform: translate3d(0, 0, 0);
  transition: left 0s linear, transform 0.1s ease-out, opacity 0.1s ease-out, color 0.2s ease-in-out;

  :visited {
    text-decoration: none;
  }
`;

const MenuButton = styled.img`
  /* 모바일, 태블릿일 때 보이게 */
  ${MEDIA_QUERY.lg} {
    display: none;
  }

  ${MEDIA_QUERY.md} {
    display: block;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fcfcfc;

  transition: opacity 0.3s ease;

  // 애니메이션
  -webkit-animation: slide-top 0.2s ease-out both;
  animation: slide-top 0.2s ease-out both;

  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
      opacity: 1;
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
      opacity: 1;
    }
  }

  :hover {
    display: block;
  }
`;

const HoverMenuItem = styled(Link)`
  color: #c1c1c1;
  font-family: 'ArgentumSans';
  font-size: 1rem;
  font-weight: 100;
  letter-spacing: 0.8px;
  line-height: 13px;
  text-align: left;
  text-decoration: none;
  -webkit-font-smoothing: subpixel-antialiased;
  transform: translate3d(0, 0, 0);
  transition: left 0s linear, transform 0.1s ease-out, opacity 0.1s ease-out, color 0.1s ease-in-out;

  &:hover {
    color: #424530;
  }
`;
