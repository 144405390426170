import { useEffect, useState } from 'react';
import styled from 'styled-components';

const PageMountTransition = (Component: React.ComponentType) => (props: any) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <FadeInWrapper isMounted={isMounted}>
      <Component {...props} />
    </FadeInWrapper>
  );
};

export default PageMountTransition;

interface FadeInWrapperProps {
  isMounted: boolean;
}

const FadeInWrapper = styled.div<FadeInWrapperProps>`
  opacity: ${(props) => (props.isMounted ? '1' : '0')};
  transition: opacity 0.8s ease-in-out;
`;
