import { Card, Space } from 'antd';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const ContractDetailPage = () => {
  const location = useLocation();
  const contactData = location.state?.contactData;

  return (
    <ReservationDetailWrapper>
      <Space direction="vertical" size={16}>
        <Card title="제휴 문의 상세 내역" style={{ width: 800 }}>
          <ReservationDataWrapper>
            <span>이름 : {contactData.name}</span>
            <span>전화번호 : {contactData.digit}</span>
            <span>지점명 : {contactData.office_selection}</span>
            <span>사진 선택 : {contactData.shooting_selection}</span>
            <span>희망 날짜 : {moment(contactData.desired_date).format('YYYY-MM-DD')}</span>
            <span>문의 내용 : {contactData.content}</span>
          </ReservationDataWrapper>
        </Card>
      </Space>
    </ReservationDetailWrapper>
  );
};

export default ContractDetailPage;

const ReservationDetailWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReservationDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
