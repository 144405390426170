import styled from 'styled-components';
import MEDIA_QUERY from '../../../constants/mediaQuery';
import useMediaQuery from '../../../hook/useMediaQuery';

const CompleteForm = () => {
  const { isLg, isSm } = useMediaQuery();

  return (
    <Container>
      <OuterWrapper>
        {isLg && !isSm && (
          <InnerWrapper>
            <CompleteFormTitle>THANK YOU!</CompleteFormTitle>
            <CompleteFormMessage>문의 내용이 접수되었어요. 확인 후 연락드릴게요.</CompleteFormMessage>
          </InnerWrapper>
        )}

        {isSm && (
          <InnerWrapper>
            <MobileCompleteFormWrapper>
              <CompleteFormTitle>THANK YOU!</CompleteFormTitle>
              <CompleteFormMessage>
                문의 내용이 접수되었어요. <br />
                확인 후 연락드릴게요.
              </CompleteFormMessage>
            </MobileCompleteFormWrapper>
          </InnerWrapper>
        )}
      </OuterWrapper>
    </Container>
  );
};

export default CompleteForm;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;

  ${MEDIA_QUERY.sm} {
    margin-top: 25rem;
  }

  ${MEDIA_QUERY.xs} {
    margin: 12rem 0 -3rem;
  }
`;

const OuterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.lg} {
    margin-bottom: 7rem;
  }
`;

const MobileCompleteFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const CompleteFormTitle = styled.span`
  font-family: 'ArgentumSansLight';
  font-size: 1.2rem;
  letter-spacing: 4.8px;
  color: #1e1f18;

  ${MEDIA_QUERY.lg} {
    margin-bottom: 1rem;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1.5rem;
    margin-bottom: 2.4rem;
    letter-spacing: 6px;
  }
`;

const CompleteFormMessage = styled.span`
  color: #474747;
  font-size: 1.1rem;

  ${MEDIA_QUERY.sm} {
    font-size: 1.2rem;
    line-height: 20px;
  }
`;
