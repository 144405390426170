import styled from 'styled-components';
import MEDIA_QUERY from '../../../constants/mediaQuery';
import useMediaQuery from '../../../hook/useMediaQuery';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import client from '../../../api/axios';
import { PortfolioDataType, ResultsType } from '../../admin/Portfolio/babyFamily';

const ProfileJuniorPage = () => {
  const { isSm, isLg } = useMediaQuery();

  // 페이지의 첫 4개의 사진만 프리로딩
  const imagesToPreload = [
    '/images/portfolio/3.profile&junior/pj (23).jpg',
    '/images/portfolio/3.profile&junior/pj (2).jpg',
    '/images/portfolio/3.profile&junior/pj (1).jpg',
    '/images/portfolio/3.profile&junior/pj (25).jpg',
  ];

  const [leftPhotoItems, setLeftPhotoItems] = useState<PortfolioDataType[]>([]);
  const [rightPhotoItems, setRightPhotoItems] = useState<PortfolioDataType[]>([]);

  // 포트폴리오 Maternity/Newborn 데이터 조회
  const handleQueryMaternityNewbornImages = async () => {
    try {
      const response = await client.get('api/v1/portfolios/portfolio/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token 71c1e61404bcf64bc69306e0e6eeb60cd47ae766`,
        },
        withCredentials: true,
      });

      let resultsWithMCategories: ResultsType = {};

      Object.keys(response.data.results2).forEach((key: string) => {
        let filteredResults = response.data.results2[key].filter(
          (item: PortfolioDataType) => item.portfolio_id === 3 && item.is_cancel === 'N',
        ); // 카테고리마다 portfolio_id가 다름
        if (filteredResults.length > 0) {
          resultsWithMCategories[key] = filteredResults;
        }
      });

      if (Object.keys(resultsWithMCategories).length > 0) {
        const allItems = Object.values(resultsWithMCategories).flat();

        const filteredLeftItems = allItems.filter((item: PortfolioDataType) => item.direction === 'left');
        const filteredRightItems = allItems.filter((item: PortfolioDataType) => item.direction === 'right');

        setLeftPhotoItems(filteredLeftItems);
        setRightPhotoItems(filteredRightItems);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    imagesToPreload.forEach((imagePath) => {
      const img = new Image();
      img.src = imagePath;
    });
  }, []);

  useEffect(() => {
    handleQueryMaternityNewbornImages();
  }, []);

  return (
    <>
      <Helmet>
        <title>Portfolio — 눈부신 일상</title>
      </Helmet>

      {/* pc 화면 */}
      {isLg && !isSm && (
        <Container>
          <OuterWrapper>
            <PhotoLeftWrapper>
              {leftPhotoItems.map((image, index) => (
                <div key={index}>
                  <PhotoItem src={image.img_url} />
                </div>
              ))}
            </PhotoLeftWrapper>
            <PhotoRightWrapper>
              <TitleWrapper>
                <PortfolioTitle>PROFILE / JUNIOR</PortfolioTitle>
              </TitleWrapper>
              {rightPhotoItems.map((image, index) => (
                <div key={index}>
                  <PhotoItem src={image.img_url} />
                </div>
              ))}
            </PhotoRightWrapper>
          </OuterWrapper>
        </Container>
      )}
      {/* 모바일 화면 */}
      {isSm && (
        <Container>
          <OuterWrapper>
            <PhotoLeftWrapper>
              <TitleWrapperMobile>
                <PortfolioTitle>PROFILE / JUNIOR</PortfolioTitle>
              </TitleWrapperMobile>
              {leftPhotoItems.map((image, index) => (
                <div key={index}>
                  <PhotoItem src={image.img_url} />
                </div>
              ))}
            </PhotoLeftWrapper>
            <PhotoRightWrapper>
              <TitleWrapper>
                <PortfolioTitle>PROFILE / JUNIOR</PortfolioTitle>
              </TitleWrapper>
              {rightPhotoItems.map((image, index) => (
                <div key={index}>
                  <PhotoItem src={image.img_url} />
                </div>
              ))}
            </PhotoRightWrapper>
          </OuterWrapper>
        </Container>
      )}
    </>
  );
};

export default ProfileJuniorPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ${MEDIA_QUERY.lg} {
    margin-top: -6.5rem;
  }

  ${MEDIA_QUERY.md} {
    margin-top: 0;
    padding: 0 4.5rem;
  }

  ${MEDIA_QUERY.sm} {
    margin-top: 0;
    padding: 0 2.8rem;
  }
`;

const OuterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;

  ${MEDIA_QUERY.md} {
    margin: 0;
  }

  ${MEDIA_QUERY.sm} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const TitleWrapper = styled.div`
  width: 35.8rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.lg} {
    margin: 9rem 0 10rem;
  }

  ${MEDIA_QUERY.sm} {
    margin: 0;
    display:none;
  }
`;

const TitleWrapperMobile = styled.div`
  width: 35.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;

  ${MEDIA_QUERY.lg} {
  width: 100%;
  margin: 9rem 0 10rem;
  display: block;
  }

  ${MEDIA_QUERY.sm} {
    margin: 0;
    text-align: center;
    margin: 7.8rem 0 3.2rem;
  }
`;

const PortfolioTitle = styled.span`
  color: #474747;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 2.4px;
  line-height: 15px;
  text-align: center;

  ${MEDIA_QUERY.sm} {
    font-size: 1.3rem;
    letter-spacing: 2.6px;
    margin: 7.8rem 0 3.2rem;
  }
`;

const PhotoLeftWrapper = styled.div`
  width: 32rem;
  display: flex;
  flex-direction: column;

  ${MEDIA_QUERY.sm} {
    align-items: center;
    flex-direction: column;

    margin: 0;
  }
`;

const PhotoRightWrapper = styled.div`
  width: 32rem;
  display: flex;
  flex-direction: column;
  align-items:center;
  ${MEDIA_QUERY.sm} {
    display: flex;
    flex-direction: column;

    margin: 0;
  }
`;

const PhotoItem = styled.img`
  width: 100%;
  padding: 1.5rem;
  box-sizing: border-box;

  ${MEDIA_QUERY.sm} {
    width: 100%;
    max-width:320px;
    padding: 1.5rem 0;
    box-sizing: content-box;
  }

  ${MEDIA_QUERY.xs} {
    width: 25rem;
  }
`;
