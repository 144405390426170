import MainTitleCard from './components/common/MainTitleCard';
import TopButton from './components/common/TopButton';
import { useAtom } from 'jotai';
import { isAdminModeAtom } from './stores';
import MainRoutes from './router/MainRoutes';
import AdminHeader from './components/admin/AdminHeader';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import MainHeader from './components/layout/Header';
import MainFooter from './components/layout/Footer';

const App = () => {
  const [isAdminMode] = useAtom(isAdminModeAtom);

  const { Header } = Layout;

  const location = useLocation();
  const IS_ADMIN = location.pathname.includes('/ilsang-admin');

  // franchisee router 별도 연결
  const IS_FRANCHISEE = location.pathname.includes('/contact/franchisee');

  return (
    <>
      {IS_FRANCHISEE && <MainRoutes/>}
      {!IS_FRANCHISEE &&
      <div
        className="App"
        style={IS_FRANCHISEE? {}:{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center'}}
      >
        {!IS_ADMIN && <MainHeader />}
        {IS_ADMIN && (
          <>
            <Header
              style={{
                width: '100%',
                height: '3rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <span style={{ color: '#fff', fontSize: '1.4rem', fontWeight: 700 }}>관리자 모드</span>
            </Header>
            <AdminHeader />
          </>
        )}
        <div style={{ flex: 1 , width:"100%"}}>
          {!isAdminMode && (
            <>
              <MainTitleCard />
              <MainRoutes />
              <TopButton />
            </>
          )}

          {isAdminMode === 'true' && (
            <>
              <MainTitleCard />
              <MainRoutes />
              <TopButton />
            </>
          )}
        </div>

        <MainFooter />
      </div>}
    </>
  );
};

export default App;
