import styled from 'styled-components';
import ReservationForm from '../../components/common/ReservationForm';
import { Helmet } from 'react-helmet-async';

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>Contact — 눈부신 일상</title>
      </Helmet>

      <Container>
        <OuterWrapper>
          <InnerWrapper>
            <ReservationForm />
          </InnerWrapper>
        </OuterWrapper>
      </Container>
    </>
  );
};

export default ContactPage;

const Container = styled.div`
  height: 100%;
  background-color: #fff;
`;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
