import { Modal } from 'antd';

// 이미지 검증
export const checkValidationImage = (file: File | undefined) => {
  if (!file?.size) {
    Modal.error({ content: '파일을 선택해주세요.' });
    return false;
  }

  return file;
};
