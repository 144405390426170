import styled from 'styled-components';
import MEDIA_QUERY from '../../../constants/mediaQuery';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import client from '../../../api/axios';
import { useState } from 'react';
import CompleteForm from '../CompleteForm';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../../stores';

const ContactForm = () => {
  const [userToken] = useAtom(tokenAtom);

  const schema = yup.object({
    name: yup.string().required('이름을 입력해주세요'),
    digit: yup.string().required('연락처를 입력해주세요'),
    office_name: yup.string().required('회사명을 입력해주세요.'),
    email: yup
      .string()
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, '올바른 이메일 주소를 입력해주세요')
      .required('이메일 주소를 입력해주세요'),
    content: yup.string(),
    privacyPolicy: yup.boolean(),
  });

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const [isSubmitForm, setIsSubmitForm] = useState(false);

  const handleSubmitForm = async (data: any) => {
    if (!data.privacyPolicy) {
      alert('개인 정보 취급 방침에 동의해주세요');
      return;
    }

    const { privacyPolicy, ...contactData } = data;

    try {
      await client.post('api/v1/contacts/contract/', contactData, {
        headers: {
          'Content-Type': 'application/json',
          //Authorization: `Token 71c1e61404bcf64bc69306e0e6eeb60cd47ae766`,
          Authorization: `Token 71c1e61404bcf64bc69306e0e6eeb60cd47ae766`,
        },
        withCredentials: true,
      });

      setIsSubmitForm(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {!isSubmitForm && (
        <>
          <ContactTitle>CONTACT</ContactTitle>
          <FormContainer onSubmit={handleSubmit(handleSubmitForm)}>
            <BasicInfoContainer>
              <BasicInfoWrapper>
                <StrongInfoLabel>이름</StrongInfoLabel>
                <BasicInfoInput
                  placeholder="김지은"
                  autoComplete="off"
                  {...register('name')}
                  style={
                    formState.errors.name?.message ? { backgroundColor: '#FFEAE8', border: '1px solid #CE2C30' } : {}
                  }
                />
                <InputErrorMsg>{formState.errors.name?.message}</InputErrorMsg>
              </BasicInfoWrapper>
              <BasicInfoWrapper>
                <StrongInfoLabel>연락처</StrongInfoLabel>
                <BasicInfoInput
                  placeholder="010-0000-0000"
                  autoComplete="off"
                  {...register('digit')}
                  style={
                    formState.errors.digit?.message ? { backgroundColor: '#FFEAE8', border: '1px solid #CE2C30' } : {}
                  }
                />
                <InputErrorMsg>{formState.errors.digit?.message}</InputErrorMsg>
              </BasicInfoWrapper>
              <BasicInfoWrapper>
                <StrongInfoLabel>회사명</StrongInfoLabel>
                <BasicInfoInput
                  type="text"
                  placeholder="회사명"
                  autoComplete="off"
                  {...register('office_name')}
                  style={
                    formState.errors.office_name?.message
                      ? { backgroundColor: '#FFEAE8', border: '1px solid #CE2C30' }
                      : {}
                  }
                />
                <InputErrorMsg>{formState.errors.office_name?.message}</InputErrorMsg>
              </BasicInfoWrapper>
              <BasicInfoWrapper>
                <StrongInfoLabel>이메일 주소</StrongInfoLabel>
                <BasicInfoInput
                  type="text"
                  placeholder="abc@naver.com"
                  autoComplete="off"
                  {...register('email')}
                  style={
                    formState.errors.email?.message ? { backgroundColor: '#FFEAE8', border: '1px solid #CE2C30' } : {}
                  }
                />
                <InputErrorMsg>{formState.errors.email?.message}</InputErrorMsg>
              </BasicInfoWrapper>
            </BasicInfoContainer>
            <div>
              <InqueryWrapper>
                <InqueryLabel>제휴문의내용</InqueryLabel>
                <InqueryTextarea autoComplete="off" {...register('content')} />
              </InqueryWrapper>
            </div>
            <div>
              <InqueryWrapper>
                <PersonalLabel>개인정보취급방침</PersonalLabel>
                <PersonalTextarea
                  value={`눈부신 일상은 (이하 ‘회사’는) 고객님의 개인정보를 중요시하며, “정보통신망 이용촉진 및 정보보호”에 관한 법률을 준수하고 있습니다. \n \n 회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.`}
                  readOnly
                />
              </InqueryWrapper>
            </div>
            <PersonalWrapper>
              <PersonalCheckbox id="privacyPolicy" type="checkbox" {...register('privacyPolicy')} />
              <PersonalCheckboxLabel htmlFor="privacyPolicy">
                <PersonalCheckboxText>개인정보취급방침에 동의합니다.</PersonalCheckboxText>
              </PersonalCheckboxLabel>
            </PersonalWrapper>
            <FormButtonWrapper>
              <SubmitButton type="submit">Submit</SubmitButton>
            </FormButtonWrapper>
          </FormContainer>
        </>
      )}

      {/* 예약 문의 폼 전송 완료 페이지 */}
      {isSubmitForm && <CompleteForm />}
    </>
  );
};

export default ContactForm;

const ContactTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.7rem;
  font-weight: 400;
  letter-spacing: 6.8px;
  color: #1e1f18;

  ${MEDIA_QUERY.sm} {
    color: #474747;
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 2.6px;

    margin-top: 7.2rem;
  }
`;

const FormContainer = styled.form``;

const BasicInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 2rem;

  ${MEDIA_QUERY.xs} {
    place-items: center;
  }
`;

const BasicInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${MEDIA_QUERY.lg} {
    padding: 0.5rem;
  }
`;

const StrongInfoLabel = styled.label`
  color: #474747;
  font-family: 'NanumSquareNeoRegular';
  font-size: 1rem;

  &::after {
    content: '*';
    color: #ff508b;
  }

  ${MEDIA_QUERY.lg} {
    margin-bottom: 0.3rem;
  }

  ${MEDIA_QUERY.sm} {
    font-family: 'MapoFlowerIsland';
    font-size: 1.2rem;
  }
`;

const BasicInfoInput = styled.input`
  border: 1px solid #c1c1c180;
  border-radius: 0;
  background-color: #fcfcfc;
  font-family: 'NanumSquareNeoRegular';

  /* IOS 기본 스타일 제거 */
  -webkit-appearance: none;
  -webkit-border-radius: 0;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #c1c1c1;
    font-size: 1rem;
  }

  /* For WebKit browsers (Chrome, Safari) */
  &::-webkit-input-placeholder {
    color: #c1c1c1;
    font-family: 'NanumSquareNeoRegular';
    font-size: 1rem;
  }

  /* For Firefox */
  &::-moz-placeholder {
    color: #c1c1c1;
    font-family: 'NanumSquareNeoRegular';
    font-size: 1rem;
  }

  /* For Internet Explorer */
  &:-ms-input-placeholder {
    color: #c1c1c1;
    font-family: 'NanumSquareNeoRegular';
    font-size: 1rem;
  }

  ${MEDIA_QUERY.lg} {
    width: 15rem;
    height: 3rem;
    padding: 5px;
    font-size: 1rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 15rem;
    height: 3.5rem;
    font-size: 1.2rem;

    &::placeholder {
      color: #c1c1c1;
      font-size: 1.2rem;
    }
  }

  ${MEDIA_QUERY.xs} {
    width: 12rem;
    font-size: 1rem;

    &::placeholder {
      color: #c1c1c1;
      font-size: 1rem;
    }
  }
`;

const InqueryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${MEDIA_QUERY.sm} {
    margin-bottom: 1rem;
  }
`;

const InqueryLabel = styled.label`
  color: #474747;
  font-family: 'NanumSquareNeoRegular';
  font-size: 1rem;
  align-self: flex-start;

  ${MEDIA_QUERY.lg} {
    margin-left: 0.5rem;
    margin-bottom: 0.3rem;
  }

  ${MEDIA_QUERY.sm} {
    font-family: 'MapoFlowerIsland';
    font-size: 1.2rem;
  }
`;

const InqueryTextarea = styled.textarea`
  border: 1px solid #c1c1c180;
  background-color: #fcfcfc;
  resize: none;
  font-family: 'NanumSquareNeoRegular';

  /* IOS 기본 스타일 제거 */
  -webkit-appearance: none;
  -webkit-border-radius: 0;

  &:focus {
    outline: none;
  }

  ${MEDIA_QUERY.lg} {
    width: 31rem;
    height: 18.6rem;
    padding: 0.5rem;
    margin-bottom: 1rem;

    font-size: 1rem;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1.2rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 25rem;
  }
`;

const PersonalTextarea = styled.textarea`
  border: 1px solid #c1c1c180;
  background-color: #fcfcfc;
  padding: 1rem;
  resize: none;
  font-family: 'NanumSquareNeoRegular';

  /* IOS 기본 스타일 제거 */
  -webkit-appearance: none;
  -webkit-border-radius: 0;

  &:focus {
    outline: none;
  }

  ${MEDIA_QUERY.lg} {
    width: 31rem;
    height: 8rem;
    margin-bottom: 1rem;

    font-size: 1rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 25rem;
  }
`;

const PersonalLabel = styled.label`
  color: #474747;
  font-family: 'NanumSquareNeoRegular';
  font-size: 1rem;
  align-self: flex-start;

  &::after {
    content: '*';
    color: #ff508b;
  }

  ${MEDIA_QUERY.lg} {
    margin-left: 0.5rem;
    margin-bottom: 0.3rem;
  }

  ${MEDIA_QUERY.sm} {
    font-family: 'MapoFlowerIsland';
    font-size: 1.2rem;
  }
`;

const PersonalWrapper = styled.div`
  display: flex;
  align-items: center;

  ${MEDIA_QUERY.lg} {
    margin-left: 0.5rem;
  }
`;

const PersonalCheckbox = styled.input`
  display: none;

  &:checked + label::after {
    visibility: visible;
    background-color: #000;
  }
`;

const PersonalCheckboxLabel = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 1.2rem;
  cursor: pointer;

  font-family: 'NanumSquareNeoRegular';

  ${MEDIA_QUERY.lg} {
    font-size: 0.9rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      text-align: center;
      background: url('/images/form_check.svg') no-repeat;
      border: 1px solid #c1c1c180;
      border-radius: 2px;
      background-color: #fff;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      background: url('/images/form_check.svg') no-repeat;
      background-position: center center;
      background-size: 0.6rem 0.6rem;
      border-radius: 1px;
      margin: 0.1rem;
      background-color: #fff;
      visibility: hidden;
    }
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1.2rem;
    padding-left: 1.5rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1.5rem;
      height: 1.5rem;
      text-align: center;
      background: url('/images/form_check.svg') no-repeat;
      border: 1px solid #c1c1c180;
      border-radius: 2px;
      background-color: #fff;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1.5rem;
      height: 1.5rem;
      margin: 0.1rem;
      background: url('/images/form_check.svg') no-repeat;
      background-position: center center;
      background-size: 1rem 1rem;
      border-radius: 1px;
      background-color: #fff;
      visibility: hidden;
    }
  }
`;

const PersonalCheckboxText = styled.span`
  display: inline-block;
  font-size: 1rem;

  ${MEDIA_QUERY.lg} {
    margin: 0.1rem 0 0 0.5rem;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1.2rem;
    margin-left: 0.7rem;
  }
`;

const FormButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222222;
  border: 0;
  cursor: pointer;

  color: #fff;

  ${MEDIA_QUERY.lg} {
    width: 7.2rem;
    height: 3rem;

    font-size: 1.2rem;
    margin-top: 2rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 10rem;
    height: 4rem;

    font-size: 1.5rem;
    margin-top: 2.2rem;
  }
`;

// form 에러 메시지
const InputErrorMsg = styled.span`
  color: #ce2c30;
  font-size: 0.9rem;

  ${MEDIA_QUERY.lg} {
    height: 1rem;
    margin-top: 0.3rem;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1rem;
  }

  ${MEDIA_QUERY.xs} {
    font-size: 0.8rem;
  }
`;
