import { useAtomValue } from 'jotai';
import { tokenAtom } from '../../stores';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { useEffect } from 'react';

export const withAuth = (Component: React.ComponentType) =>
  function WithAuthentication<P extends Record<string, unknown>>(props: P) {
    const userToken = localStorage.getItem('Token');

    const navigate = useNavigate();

    useEffect(() => {
      if (!userToken) {
        Modal.error({ content: '관리자 로그인이 필요합니다!' });
        navigate('/ilsang-admin');
        return;
      }
    }, [userToken]);

    return <Component {...props} />;
  };
