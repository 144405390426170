import { MutableRefObject, useEffect } from 'react';

const useOnHoverOutside = (ref: MutableRefObject<HTMLElement | null>, handler: (e: MouseEvent) => void) => {
  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (!ref.current || ref.current.contains(e.target as Node)) return;

      handler(e);
    };
    document.addEventListener('mouseover', listener);

    return () => {
      document.removeEventListener('mouseover', listener);
    };
  }, [ref, handler]);
};

export default useOnHoverOutside;
