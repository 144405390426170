import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { fonts } from './fonts';

const GlobalStyle = createGlobalStyle`
  ${fonts}
  ${normalize}

  * {
    box-sizing: border-box;
  }
  html {
    line-height: 1.15;
    font-size: 62.5%;
    font-family: 'Gowun Batang', serif;
    scroll-behavior: smooth;
  }
  body {
    font-size: 1.4rem;
  }

`;

export default GlobalStyle;
