import styled from 'styled-components';
import { ChangeEvent, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import useMediaQuery from '../../../../hook/useMediaQuery';
import client from '../../../../api/axios';
import MEDIA_QUERY from '../../../../constants/mediaQuery';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../../../stores';
import { Input, message } from 'antd';

const AdminFranchiseePage = () => {
  const { isXs, isSm, isLg } = useMediaQuery();

  const [userToken] = useAtom(tokenAtom);

  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // 가맹점 비밀번호 생성
  const [newPassword, setNewPassword] = useState('');

  // 가맹점 비밀번호 변경
  const [currentPassword, setCurrentPassword] = useState('');
  const [updatePassword, setUpdatePassword] = useState('');
  const [createPasswordId, setCreatePasswrodId] = useState(1);

  // 에러 처리
  const [isError, setIsError] = useState(false);

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handlePasswordValue = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleCurrentPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(e.target.value);
  };

  const handleUpdatePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setUpdatePassword(e.target.value);
  };

  // 비밀번호 체크 (이거 최근 등록한 비밀번호만 가능 => id때문에)
  const handleCheckPassword = async (e: any) => {
    e.preventDefault();

    const data = {
      password,
    };

    console.log('createPasswordId', createPasswordId);

    try {
      await client.post(`api/v1/contacts/affiliate_check/${4}/`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      setIsLoggedIn(true);
    } catch (error) {
      console.error(error); // TODO: response.message에 따라 예외 처리 필요
      setIsError(true);
    }
  };

  // 가맹점 비밀번호 생성 (id = 4만 사용할 거긴 함)
  const handleCreateContactPassword = async () => {
    const newpasswordData = {
      password: newPassword,
    };

    try {
      const response = await client.post('api/v1/contacts/affiliate/', newpasswordData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      message.success({ content: '비밀번호가 생성되었습니다.' });

      setCreatePasswrodId(response.data.id);
    } catch (error) {
      console.error(error);
    }
  };

  // 가맹점 비밀번호 변경  (프로덕션에서는 id = 4만 사용)
  const handleUpdateContactPassword = async () => {
    const updatePasswordData = {
      current_password: currentPassword,
      new_password: updatePassword,
    };

    try {
      await client.post(`api/v1/contacts/affiliate/${4}/`, updatePasswordData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      message.success({ content: '비밀번호 변경이 완료되었습니다.' });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>관리자 | Franchisee — 눈부신 일상</title>
      </Helmet>

      <Container>
        <OuterWrapper>
          <InnerWrapper>
            {!isLoggedIn && (
              <BeforeValidWrapper>
                <BeforeValidTitle>FRANCHISEE</BeforeValidTitle>
                <ValidationForm onSubmit={handleCheckPassword}>
                  <ErrorPasswordWrapper>
                    <PasswordInput
                      type="password"
                      placeholder="비밀번호를 입력하세요"
                      autoComplete="off"
                      onChange={handlePasswordValue}
                      style={isError ? { backgroundColor: '#FFEAE8', border: '1px solid #CE2C30' } : {}}
                    />
                    {isError && <ErrorPasswordText>올바른 비밀번호를 입력해주세요</ErrorPasswordText>}
                  </ErrorPasswordWrapper>
                  <LoginButton type="submit">LOG IN</LoginButton>
                </ValidationForm>

                <PasswordContainer>
                  <PasswordWrapper>
                    <DescriptionSpan>가맹점 비밀번호 생성</DescriptionSpan>
                    <PasswordInput type="text" placeholder="비밀번호 생성" onChange={handlePasswordChange} />
                    <LoginButton
                      type="button"
                      onClick={handleCreateContactPassword}
                      style={{ width: '8rem', marginTop: '1rem' }}
                    >
                      비밀번호 생성
                    </LoginButton>
                  </PasswordWrapper>

                  <PasswordWrapper>
                    <DescriptionSpan>가맹점 비밀번호 변경</DescriptionSpan>
                    <PasswordInput
                      placeholder="현재 비밀번호"
                      onChange={handleCurrentPassword}
                      style={{ marginBottom: '0.5rem' }}
                    />
                    <PasswordInput placeholder="새로운 비밀번호" onChange={handleUpdatePassword} />
                    <LoginButton
                      type="button"
                      onClick={handleUpdateContactPassword}
                      style={{ width: '8rem', marginTop: '1rem' }}
                    >
                      비밀번호 변경
                    </LoginButton>
                  </PasswordWrapper>
                </PasswordContainer>
              </BeforeValidWrapper>
            )}
            {isLoggedIn && (
              <>
                <AfterValidTitle>FRANCHISEE</AfterValidTitle>
                <DescriptionSpan>가맹 정보는 관리자에서 수정되지 않습니다.</DescriptionSpan>
                <FranchiseeInfoWrapper>
                  <FranchiseeInfoItem>
                    <FranchiseeLeftImage
                      src="/images/franchisee/franchisee_1.png"
                      alt="눈부신 일상 창업 경쟁력 이미지"
                    />
                    <FranchiseeDescriptionWrapper style={{ marginLeft: '3rem' }}>
                      <FranchiseeInfoTitle>눈부신 일상 창업경쟁력</FranchiseeInfoTitle>
                      <FranchiseeDescriptions>
                        {/* Mobile 화면에서 띄어쓰기 다름 */}
                        {isLg && !isSm && (
                          <FranchiseeDescription>
                            현존하는 국내 베이비스튜디오브랜드 중 단연 1위의
                            <br /> 파스텔스튜디오그룹 프리미엄 세컨브랜드
                          </FranchiseeDescription>
                        )}
                        {isSm && !isXs && (
                          <FranchiseeDescription>
                            현존하는 국내 베이비스튜디오브랜드 중 단연 1위의 파스텔스튜디오
                            <br />
                            그룹 프리미엄 세컨브랜드
                          </FranchiseeDescription>
                        )}
                        {isXs && (
                          <FranchiseeDescription>
                            현존하는 국내 베이비스튜디오브랜드 중 단연 1위의 <br />
                            &nbsp;&nbsp;&nbsp;파스텔스튜디오 그룹 프리미엄 세컨브랜드
                          </FranchiseeDescription>
                        )}
                        <br />
                        <FranchiseeDescription>안정적인 운영에 필요한 고객DB공급</FranchiseeDescription>
                        <br />
                        <FranchiseeDescription>지역내 거래처(산부인과,산후조리원) 영업 지원 우위</FranchiseeDescription>
                        <br />
                        <FranchiseeDescription>지속적인 사진샘플링과 뉴테마개발로 고객선호도</FranchiseeDescription>
                        <br />
                        {!isXs && (
                          <>
                            <FranchiseeDescription>
                              자체개발 고객관리프로그램(EDRP)을 통한 효율적인 운영관리
                            </FranchiseeDescription>
                            <br />
                            <FranchiseeDescription>
                              본사 자체 편집실, 제조 공장 제품생산및 납품으로 인한 인건비
                              <br /> 및 제품 원가절감
                            </FranchiseeDescription>
                          </>
                        )}
                        {isXs && (
                          <>
                            <FranchiseeDescription>
                              자체개발 고객관리프로그램(EDRP)을 통한 <br /> &nbsp;&nbsp;효율적인 운영관리
                            </FranchiseeDescription>
                            <br />
                            <FranchiseeDescription>
                              본사 자체 편집실, 제조 공장 제품생산및 납품으로 인한 <br />
                              &nbsp;&nbsp;인건비 및 제품 원가절감
                            </FranchiseeDescription>
                          </>
                        )}
                        <br />
                        <FranchiseeDescription>독보적인 촬영스타일을 통한 매니아층 확장</FranchiseeDescription>
                        <br />
                        {isLg && !isSm && (
                          <FranchiseeDescription>
                            {'나이스페이먼츠 창업자금 대출 최대 1억원 지원'}
                            <br />
                            {'연금리 10%(월 0.833%)'}
                            <br />
                            {'1억원 대출시 월 이자 833,000원'}&lt;{'원금+이자 1년~2년 분할상환조건'}&gt;
                            <br />
                            {'※ 연매출과 신용도에 따라 상이함'}
                          </FranchiseeDescription>
                        )}
                        {isSm && !isXs && (
                          <FranchiseeDescription>
                            {'나이스페이먼츠 창업자금 대출 최대 1억원 지원'}
                            <br />
                            {'연금리 10%(월 0.833%)'}
                            <br />
                            {'1억원 대출시 월 이자 833,000원'}
                            <br />
                            &lt;{'원금+이자 1년~2년 분할상환조건'}&gt;
                            <br />
                            {'※ 연매출과 신용도에 따라 상이함'}
                          </FranchiseeDescription>
                        )}
                        {isXs && (
                          <FranchiseeDescription>
                            {'나이스페이먼츠 창업자금 대출 최대 1억원 지원'}
                            <br />
                            &nbsp;&nbsp;{'연금리 10%(월 0.833%)'}
                            <br />
                            &nbsp;&nbsp;{'1억원 대출시 월 이자 833,000원'}
                            <br />
                            &nbsp;&nbsp;&lt;{'원금+이자 1년~2년 분할상환조건'}&gt;
                            <br />
                            &nbsp;&nbsp;{'※ 연매출과 신용도에 따라 상이함'}
                          </FranchiseeDescription>
                        )}
                        <br />
                        <FranchiseeDescription>본사 TM실 구축되어 최적화된 TM콜센터 운영</FranchiseeDescription>
                      </FranchiseeDescriptions>
                    </FranchiseeDescriptionWrapper>
                  </FranchiseeInfoItem>
                </FranchiseeInfoWrapper>

                <FranchiseeInfoWrapper>
                  <FranchiseeInfoItem>
                    <DescriptionOuterWrapper>
                      <FranchiseeDescriptionWrapper>
                        <FranchiseeInfoTitle>가맹조건</FranchiseeInfoTitle>
                        {isLg && !isSm && (
                          <FranchiseeDescriptions style={{ height: '18.9rem' }}>
                            <FranchiseeDescription>
                              가맹비 3천만원 (3년계약, 상호협의하에 연장시 추가비용없이
                              <br /> 연장가능)
                            </FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>보증금 2천만원 (가맹종료시 반납)</FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>
                              월회비 100만원 (브랜드광고, 컨셉개발,본사운영비,시스템사용비)
                            </FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>교육비 500만원(서울,경기외 지역 +100만원)</FranchiseeDescription>
                            <br />
                            1일 촬영 및 연출, 포토샵 보정 교육 / 1일 상담 및 운영교육 <br />
                            *10호점까지 교육비면제
                          </FranchiseeDescriptions>
                        )}
                        {isSm && !isXs && (
                          <FranchiseeDescriptions>
                            <FranchiseeDescription>
                              가맹비 3천만원 (3년계약, 상호협의하에 연장시 추가비용없이
                              <br /> 연장가능)
                            </FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>보증금 2천만원 (가맹종료시 반납)</FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>
                              월회비 100만원 (브랜드광고, 컨셉개발,본사운영비,시스템사용비)
                            </FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>교육비 500만원(서울,경기외 지역 +100만원)</FranchiseeDescription>
                            <br />
                            &nbsp;&nbsp;1일 촬영 및 연출, 포토샵 보정 교육 / 1일 상담 및 운영교육 <br />
                            &nbsp;&nbsp;*10호점까지 교육비면제
                          </FranchiseeDescriptions>
                        )}
                        {isXs && (
                          <FranchiseeDescriptions>
                            <FranchiseeDescription>
                              가맹비 3천만원 (3년계약, 상호협의하에 연장시 <br /> &nbsp;&nbsp;추가비용없이 연장가능)
                            </FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>보증금 2천만원 (가맹종료시 반납)</FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>
                              월회비 100만원 (브랜드광고, 컨셉개발, <br />
                              &nbsp;&nbsp;본사운영비, 시스템사용비)
                            </FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>교육비 500만원(서울,경기외 지역 +100만원)</FranchiseeDescription>
                            <br />
                            &nbsp;&nbsp;1일 촬영 및 연출, 포토샵 보정 교육 /<br />
                            &nbsp;&nbsp;1일 상담 및 운영교육 <br />
                            &nbsp;&nbsp;*10호점까지 교육비면제
                          </FranchiseeDescriptions>
                        )}
                      </FranchiseeDescriptionWrapper>
                      <FranchiseeDescriptionWrapper>
                        <FranchiseeInfoTitle>본사제공</FranchiseeInfoTitle>
                        <FranchiseeDescriptions>
                          {!isXs && (
                            <FranchiseeDescription>
                              상담실 1개소의 셋팅되는 상품패키지에 있는 액자 및 앨범 상품
                              <br /> 2세트 무상제공 (추가제작시엔 본사납품가로 추가가능)
                            </FranchiseeDescription>
                          )}
                          {isXs && (
                            <FranchiseeDescription>
                              상담실 1개소의 셋팅되는 상품패키지에 있는 액자 및 <br /> &nbsp;&nbsp;앨범 상품 2세트
                              무상제공 (추가제작시엔 본사납품가로
                              <br /> &nbsp;&nbsp;추가가능)
                            </FranchiseeDescription>
                          )}
                        </FranchiseeDescriptions>
                      </FranchiseeDescriptionWrapper>
                    </DescriptionOuterWrapper>
                    <FranchiseeRightImage src="/images/franchisee/franchisee_2.png" alt="가맹 조건 이미지" />
                  </FranchiseeInfoItem>
                </FranchiseeInfoWrapper>

                <FranchiseeInfoWrapper>
                  <FranchiseeInfoItem>
                    <FranchiseeLeftImage src="/images/franchisee/franchisee_3.png" alt="필수 항목 이미지" />
                    {isLg && !isSm && (
                      <DescriptionOuterWrapper style={{ marginLeft: '2.9rem' }}>
                        <FranchiseeDescriptionWrapper>
                          <FranchiseeInfoTitle>필수항목</FranchiseeInfoTitle>
                          <FranchiseeDescriptions>
                            <FranchiseeDescription>
                              고객 서비스 공간, 상담실, 사인물 및 간판, 시그니처컨셉 인테리어 공사
                              <br /> 실비로 진행(본사 인테리어팀 외주업체) <br />
                              * 공통적인 느낌을 내는 공사이므로 지점의 현시설 상태를 최대한 활용하
                              <br /> 여 최소비용으로 할수있는 방안 및 디자인 본사제시
                            </FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>
                              대표테마에 사용되는 소품 ,의상,옷걸이 원가제공
                            </FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>EDRP사용 (고객관리 양방향 시스템)</FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>
                              EDRP에 탑재된 결제시스템 사용및 사진편집, 앨범 액자 제작
                              <br /> EDRP를 통해 본사 자체공정 및 공장납품
                            </FranchiseeDescription>
                          </FranchiseeDescriptions>
                        </FranchiseeDescriptionWrapper>
                        <FranchiseeDescriptionWrapper>
                          <FranchiseeInfoTitle>선택항목</FranchiseeInfoTitle>
                          <FranchiseeDescriptions>
                            <FranchiseeDescription>
                              카메라 및 조명장비 본사추천제품 사용 <br />
                              (캐논 R5 미러리스및 전용렌즈, 스트로보 상하일체형시스템)
                            </FranchiseeDescription>
                          </FranchiseeDescriptions>
                        </FranchiseeDescriptionWrapper>
                      </DescriptionOuterWrapper>
                    )}
                    {isSm && !isXs && (
                      <DescriptionOuterWrapper>
                        <FranchiseeDescriptionWrapper>
                          <FranchiseeInfoTitle>필수항목</FranchiseeInfoTitle>
                          <FranchiseeDescriptions>
                            <FranchiseeDescription>
                              고객 서비스 공간, 상담실, 사인물 및 간판, 시그니처컨셉 인테리어 <br />
                              &nbsp;&nbsp;공사 실비로 진행(본사 인테리어팀 외주업체) <br /> *공통적인 느낌을 내는
                              공사이므로 지점의 현시설 상태를 최대한 활용
                              <br />
                              &nbsp; 하여 최소비용으로 할수있는 방안 및 디자인 본사제시
                            </FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>
                              대표테마에 사용되는 소품 ,의상,옷걸이 원가제공
                            </FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>EDRP사용 (고객관리 양방향 시스템)</FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>
                              EDRP에 탑재된 결제시스템 사용및 사진편집, 앨범 액자 제작
                              <br /> &nbsp;&nbsp;EDRP를 통해 본사 자체공정 및 공장납품
                            </FranchiseeDescription>
                          </FranchiseeDescriptions>
                        </FranchiseeDescriptionWrapper>
                        <FranchiseeDescriptionWrapper>
                          <FranchiseeInfoTitle>선택항목</FranchiseeInfoTitle>
                          <FranchiseeDescriptions>
                            <FranchiseeDescription>
                              카메라 및 조명장비 본사추천제품 사용 <br />
                              &nbsp;(캐논 R5 미러리스및 전용렌즈, 스트로보 상하일체형시스템)
                            </FranchiseeDescription>
                          </FranchiseeDescriptions>
                        </FranchiseeDescriptionWrapper>
                      </DescriptionOuterWrapper>
                    )}
                    {isXs && (
                      <DescriptionOuterWrapper>
                        <FranchiseeDescriptionWrapper>
                          <FranchiseeInfoTitle>필수항목</FranchiseeInfoTitle>
                          <FranchiseeDescriptions>
                            <FranchiseeDescription>
                              고객 서비스 공간, 상담실, 사인물 및 간판, 시그니처컨셉 <br />
                              인테리어 공사 실비로 진행 (본사 인테리어팀 외주업체) <br /> *공통적인 느낌을 내는
                              공사이므로 지점의 현시설 상태를 <br /> 최대한 활용하여 최소비용으로 할수있는 방안 및
                              디자인
                              <br /> 본사제시
                            </FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>
                              대표테마에 사용되는 소품 ,의상,옷걸이 원가제공
                            </FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>EDRP사용 (고객관리 양방향 시스템)</FranchiseeDescription>
                            <br /> <br />
                            <FranchiseeDescription>
                              EDRP에 탑재된 결제시스템 사용및 사진편집, 앨범 액자 <br />
                              &nbsp;&nbsp;제작 EDRP를 통해 본사 자체공정 및 공장납품
                            </FranchiseeDescription>
                          </FranchiseeDescriptions>
                        </FranchiseeDescriptionWrapper>
                        <FranchiseeDescriptionWrapper>
                          <FranchiseeInfoTitle>선택항목</FranchiseeInfoTitle>
                          <FranchiseeDescriptions>
                            <FranchiseeDescription>
                              카메라 및 조명장비 본사추천제품 사용 <br />
                              &nbsp;(캐논 R5 미러리스및 전용렌즈, 스트로보 상하일체형
                              <br />
                              &nbsp;시스템)
                            </FranchiseeDescription>
                          </FranchiseeDescriptions>
                        </FranchiseeDescriptionWrapper>
                      </DescriptionOuterWrapper>
                    )}
                  </FranchiseeInfoItem>
                </FranchiseeInfoWrapper>

                <FranchiseeInfoWrapper style={{ borderBottom: 'none' }}>
                  <FranchiseeInfoItem>
                    <DescriptionOuterWrapper>
                      <FranchiseeDescriptionWrapper>
                        <FranchiseeInfoTitle>TM콜센터 (콜량에 따른 비용 가격표제시)</FranchiseeInfoTitle>
                        <FranchiseeDescriptions>
                          <FranchiseeDescription>
                            신규DB TM산정기준(만삭/50일 기준)
                            <br /> 월 고객등록 통계수치상 계산시
                            <br /> <br />
                            <br /> 100~150개 신규디비 +/- 10% = 100만원
                            <br /> 150~250개 신규디비 +/- 10% = 150만원
                            <br /> 250~350개 신규디비 +/- 10% = 200만원
                            <br /> 350~450개 신규디비 +/- 10% = 250만원
                            <br /> 450~550개 신규디비 +/- 10% = 300만원
                            <br /> 550개 이상 신규디비 +/- 10% = 350만원
                          </FranchiseeDescription>
                        </FranchiseeDescriptions>
                      </FranchiseeDescriptionWrapper>
                      <FranchiseeDescriptionWrapper>
                        <FranchiseeInfoTitle>본사제공</FranchiseeInfoTitle>
                        <FranchiseeDescriptions>
                          {!isXs && (
                            <FranchiseeDescription>
                              상담실 1개소의 셋팅되는 상품패키지에 있는 액자 및 앨범 상품
                              <br /> 2세트 무상제공 (추가제작시엔 본사납품가로 추가가능)
                            </FranchiseeDescription>
                          )}
                          {isXs && (
                            <FranchiseeDescription>
                              상담실 1개소의 셋팅되는 상품패키지에 있는 액자 및 <br /> &nbsp;&nbsp;앨범 상품 2세트
                              무상제공 (추가제작시엔 본사납품가로 <br /> &nbsp;&nbsp;추가가능)
                            </FranchiseeDescription>
                          )}
                        </FranchiseeDescriptions>
                      </FranchiseeDescriptionWrapper>
                    </DescriptionOuterWrapper>
                    <FranchiseeRightImage src="/images/franchisee/franchisee_4.png" alt="TMI 콜센터 이미지" />
                  </FranchiseeInfoItem>
                </FranchiseeInfoWrapper>
              </>
            )}
          </InnerWrapper>
        </OuterWrapper>
      </Container>
    </>
  );
};

export default AdminFranchiseePage;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-sizing: border-box;

  ${MEDIA_QUERY.lg} {
    margin-top: 7rem;
  }

  ${MEDIA_QUERY.sm} {
    margin-top: 0;
  }
`;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BeforeValidWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BeforeValidTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.7rem;
  font-weight: 400;
  letter-spacing: 6.8px;
  color: #474747;

  ${MEDIA_QUERY.lg} {
    margin-bottom: 1.5rem;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 2.6px;
    margin-top: 19.7rem;
  }
`;

const AfterValidTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.7rem;
  font-weight: 400;
  letter-spacing: 6.8px;
  color: #1e1f18;
  text-align: center;

  ${MEDIA_QUERY.lg} {
    margin-top: -10.5rem;
    margin-bottom: 4.5rem;
  }

  ${MEDIA_QUERY.sm} {
    margin: 7.8rem 0 4.9rem;
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 2.6px;
    line-height: 16px;
  }
`;

const ValidationForm = styled.form`
  display: flex;

  ${MEDIA_QUERY.sm} {
    flex-direction: column;
    align-items: center;
  }
`;

const PasswordInput = styled.input`
  margin-right: 1rem;
  border: 1px solid #c1c1c180;
  padding: 4px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #c1c1c1;
    font-family: 'NanumSquareNeoRegular';
    font-weight: 100;
    font-size: 1rem;
    padding: 0.9rem 0.2rem 1rem;
  }

  /* For WebKit browsers (Chrome, Safari) */
  &::-webkit-input-placeholder {
    color: #c1c1c1;
    font-family: 'NanumSquareNeoRegular';
    font-size: 1rem;
  }

  /* For Firefox */
  &::-moz-placeholder {
    color: #c1c1c1;
    font-family: 'NanumSquareNeoRegular';
    font-size: 1rem;
  }

  /* For Internet Explorer */
  &:-ms-input-placeholder {
    color: #c1c1c1;
    font-family: 'NanumSquareNeoRegular';
    font-size: 1rem;
  }

  ${MEDIA_QUERY.lg} {
    width: 15rem;
    height: 3rem;
  }

  ${MEDIA_QUERY.sm} {
    font-family: 'NanumSquareNeoLight';
    height: 3.5rem;
    padding: 1.15rem 0.8rem;
  }
`;

const LoginButton = styled.button`
  font-size: 1rem;
  color: #fff;
  border: 0;
  background-color: #000;
  cursor: pointer;

  ${MEDIA_QUERY.lg} {
    width: 7.2rem;
    height: 3rem;
  }

  ${MEDIA_QUERY.sm} {
    margin: 0.2rem 0 12.5rem;
  }
`;

const FranchiseeInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 3.7rem;
  border-bottom: 1px solid #b9b9b9;

  ${MEDIA_QUERY.lg} {
    margin-top: 4.5rem;
  }

  ${MEDIA_QUERY.sm} {
    margin-top: 0;
  }
`;

const FranchiseeInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${MEDIA_QUERY.sm} {
    flex-direction: column;
  }
`;

const DescriptionOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: content-box;

  ${MEDIA_QUERY.lg} {
    height: 40rem;
    margin-bottom: 8rem;
  }

  ${MEDIA_QUERY.sm} {
    margin-bottom: 0;
  }
`;

// 이미지 위치에 따라 margin 값이 바뀌어야해서 네이밍을 left, right에 나눠서 함
const FranchiseeLeftImage = styled.img`
  width: 32rem;
  height: 48rem;
  background-color: #d5d5d5;

  ${MEDIA_QUERY.sm} {
    margin-top: 5.5rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 25rem;
    margin-top: 2.5rem;
  }
`;

const FranchiseeRightImage = styled.img`
  width: 32rem;
  height: 48rem;
  background-color: #d5d5d5;

  ${MEDIA_QUERY.sm} {
    order: -1;
    margin-top: 5rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 25rem;
  }
`;

const FranchiseeDescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;

  ${MEDIA_QUERY.lg} {
    width: 35rem;
    margin-right: -3rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 30rem;
    margin-right: 0;
    margin-left: 1rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 25rem;
    margin-left: 0;
  }
`;

const FranchiseeInfoTitle = styled.span`
  color: #424530;
  font-family: 'NanumSquareNeoBold';
  font-size: 1.2rem;

  ${MEDIA_QUERY.lg} {
    margin: 5.5rem 0 3.7rem;
  }

  ${MEDIA_QUERY.sm} {
    margin: 3.2rem 0 3.7rem;
  }
`;

const FranchiseeDescriptions = styled.ol`
  display: block;
  color: #1e1f18;
  font-family: 'NanumSquareNeoRegular';
  font-size: 1rem;
  margin: 0;

  ${MEDIA_QUERY.lg} {
    padding-left: 1rem;
  }

  ${MEDIA_QUERY.xs} {
    margin-left: 0;
  }
`;

const FranchiseeDescription = styled.li`
  color: #1e1f18;

  ${MEDIA_QUERY.lg} {
    font-size: 1rem;
    line-height: 17px;
  }

  ${MEDIA_QUERY.sm} {
    text-indent: 0.5rem;
  }

  ${MEDIA_QUERY.xs} {
    line-height: 15px;
  }
`;

// 비밀번호 에러 메시지
const ErrorPasswordWrapper = styled.div`
  height: 5rem;
  display: flex;
  flex-direction: column;
`;

const ErrorPasswordText = styled.span`
  color: #ce2c30;
  font-size: 1rem;
  margin-top: 0.5rem;
`;

const DescriptionSpan = styled.span`
  color: #000;
  font-family: ArgentumSansRegular;
  font-size: 1.3rem;
  margin-bottom: 1rem;
`;

const PasswordContainer = styled.div`
  display: flex;
  margin-top: 3rem;
`;

const PasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
`;

const AdminPasswordInput = styled(Input)`
  margin-right: 1rem;
  border: 1px solid #c1c1c180;
  padding: 4px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #c1c1c1;
    font-family: 'NanumSquareNeoRegular';
    font-weight: 100;
    font-size: 1rem;
    padding: 0.9rem 0.2rem 1rem;
  }

  /* For WebKit browsers (Chrome, Safari) */
  &::-webkit-input-placeholder {
    color: #c1c1c1;
    font-family: 'NanumSquareNeoRegular';
    font-size: 1rem;
  }

  /* For Firefox */
  &::-moz-placeholder {
    color: #c1c1c1;
    font-family: 'NanumSquareNeoRegular';
    font-size: 1rem;
  }

  /* For Internet Explorer */
  &:-ms-input-placeholder {
    color: #c1c1c1;
    font-family: 'NanumSquareNeoRegular';
    font-size: 1rem;
  }

  ${MEDIA_QUERY.lg} {
    width: 15rem;
    height: 3rem;
  }

  ${MEDIA_QUERY.sm} {
    font-family: 'NanumSquareNeoLight';
    height: 3.5rem;
    padding: 1.15rem 0.8rem;
  }
`;
