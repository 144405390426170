import { Button, Form, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import client from '../../../api/axios';
import { useAtom } from 'jotai';
import { tokenAtom, userAtom } from '../../../stores';

type LoginType = {
  username: string;
  password: string;
};

const AdminLoginForm = () => {
  const [, setToken] = useAtom(tokenAtom);
  const [, setUserInfo] = useAtom(userAtom);

  const navigate = useNavigate();

  const handleLoginSubmit = async (data: LoginType) => {
    try {
      const response = await client.post('api/v1/accounts/login/', data, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      if (response.status === 200) {
        setToken(response.data?.token);
        localStorage.setItem('useradmin', `${response.data?.token}`);
        const { msg, token, ...restUserInfo } = response.data;
        setUserInfo(restUserInfo);

        navigate('/ilsang-admin/home');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={handleLoginSubmit}
            autoComplete="off"
          >
            <h2 style={{ display: 'flex', justifyContent: 'center', marginBottom: '5rem' }}>관리자 페이지</h2>

            <Form.Item<LoginType>
              label="아이디"
              name="username"
              rules={[{ required: true, message: '아이디를 입력해주세요' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item<LoginType>
              label="비밀번호"
              name="password"
              rules={[{ required: true, message: '비밀번호를 입력해주세요' }]}
            >
              <Input.Password />
            </Form.Item>

            <div>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  로그인
                </Button>

                <Link to="/ilsang-admin/signup" style={{ marginLeft: '2rem' }}>
                  회원가입
                </Link>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AdminLoginForm;
