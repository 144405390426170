import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { Button, Input, message } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MEDIA_QUERY from '../../constants/mediaQuery';
import useMediaQuery from '../../hook/useMediaQuery';
import client from '../../api/axios';

export type PriceTitleDataType = {
  id?: number;
  title?: string;
  content?: string;
  event?: string;
  event_content?: string;
  is_cancel?: string;
  created_at?: string;
};

export type ProductTitleDataType = {
  id?: number;
  title?: string;
  content?: string;
  category?: string;
  is_cancel?: string;
  created_at?: string;
};

export type DetailProductDataType = {
  id?: number;
  title?: string;
  content: string;
  price?: string;
  times?: string;
  thema: string;
  album: string;
  photoframe?: string;
  filmingversion?: string;
  etc?: string;
  img_url?: string;
  is_cancel?: string;
  created_at?: string;
  priceproduct_id?: number;
};

const PricePage = () => {
  const { isXs, isSm, isLg } = useMediaQuery();

  // price title 전체 데이터
  const [priceTitleData, setPriceTitleData] = useState<PriceTitleDataType[]>([]);

  // price product 전체 데이터
  const [simpleTitleData, setSimpleTitleData] = useState<ProductTitleDataType[]>([]);

  // price detail product 전체 데이터
  const [priceDetailProductData, setPriceDetailProductData] = useState<DetailProductDataType[]>([]);

  // Price product 전체 데이터 조회
  const handleQueryProductList = async () => {
    try {
      const response = await client.get('api/v1/prices/priceproduct/', {
        headers: {
          'Content-Type': 'application/json',
          //Authorization: `Token 71c1e61404bcf64bc69306e0e6eeb60cd47ae766`,
          Authorization: `Token 71c1e61404bcf64bc69306e0e6eeb60cd47ae766`,
        },
        withCredentials: true,
      });

      setSimpleTitleData(response.data.results);
    } catch (error) {
      console.error(error);
    }
  };
  // Price product 전체 데이터 조회
  const handleQueryDetailProductList = async () => {
    try {
      const response = await client.get('api/v1/prices/pricedetailproduct/', {
        headers: {
          'Content-Type': 'application/json',
          //Authorization: `Token 71c1e61404bcf64bc69306e0e6eeb60cd47ae766`,
          Authorization: `Token 71c1e61404bcf64bc69306e0e6eeb60cd47ae766`,
        },
        withCredentials: true,
      });
      setPriceDetailProductData(response.data.results);
    } catch (error) {
      console.error(error);
    }
  };

  // Price title 전체 데이터 조회
  const handleQueryPriceTitle = async () => {
    try {
      const response = await client.get('/api/v1/prices/pricetitle/', {
        headers: {
          'Content-Type': 'application/json',
          //Authorization: `Token 71c1e61404bcf64bc69306e0e6eeb60cd47ae766`,
          Authorization: `Token 71c1e61404bcf64bc69306e0e6eeb60cd47ae766`,
        },
        withCredentials: true,
      });

      setPriceTitleData(response.data.results);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const img = new Image();
    img.src = 'images/price/price_1.png';
  }, []);

  useEffect(() => {
    handleQueryProductList();
    handleQueryPriceTitle();
    handleQueryDetailProductList();
  }, []);

  return (
    <>
      <Helmet>
        <title>관리자 | Price — 눈부신 일상</title>
      </Helmet>

      <Container>
        <OuterWrapper>
          <PriceTitleWrapper>
            <PriceTitle>{priceTitleData[0]?.title}</PriceTitle>
            <PriceParagraph dangerouslySetInnerHTML={{ __html: priceTitleData[0]?.content || '' }} />
          </PriceTitleWrapper>

          {/* 심플 성장 */}
          <SimpleSectionWrapper>
            <>
              {isSm && (
                <MobileSignatureWrapper>
                  <MobileSinatureTitle>{simpleTitleData[0]?.title}</MobileSinatureTitle>
                  {/* <MobileSignatureDescription>{simpleTitleData[0]?.content}</MobileSignatureDescription> */}
                </MobileSignatureWrapper>
              )}
              <SimpleSectionOnlyOne>
                  <SimpleTitle>심플 성장</SimpleTitle>
                {/* <SimpleTitleWrapper>
                  <SimpleTitle>심플 성장</SimpleTitle>
                </SimpleTitleWrapper> */}
                {/* <SimpleParagraph>
                  심플 성장 패키지 상품은 돌사진 촬영 시 엄마, 아빠, 아기가 함께 촬영합니다. <br />
                  가족사진과 위드 컷은 원본과 함께 기본 수정본(피부, 몸매보정)이 제공됩니다.
                </SimpleParagraph> */}
              </SimpleSectionOnlyOne>
              <SimpleAlbumPriceWrapper>
                <AlbumMarginPriceImage src={priceDetailProductData[0]?.img_url} alt="소소한 일상 앨범 이미지" />
                <AlbumDescritionWrapper>
                  <AlbumDescriptionTitleWrapper>
                    <AlbumDescritionSubTitle>{priceDetailProductData[0]?.title}</AlbumDescritionSubTitle>
                    <AlbumDescritionTitle>{priceDetailProductData[0]?.content}</AlbumDescritionTitle>
                  </AlbumDescriptionTitleWrapper>
                  {isLg && !isSm && (
                    <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[0]?.album || '' }} />
                  )}
                  {isSm && (
                    <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[0]?.album || '' }} />
                  )}
                </AlbumDescritionWrapper>
              </SimpleAlbumPriceWrapper>
              {isLg && !isSm && (
                <>
                  <DivideLine style={{ margin: 0 }} />
                  <SimpleAlbumPriceWrapper>
                    <AlbumLeftDescriptionWrapper style={{ marginBottom: '3rem', marginLeft: '1.5rem'}}>
                      <AlbumDescriptionTitleWrapper>
                        <AlbumDescritionSubTitle>{priceDetailProductData[1]?.title}</AlbumDescritionSubTitle>
                        <AlbumDescritionTitle>{priceDetailProductData[1]?.content}</AlbumDescritionTitle>
                      </AlbumDescriptionTitleWrapper>
                      <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[1]?.album || '' }} />
                    </AlbumLeftDescriptionWrapper>
                    <AlbumPriceImage src={priceDetailProductData[1]?.img_url} alt="달콤한 일상 앨범 이미지" />
                  </SimpleAlbumPriceWrapper>
                </>
              )}
              {/* {isSm && (
                <>
                  <DivideLine />
                  <SimpleAlbumPriceWrapper>
                    <AlbumMarginPriceImage src="/images/price/price_2.png" alt="달콤한 일상 앨범 이미지" />
                    <AlbumDescritionWrapper>
                      <AlbumDescriptionTitleWrapper>
                        <AlbumDescritionSubTitle>심플성장</AlbumDescritionSubTitle>
                        <AlbumDescritionTitle>달콤한 일상</AlbumDescritionTitle>
                      </AlbumDescriptionTitleWrapper>
                      <AlbumDescrition style={{ }}>
                        <AlbumDescriptionList>₩1,390,000(30분/60분)</AlbumDescriptionList>
                        <AlbumDescriptionList>
                          백일 2테마 + <br /> 돌 3테마(가족 1테마 촬영 가능)
                        </AlbumDescriptionList>
                        <br /> <br />
                        <AlbumDescriptionList>20x25cm 에코블럭 액자 2점</AlbumDescriptionList>
                        <AlbumDescriptionList>액자2점(20x25cm 데코블럭)</AlbumDescriptionList>
                        <br />
                        <AlbumDescriptionList>촬영 원본</AlbumDescriptionList>
                      </AlbumDescrition>
                    </AlbumDescritionWrapper>
                  </SimpleAlbumPriceWrapper>
                </>
              )} */}
               {isSm && (
                  <SpecialAlubmPriceWrapper
                    style={{
                      padding: '0 0 3.5rem',
                    }}
                  >
                    {isSm && !isXs && (
                      <AlbumMarginPriceImage src={priceDetailProductData[1]?.img_url} alt="우리의 일상 앨범 이미지" />
                    )}
                    {isXs && (
                      <AlbumFordablePriceImage src={priceDetailProductData[1]?.img_url} alt="우리의 일상 앨범 이미지" />
                    )}
                    <AlbumDescritionWrapper>
                      <AlbumDescriptionTitleWrapper>
                        <AlbumDescritionSubTitle>{priceDetailProductData[1]?.title}</AlbumDescritionSubTitle>
                        <AlbumDescritionTitle>{priceDetailProductData[1]?.content}</AlbumDescritionTitle>
                      </AlbumDescriptionTitleWrapper>

                      <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[1]?.album || '' }} />
                    </AlbumDescritionWrapper>
                  </SpecialAlubmPriceWrapper>
                )}
              <DivideLine style={{ margin: 0 }} />
            </>
          </SimpleSectionWrapper>

          {/* 시그니처 성장 */}
          <SignatureContainer>
            <div>
              {isSm && (
                <MobileSignatureWrapper>
                  <MobileSinatureTitle>{simpleTitleData[1]?.title}</MobileSinatureTitle>
                  <MobileSignatureDescription>{simpleTitleData[1]?.content}</MobileSignatureDescription>
                </MobileSignatureWrapper>
              )}
              <SimpleSection style={{ margin: '11rem 0 0' }}>
                <SimpleTitleWrapper>
                  <SimpleTitle>{simpleTitleData[1]?.title}</SimpleTitle>
                </SimpleTitleWrapper>
                {/* <SimpleParagraph style={{ textAlign: 'left' }}>{simpleTitleData[1]?.content}</SimpleParagraph> */}
              </SimpleSection>
              <AlbumPriceWrapper
                style={{ marginTop: 0, padding: '4.1rem 0 3.5rem', borderBottom: '1px solid #b9b9b9' }}
              >
                {!isXs && (
                  <AlbumMarginPriceImage src={priceDetailProductData[2]?.img_url} alt="눈부신 일상 앨범 이미지" />
                )}
                {isXs && (
                  <AlbumFordablePriceImage src={priceDetailProductData[2]?.img_url} alt="눈부신 일상 앨범 이미지" />
                )}
                <SignatureAlbumDescriptionWrapper>
                  <AlbumDescriptionTitleWrapper>
                    <AlbumDescritionSubTitle>{priceDetailProductData[2]?.title}</AlbumDescritionSubTitle>
                    <AlbumDescritionTitle>{priceDetailProductData[2]?.content}</AlbumDescritionTitle>
                  </AlbumDescriptionTitleWrapper>
                  <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[2]?.album || '' }} />
                </SignatureAlbumDescriptionWrapper>
              </AlbumPriceWrapper>
              {isLg && !isSm && (
                <AlbumPriceWrapper
                  style={{
                    backgroundColor: '#FAFAFA',
                    marginTop: 0,
                    padding: '4rem 0 3.5rem',
                    borderBottom: '1px solid #b9b9b9',
                    boxSizing: 'content-box',
                  }}
                >
                  <AlbumLeftDescriptionWrapper style={{ marginLeft: '1.5rem' }}>
                    <AlbumDescriptionTitleWrapper>
                      <AlbumDescritionSubTitle>{priceDetailProductData[3]?.title}</AlbumDescritionSubTitle>
                      <AlbumDescritionTitle>{priceDetailProductData[3]?.content}</AlbumDescritionTitle>
                    </AlbumDescriptionTitleWrapper>
                    <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[3]?.album || '' }} />
                  </AlbumLeftDescriptionWrapper>
                  <AlbumPriceImage src={priceDetailProductData[3]?.img_url} alt="빛나는 일상 앨범 이미지" />
                </AlbumPriceWrapper>
              )}
              {isSm && (
                <AlbumPriceWrapper
                  style={{
                    marginTop: 0,
                    padding: '4rem 0 3.5rem',
                    borderBottom: '1px solid #b9b9b9',
                    boxSizing: 'content-box',
                  }}
                >
                  {isSm && !isXs && (
                    <AlbumMarginPriceImage src={priceDetailProductData[3]?.img_url} alt="빛나는 일상 앨범 이미지" />
                  )}
                  {isXs && (
                    <AlbumFordablePriceImage src={priceDetailProductData[3]?.img_url} alt="빛나는 일상 앨범 이미지" />
                  )}
                  <SignatureAlbumDescriptionWrapper>
                    <AlbumDescriptionTitleWrapper>
                      <AlbumDescritionSubTitle>{priceDetailProductData[3]?.title}</AlbumDescritionSubTitle>
                      <AlbumDescritionTitle>{priceDetailProductData[3]?.content}</AlbumDescritionTitle>
                    </AlbumDescriptionTitleWrapper>
                    <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[3]?.album || '' }} />
                  </SignatureAlbumDescriptionWrapper>
                </AlbumPriceWrapper>
              )}
              {isLg && !isSm && (
                <ChoiceSection style={{ backgroundColor: '#fafafa' }}>
                  <ChoiceDescription>
                    <PriceTitleBold>* {priceTitleData[2]?.title}</PriceTitleBold> - {priceTitleData[2]?.content}
                  </ChoiceDescription>
                </ChoiceSection>
              )}
              {isSm && !isXs && (
                <ChoiceSection>
                  <ChoiceDescription>
                    <PriceTitleBold>* {priceTitleData[2]?.title}</PriceTitleBold>
                    <br /> {priceTitleData[2]?.content}
                  </ChoiceDescription>
                </ChoiceSection>
              )}
              {isXs && (
                <ChoiceSection>
                  <ChoiceDescription>
                    <PriceTitleBold>* {priceTitleData[2]?.title}</PriceTitleBold>
                    <br /> {priceTitleData[2]?.content}
                  </ChoiceDescription>
                </ChoiceSection>
              )}
            </div>
          </SignatureContainer>

          {/* 스페셜 성장 */}
          <SpecitalContainerBox>
            <div>
              <SpecialContainer>
                {isLg && !isSm && (
                  <SimpleSection style={{ marginBottom: 0 }}>
                    <SimpleTitleWrapper>
                      {/* <SimpleTitle>{simpleTitleData[2]?.title}</SimpleTitle> */}
                      <SimpleTitle>프리미엄 성장</SimpleTitle>
                    </SimpleTitleWrapper>
                    {/* <SimpleParagraph>{simpleTitleData[2]?.content}</SimpleParagraph> */}
                  </SimpleSection>
                )}
                {isSm && (
                  <MobileSpecialWrapper>
                    <MobileSpecialTitle>{simpleTitleData[2]?.title}</MobileSpecialTitle>
                    <MobileSpecialDescription>{simpleTitleData[2]?.content}</MobileSpecialDescription>
                  </MobileSpecialWrapper>
                )}
                {isLg && !isSm && (
                  <SpecialAlubmPriceWrapper>
                    <AlbumMarginPriceImage src={priceDetailProductData[4]?.img_url} alt="우리의 일상 앨범 이미지" />
                    <AlbumLeftDescriptionWrapper>
                      <AlbumDescriptionTitleWrapper>
                        <AlbumDescritionSubTitle>{priceDetailProductData[4]?.title}</AlbumDescritionSubTitle>
                        <AlbumDescritionTitle>{priceDetailProductData[4]?.content}</AlbumDescritionTitle>
                      </AlbumDescriptionTitleWrapper>

                      <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[4]?.album || '' }} />
                    </AlbumLeftDescriptionWrapper>
                  </SpecialAlubmPriceWrapper>
                )}
                {isSm && (
                  <SpecialAlubmPriceWrapper
                    style={{
                      padding: '0 0 3.5rem',
                    }}
                  >
                    {isSm && !isXs && (
                      <AlbumMarginPriceImage src={priceDetailProductData[4]?.img_url} alt="우리의 일상 앨범 이미지" />
                    )}
                    {isXs && (
                      <AlbumFordablePriceImage src={priceDetailProductData[4]?.img_url} alt="우리의 일상 앨범 이미지" />
                    )}
                    <AlbumDescritionWrapper>
                      <AlbumDescriptionTitleWrapper>
                        <AlbumDescritionSubTitle>{priceDetailProductData[4]?.title}</AlbumDescritionSubTitle>
                        <AlbumDescritionTitle>{priceDetailProductData[4]?.content}</AlbumDescritionTitle>
                      </AlbumDescriptionTitleWrapper>

                      <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[4]?.album || '' }} />
                    </AlbumDescritionWrapper>
                  </SpecialAlubmPriceWrapper>
                )}
                {isLg && !isSm && (
                  <>
                    <DivideLine />
                    <SpecialAlubmPriceWrapper style={{ borderBottom: '1px solid #b9b9b9', padding: '4rem 0 3.5rem' }}>
                      <AlbumLeftDescriptionWrapper>
                        <AlbumDescriptionTitleWrapper>
                          <AlbumDescritionSubTitle>{priceDetailProductData[5]?.title}</AlbumDescritionSubTitle>
                          <AlbumDescritionTitle>{priceDetailProductData[5]?.content}</AlbumDescritionTitle>
                        </AlbumDescriptionTitleWrapper>

                        <StyledDescription
                          dangerouslySetInnerHTML={{ __html: priceDetailProductData[5]?.album || '' }}
                        />
                      </AlbumLeftDescriptionWrapper>
                      <AlbumPriceImage src={priceDetailProductData[5]?.img_url} alt="눈부신 일상 앨범 이미지" />
                    </SpecialAlubmPriceWrapper>
                  </>
                )}
                {isSm && (
                  <SpecialAlubmPriceWrapper
                    style={{
                      width: '32rem',
                      borderBottom: '1px solid #b9b9b9',
                      margin: '0 auto',
                      marginTop: '5rem',
                    }}
                  >
                    {isSm && !isXs && (
                      <AlbumMarginPriceImage src={priceDetailProductData[5]?.img_url} alt="눈부신 일상 앨범 이미지" />
                    )}
                    {isXs && (
                      <AlbumFordablePriceImage src={priceDetailProductData[5]?.img_url} alt="눈부신 일상 앨범 이미지" />
                    )}
                    <AlbumDescritionWrapper>
                      <AlbumDescriptionTitleWrapper>
                        <AlbumDescritionSubTitle>{priceDetailProductData[5]?.title}</AlbumDescritionSubTitle>
                        <AlbumDescritionTitle>{priceDetailProductData[5]?.content}</AlbumDescritionTitle>
                      </AlbumDescriptionTitleWrapper>

                      <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[5]?.album || '' }} />
                    </AlbumDescritionWrapper>
                  </SpecialAlubmPriceWrapper>
                )}
              </SpecialContainer>
              {isLg && !isSm && (
                <ChoiceSection style={{ padding: '1.6rem 2rem' }}>
                  <ChoiceDescription style={{ fontSize: '1.1rem' }}>
                    <PriceTitleBold>* {priceTitleData[3]?.title}</PriceTitleBold> - {priceTitleData[3]?.content}
                  </ChoiceDescription>
                  <br />
                  <ChoiceDescription style={{ fontSize: '1.1rem' }}>
                    <PriceTitleBold>** {priceTitleData[3]?.event}</PriceTitleBold> -{priceTitleData[3]?.event_content}
                    <br />
                  </ChoiceDescription>
                  {/* 눈부신일상 초이스 임시 하드코딩 */}
                  <div style={{color:'#C77F4D', fontSize:'10px',display:'flex',flexDirection:'column',marginLeft:'15px',fontWeight:'bold'}}>
                    <span>-눈부신초이스 모든 상품은 10x13인치 퓨어 슬림 액자 1점이 포함됩니다</span>
                    <span>-리마인드 웨딩 촬영 시 부모님 헤어,메이크업,드레스,턱시도 대여는 추가 서비스로 제공됩니다</span>
                  </div>
                  {/* 여기까지 */}
                </ChoiceSection>
              )}
              {isSm && !isXs && (
                <ChoiceSection>
                  <ChoiceDescription style={{ fontSize: '1.2rem' }}>
                    <PriceTitleBold>* {priceTitleData[3]?.title}</PriceTitleBold> - <br />
                    {priceTitleData[3]?.content}
                  </ChoiceDescription>
                  <br />
                  <ChoiceDescription style={{ fontSize: '1.2rem' }}>
                    <PriceTitleBold>** {priceTitleData[3]?.event}</PriceTitleBold> - <br />
                    {priceTitleData[3]?.event_content}
                  </ChoiceDescription>
                </ChoiceSection>
              )}
              {isXs && (
                <ChoiceSection>
                  <ChoiceDescription style={{ fontSize: '1.2rem' }}>
                    <PriceTitleBold>* {priceTitleData[3]?.title}</PriceTitleBold> - <br />
                    {priceTitleData[3]?.content}
                  </ChoiceDescription>
                  <br />
                  <ChoiceDescription style={{ fontSize: '1.2rem' }}>
                    <PriceTitleBold>** {priceTitleData[3]?.event}</PriceTitleBold> - <br />
                    {priceTitleData[3]?.event_content}
                  </ChoiceDescription>
                </ChoiceSection>
              )}
            </div>
          </SpecitalContainerBox>

          {/* 베이직 단품 */}
          <BasicAlbumContainer>
            <div>
            {isLg && !isSm && (
  <BasicAlbumPriceWrapper>
    <BasicImage src={priceDetailProductData[6]?.img_url} alt="베이직 단품 앨범 이미지" />
    <BasicDescriptionWrapper>
      <MobileBasicAlbumWrapper style={{ margin: 0 }}>
        
        <AlbumDescritionWrapper>
          <AlbumDescriptionTitleWrapper>
            <AlbumDescritionSubTitle>{priceDetailProductData[6]?.title}</AlbumDescritionSubTitle>
            <AlbumDescritionTitle>{priceDetailProductData[6]?.content}</AlbumDescritionTitle>
          </AlbumDescriptionTitleWrapper>
          <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[6]?.album || '' }} />
        </AlbumDescritionWrapper>

        <AlbumDescritionWrapper>
          <AlbumDescriptionTitleWrapper>
            <AlbumDescritionSubTitle>{priceDetailProductData[7]?.title}</AlbumDescritionSubTitle>
            <AlbumDescritionTitle>{priceDetailProductData[7]?.content}</AlbumDescritionTitle>
          </AlbumDescriptionTitleWrapper>
          <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[7]?.album || '' }} />
        </AlbumDescritionWrapper>

        <AlbumDescritionWrapper>
          <AlbumDescriptionTitleWrapper>
            <AlbumDescritionSubTitle>{priceDetailProductData[8]?.title}</AlbumDescritionSubTitle>
            <AlbumDescritionTitle>{priceDetailProductData[8]?.content}</AlbumDescritionTitle>
          </AlbumDescriptionTitleWrapper>
          <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[8]?.album || '' }} />
        </AlbumDescritionWrapper>
        
      </MobileBasicAlbumWrapper>
    </BasicDescriptionWrapper>
  </BasicAlbumPriceWrapper>
)}

              {isSm && !isXs && (
                <BasicAlbumPriceWrapper style={{ border: 'none' }}>
                  <BasicImage src={priceDetailProductData[6]?.img_url} alt="베이직 단품 앨범 이미지" />
                  <BasicDescriptionWrapper>
                    <MobileBasicAlbumWrapper>

                      <AlbumDescriptionTitleWrapper>
                        <AlbumDescritionSubTitle>{priceDetailProductData[6]?.title}</AlbumDescritionSubTitle>
                        <AlbumDescritionTitle>{priceDetailProductData[6]?.content}</AlbumDescritionTitle>
                      </AlbumDescriptionTitleWrapper>

                      <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[6]?.album || '' }} />
                    
                    <AlbumDescritionWrapper style={{ padding: 0 }}>
                      <AlbumDescriptionTitleWrapper>
                        <AlbumDescritionSubTitle>{priceDetailProductData[7]?.title}</AlbumDescritionSubTitle>
                        <AlbumDescritionTitle>{priceDetailProductData[7]?.content}</AlbumDescritionTitle>
                      </AlbumDescriptionTitleWrapper>
                      <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[7]?.album || '' }} />
                    </AlbumDescritionWrapper>

                    <AlbumDescritionWrapper style={{ padding: 0 }}>
                      <AlbumDescriptionTitleWrapper>
                        <AlbumDescritionSubTitle>{priceDetailProductData[8]?.title}</AlbumDescritionSubTitle>
                        <AlbumDescritionTitle>{priceDetailProductData[8]?.content}</AlbumDescritionTitle>
                      </AlbumDescriptionTitleWrapper>
                      <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[8]?.album || '' }} />
                    </AlbumDescritionWrapper>

                    </MobileBasicAlbumWrapper>
                  </BasicDescriptionWrapper>
                </BasicAlbumPriceWrapper>
              )}
              {isXs && (
                <BasicAlbumPriceWrapper style={{ border: 'none' }}>
                  <BasicImage src={priceDetailProductData[6]?.img_url} alt="베이직 단품 앨범 이미지" />
                  <BasicDescriptionWrapper>
                    <MobileBasicAlbumWrapper>
                      <AlbumDescriptionTitleWrapper>
                        <AlbumDescritionSubTitle>{priceDetailProductData[6]?.title}</AlbumDescritionSubTitle>
                        <AlbumDescritionTitle>{priceDetailProductData[6]?.content}</AlbumDescritionTitle>
                      </AlbumDescriptionTitleWrapper>
                      <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[6]?.album || '' }} />
                    
                    <AlbumDescritionWrapper style={{ padding: 0 }}>
                      <AlbumDescriptionTitleWrapper>
                        <AlbumDescritionSubTitle>{priceDetailProductData[6]?.title}</AlbumDescritionSubTitle>
                        <AlbumDescritionTitle>{priceDetailProductData[6]?.content}</AlbumDescritionTitle>
                      </AlbumDescriptionTitleWrapper>
                      <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[6]?.album || '' }} />
                    </AlbumDescritionWrapper>
                    </MobileBasicAlbumWrapper>
                  </BasicDescriptionWrapper>
                </BasicAlbumPriceWrapper>
              )}
            </div>
          </BasicAlbumContainer>

          {isLg && !isSm && (
            <>
              <EventWrapper>
                <EventTitle>{priceTitleData[1]?.event}</EventTitle>
                <EventDescription dangerouslySetInnerHTML={{ __html: priceTitleData[1]?.event_content || '' }} />
              </EventWrapper>
            </>
          )}

          {isSm && !isXs && (
            <EventWrapper>
              <EventTitle>평 일 촬 영 이 벤 트</EventTitle>
              <EventDescription>
                <AlbumDescriptionList>• 만삭 평일 촬영 시 : 아버님 스타일링</AlbumDescriptionList>
                <AlbumDescriptionList>• 50일 평일 촬영 시 : 파노라마 테마 추가 촬영 + 파노라마 테마 원본 제공</AlbumDescriptionList>
                <AlbumDescriptionList>• 100일+돌+가족 평일 촬영 시 : 2단 폴리오 앨범</AlbumDescriptionList>
                <AlbumDescriptionList>
                • 모든 촬영 평일 진행 시 : 
                  파노라마 1구 자석 액자 추가 증정(42x11cm + 6x6cm 자석액자)
                </AlbumDescriptionList>
              </EventDescription>
            </EventWrapper>
          )}
          {isXs && (
              <EventWrapper>
              <EventTitle>평 일 촬 영 이 벤 트</EventTitle>
              <EventDescription>
                <AlbumDescriptionList>• 만삭 평일 촬영 시 : 아버님 스타일링</AlbumDescriptionList>
                <AlbumDescriptionList>• 50일 평일 촬영 시 : 파노라마 테마 추가 촬영 + 파노라마 테마 원본 제공</AlbumDescriptionList>
                <AlbumDescriptionList>• 100일+돌+가족 평일 촬영 시 : 2단 폴리오 앨범</AlbumDescriptionList>
                <AlbumDescriptionList>
                • 모든 촬영 평일 진행 시 : 
                  파노라마 1구 자석 액자 추가 증정(42x11cm + 6x6cm 자석액자)
                </AlbumDescriptionList>
              </EventDescription>
            </EventWrapper>
          )}
        </OuterWrapper>
      </Container>
    </>
  );
};

export default PricePage;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;

  ${MEDIA_QUERY.sm} {
    margin: 10px;
  }

  ${MEDIA_QUERY.xs} {
    padding: 0 1.5rem;
  }
`;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.sm} {
    width: 100%;
  }
`;

const PriceTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  ${MEDIA_QUERY.lg} {
    width: 67.5rem;
    margin-bottom: 6rem;
    margin-top: -5rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 32rem;
    margin-bottom: 4rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 23rem;
    margin-bottom: 4rem;
  }
`;

const PriceTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.7rem;
  font-weight: 500;

  letter-spacing: 6.8px;
  color: #474747;

  ${MEDIA_QUERY.sm} {
    margin: 12.8rem 0 5.2rem;
    font-weight: 500;
    font-size: 1.2rem;
    letter-spacing: 2.6px;
  }

  ${MEDIA_QUERY.xs} {
    margin-bottom: 4rem;
  }
`;

const PriceParagraph = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: #1e1f18;
  // line-height: 2.5rem;
  margin: 3.5rem 0 0;
  font-family: MapoFlowerIsland;

  ${MEDIA_QUERY.sm} {
    line-height: 1.8rem;
    margin: 0;
  }

  ${MEDIA_QUERY.xs} {
    font-size: 1.1rem;
  }
`;

const SimpleSection = styled.div`
  display: flex;
  // justify-content: space-evenly;
  align-items: center;
  background-color: #f7f6f4;
  padding:20px 30px;
  white-space: pre-wrap;

  ${MEDIA_QUERY.sm} {
   display: none;
  }
`;

const SimpleSectionOnlyOne = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: rgb(247, 246, 244);
  padding: 20px 30px;
  white-space: pre-wrap;

  ${MEDIA_QUERY.sm} {
   display: none;
  }
`;

const SimpleTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 12rem;
  // border-right: 1px solid #707070;
`;

const SimpleTitle = styled.span`
  font-family: 'NanumSquareNeoBold';
  font-size: 1.2rem;
  line-height: 13px;
  letter-spacing: 2.2px;
  color: #424530;
`;

const SimpleParagraph = styled.p`
  font-family: 'NanumSquareNeoLight';
  font-size: 1.1rem;
  font-weight: 200;
  color: transparent;
  text-align: left;
  max-width: 320px;
  ${MEDIA_QUERY.lg} {
    line-height: 13px;
  }

`;

const AlbumPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: pre-wrap;

  ${MEDIA_QUERY.lg} {
    width: 67rem;
    background-color: #fafafa;
  }

  ${MEDIA_QUERY.sm} {
    flex-direction: column;
    align-items: flex-start;
    width: 32rem;
    height: 100%;
    margin: 0 auto;
    border-bottom: 1px solid #b9b9b9;
  }
`;

const SpecialAlubmPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: pre-wrap;

  ${MEDIA_QUERY.lg} {
    width: 67rem;
    background-color: #fff;
    padding: 4rem 0 3.5rem;
  }

  ${MEDIA_QUERY.sm} {
    flex-direction: column;
    align-items: flex-start;
    width: 32rem;
    height: 100%;
    margin: 0 auto;
    border-bottom: 1px solid #b9b9b9;
  }
`;

const AlbumMarginPriceImage = styled.img`
  ${MEDIA_QUERY.lg} {
    width: 36rem;
    height: 27.5rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 32rem;
    height: 21.5rem;
    margin: 0 auto;
    margin-bottom: 5.5rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 90%;
    margin: 0 auto;
  }
`;

const AlbumFordablePriceImage = styled.img`
  ${MEDIA_QUERY.xs} {
    width: 25rem;
    margin: 0 1rem 3.5rem;
  }
`;

const AlbumPriceImage = styled.img`
  ${MEDIA_QUERY.lg} {
    width: 36rem;
    height: 27.5rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 32rem;
    height: 21.5rem;
    margin: 0 auto;
  }
`;

const SimpleAlbumPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  white-space: pre-wrap;

  ${MEDIA_QUERY.lg} {
    width: 67rem;
    padding: 3.5rem 0;
  }

  ${MEDIA_QUERY.sm} {
    flex-direction: column;
    align-items: flex-start;
    width: 32rem;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    border-bottom: 1px solid #b9b9b9;
    background-color: #fff;
    padding-bottom:3.5rem;
  }
`;

const AlbumDescritionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  width: 280px;

   h3 {
    margin:0;
  }


  ${MEDIA_QUERY.lg} {
           margin: 1rem 3.5rem 1rem 2rem;
    
  }

  ${MEDIA_QUERY.sm} {
    padding: 0 2.6rem;
    margin-right: 0;
  }

  ${MEDIA_QUERY.xs} {
    padding: 0 3.6rem;
  }
`;

const AlbumLeftDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align:left;
  white-space: pre-wrap;

  ${MEDIA_QUERY.lg} {
    margin-top: 1rem;
    width:280px;
  }

  ${MEDIA_QUERY.sm} {
    padding: 0 2.6rem;
  }
`;

const SignatureAlbumDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  white-space: pre-wrap;

  ${MEDIA_QUERY.lg} {
    margin: 1rem 3.5rem 1rem 2rem;
    width:280px;
  }

  ${MEDIA_QUERY.xs} {
    margin: 1rem 5rem 1rem 4rem;
  }
`;

const AlbumDescriptionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  ${MEDIA_QUERY.lg} {
   // margin: 0 0 1.2rem 0rem;
  }

  ${MEDIA_QUERY.sm} {
    margin-bottom: 0.1rem;
  }
`;

const AlbumDescritionSubTitle = styled.span`
  font-family: 'NanumSquareLight';
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 2.2px;
  color: #424530;

  ${MEDIA_QUERY.lg} {
  //시안 변경으로 임시로 부제 가려놓음
    display:none;
    margin-bottom: 0.4rem;
  }
`;

const AlbumDescritionTitle = styled.span`
  font-family: 'NanumSquareNeoExtraBold';
  color: #424530;
  font-size: 1.6rem;
`;

const AlbumDescrition = styled.ul`
  color: #1e1f18;
  font-family: 'S-CoreDream-3Light';
  font-weight: 200;
  font-size: 1.2rem;
  list-style-position: inside;
  padding-left: 0px !important;

  strong{
    font-family: NanumSquareNeoExtraBold;
  }

  ${MEDIA_QUERY.lg} {
    line-height: 18px;
    letter-spacing: -0.2px;
    margin: 0;
    margin-bottom:20px;

    h3{
      display:flex;
      justify-content: space-between;
    }
`;

const AlbumDescriptionList = styled.li`
  position: relative;
  list-style: none;
  padding-left: 0px;
  margin: 1rem 0;
`;

const DivideLine = styled.div`
  ${MEDIA_QUERY.lg} {
    width: 67rem;
    border-top: 1px solid #b9b9b9;
    margin-top: 1.8rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 32rem;
    border: 0;
    margin: 0 auto;
    margin-bottom: 5rem;
  }
`;

const ChoiceSection = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #b9b9b9;

  ${MEDIA_QUERY.lg} {
    width: 67rem;
    padding: 1.4rem 2rem;
    margin-bottom: 11rem;
    line-height: 18px;
  }

  ${MEDIA_QUERY.sm} {
    width: 90%;
    max-width:320px;
    padding: 2.9rem 0rem;
    margin:0 auto;
  }
`;

const ChoiceDescription_one = styled.div`
  color: #424530;
  font-family: 'S-CoreDream-3Light';
  font-size: 1.2rem;
  font-weight: 200;
  word-break: keep-all;

  ${MEDIA_QUERY.sm} {
    font-size: 1.2rem;
    line-height: 10px;
    text-align: center;
  }
`;
const ChoiceDescription = styled.div`
  color: #424530;
  font-family: 'S-CoreDream-3Light';
  font-size: 1.2rem;
  font-weight: 200;
  word-break: keep-all;

  ${MEDIA_QUERY.sm} {
    font-size: 1.2rem;
    line-height: 18px;
    text-align: center;
  }
`;
const BasicAlbumPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${MEDIA_QUERY.lg} {
    width: 67rem;
  }

  ${MEDIA_QUERY.sm} {
    flex-direction: column;
    align-items: flex-start;
    width: 32rem;
    height: 100%;
    margin: 0 auto;
    border-bottom: 1px solid #b9b9b9;
  }
`;

const BasicImage = styled.img`
  ${MEDIA_QUERY.lg} {
    width: 25.5rem;
    height: 35rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 32rem;
    height: 48rem;
    margin: 11rem 0 5.5rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 26rem;
    margin: 9rem 0 5.5rem;
  }
`;

const BasicDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${MEDIA_QUERY.lg} {
    margin-right: 2.2rem;
  }

  ${MEDIA_QUERY.sm} {
    margin: 0 0 0 2rem;
  }

  ${MEDIA_QUERY.xs} {
    margin: 0 0 0 3rem;
  }
`;

interface EventWrapperProps {
  isEdit?: boolean;
  isEditMode?: boolean;
}

const EventWrapper = styled.div<EventWrapperProps>`
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;

  ${(props) =>
    props.isEdit &&
    !props.isEditMode &&
    css`
      opacity: 0.3;
    `}

  ${MEDIA_QUERY.lg} {
    width: 67rem;
    margin-top: 12rem;
    padding: 3.9rem 5.6rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 100vw;
    height: 23.1rem;
    padding: 3.4rem 5rem;
    margin-top: 6.9rem;
  }

  ${MEDIA_QUERY.xs} {
    height: 25rem;
  }
`;

const EventTitle = styled.span`
  font-family: 'NanumSquareNeoExtraBold';
  font-size: 1.4rem;
  letter-spacing: -0.8px;

  ${MEDIA_QUERY.lg} {
    line-height: 20px;
  }
`;

const EventDescription = styled.div`
  font-family: 'S-CoreDream-3Light';
  font-size: 1.2rem;
  letter-spacing: -0.2px;
  color: #1e1f18;
  white-space: pre-wrap;
  

  ${MEDIA_QUERY.lg} {
    line-height: 18px;
  }
`;

// 모바일용 섹션 설명
const MobileSignatureWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 4rem 0;
  background-color: #fafafa;
  padding:20px 0;
  ${MEDIA_QUERY.lg} {
    background-color: #fff;
  }
`;

const MobileSinatureTitle = styled.span`
  color: #424530;
  font-family: 'NanumSquareNeoBold';
  font-size: 1.2rem;
  letter-spacing: 2.6px;
  line-height: 1.4rem;
  text-align: center;
  margin-bottom: 2rem;
`;

const MobileSignatureDescription = styled.p`
  color: #424530;
  font-family: 'NanumSquareNeoLight';
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 2rem;
  text-align: center;
  margin: 0;
  ${MEDIA_QUERY.lg} {
    width:90%;
    max-width:320px;
    margin:0 auto;
  }
`;

const SignatureContainer = styled.div`
  position: relative;

  ${MEDIA_QUERY.sm} {
    width: 100vw;
    background-color: #fafafa;
    margin-top: 5rem;
  }
`;

const SpecialContainer = styled.div`
  ${MEDIA_QUERY.sm} {
    width: 100vw;
  }
`;

const MobileSpecialWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 4rem 0;

  ${MEDIA_QUERY.sm} {
    padding-top: 8rem;
  }
`;

const MobileSpecialTitle = styled.span`
  color: #424530;
  font-family: 'NanumSquareNeoBold';
  font-size: 1.2rem;
  letter-spacing: 2.6px;
  line-height: 1.4rem;
  text-align: center;
  margin-bottom: 2rem;
`;

const MobileSpecialDescription = styled.p`
  color: #424530;
  font-family: 'NanumSquareNeoLight';
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 2rem;
  text-align: center;
  margin: 0;
  ${MEDIA_QUERY.lg} {
    width:90%;
    max-width:320px;
    margin:0 auto;
  }
`;

const MobileBasicAlbumWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${MEDIA_QUERY.lg} {
    padding: 0;
  }
`;

const SimpleSectionWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const SpecitalContainerBox = styled.div`
  position: relative;
`;

const BasicAlbumContainer = styled.div`
  position: relative;
`;

const StyledDescription = styled(AlbumDescrition)`

  p {
    margin: 0;
  }
`;

const PriceTitleBold = styled.b`
  font-weight: 700;
`;
