import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import useMediaQuery from '../../../../hook/useMediaQuery';
import MEDIA_QUERY from '../../../../constants/mediaQuery';
import client from '../../../../api/axios';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../../../stores';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CloudUploadOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { checkValidationImage } from '../../../../utils';

type PostIlsangArticleType = {
  content?: string;
  img_url?: string;
};

type UpdateIlsangArticleType = {
  id: number;
  content?: string;
  img_url?: string;
};

type ValidationImageType = {
  image?: string;
  tag?: string;
};

type IlsangDataType = {
  content?: string;
  created_at: string;
  id?: string;
  img_url?: string;
  is_cancel?: string;
};

const AdminIlsangPage = () => {
  const { isXs, isSm, isLg } = useMediaQuery();

  const [userToken] = useAtom(tokenAtom);

  const navigate = useNavigate();

  const [isHoverArticle, setIsHoverArticle] = useState(false);
  const [isHoverImage, setIsHoverImage] = useState(false);

  const [isActiveEditArticle, setIsActiveEditArticle] = useState(false);
  const [isActiveEditImage, setIsActiveEditImage] = useState(false);

  // 일상 전체 데이터
  const [ilsangData, setIlsangData] = useState<IlsangDataType[] | undefined>([]);

  // 일상 아티클 데이터 (마지막 작성된 글 기준)
  const [ilsangArticle, setIlsangArticle] = useState<any>();

  // 텍스트 에디터
  const [editorValue, setEditorValue] = useState('');
  const [key, setKey] = useState(Math.random());

  const modules = {
    toolbar: [[{ header: [1, 2, 3, false] }], ['bold', 'italic', 'underline', 'strike']],
  };
  const formats = ['header', 'bold', 'italic', 'underline', 'strike'];

  // 일상 글 post
  const [postData, setPostData] = useState<PostIlsangArticleType>({});

  const imgRef = useRef<HTMLInputElement>(null);
  const [fileUrl, setFileUrl] = useState('');
  const [image, setImage] = useState<any>();

  // 일상 이미지 검증
  const [validationPhoto, setValidationPhoto] = useState<ValidationImageType>({
    tag: 'test',
  });

  // 일상 디테일 수정 (마지막 포스팅 글 기준으로 수정)
  const [articleId, setArticleId] = useState(1);
  const [updatePostData, setupdatePostData] = useState<UpdateIlsangArticleType>({
    id: articleId,
  });

  const [isVisible, setIsVisible] = useState(false);

  const handleVisibleArticle = () => {
    setIsVisible((prev) => !prev);
  };

  const handleArticleChange = (e: any) => {
    // setArticle({
    //   ...article,
    //   content: event.target.value,
    // });
  };

  // 일상 글, 사진 업로드
  const handlePostArticle = async () => {
    try {
      const response = await client.post('api/v1/articles/ilsang/', postData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      // 마지막 포스팅된 글을 기준으로 수정하기 위해 id 저장
      setArticleId(response.data?.results.length);

      message.success('일상 페이지의 글이 성공적으로 업로드 되었습니다.');
    } catch (error) {
      console.error(error);
    }
  };

  // 일상 페이지 데이터 조회
  const handleQueryArticle = async () => {
    try {
      const response = await client.get('api/v1/articles/ilsang/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      // 마지막 포스팅된 글을 기준으로 수정하기 위해 id 저장
      setArticleId(response.data?.results.length);
      setIlsangData(response.data.results);
      setIlsangArticle(response.data?.results?.[1]?.content);
    } catch (error) {
      console.error(error);
    }
  };

  // 일상 글 수정
  const handleUpdateArticle = async () => {
    setupdatePostData({
      ...updatePostData,
      content: editorValue,
    });

    try {
      const response = await client.post(`api/v1/articles/ilsang/${1}/`, updatePostData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      // 마지막 포스팅된 글을 기준으로 수정하기 위해 id 저장
      setArticleId(response.data?.results.length);
      setEditorValue(ilsangData?.[1]?.content || '');
      setIsActiveEditImage(false);
      setIsActiveEditArticle(false);
      setKey(Math.random());

      message.success({ content: '일상 페이지의 글이 수정되었습니다.' });
    } catch (error) {
      console.error(error);
    }
  };

  // 일상 사진 수정
  const handleUpdateImage = async () => {
    try {
      const response = await client.post(`api/v1/articles/ilsang/${1}/`, updatePostData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      // 마지막 포스팅된 글을 기준으로 id 저장
      setArticleId(response.data?.results.length);
    } catch (error) {
      console.error(error);
    }
  };

  const onClickUpload = () => {
    imgRef.current?.click();
  };

  // 이미지 미리보기
  const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const isValid = checkValidationImage(e.target.files?.[0]);
    if (!isValid) return;

    try {
      if (imgRef.current?.files) {
        const file = imgRef.current.files[0];
        setImage(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            setFileUrl(reader.result);
          }
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 일상 이미지 업로드
  const handleUploadImage = async () => {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('tag', 'ilsang'); // tag는 페이지에 따라 다르게 입력

    try {
      // 이미지 검증 및 스토리지 저장
      const response = await client.post('api/v1/images/image/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      const newFileUrl = response.data.img_url;
      setFileUrl(newFileUrl);

      setupdatePostData({
        ...updatePostData,
        img_url: newFileUrl,
      });

      // 이미지 업로드
      const postResponse = await client.post(`api/v1/articles/ilsang/${1}/`, updatePostData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      setArticleId(postResponse.data?.results.length); // 마지막 포스팅된 글을 기준으로 수정하기 위해 id 저장
      setIsActiveEditImage(false);
      setIsActiveEditArticle(false);

      message.success({ content: '일상 페이지의 이미지가 수정되었습니다.' });

      navigate('/ilsang-admin/about/ilsang');
    } catch (error) {
      console.error(error);
    }
  };

  // 수정 취소 버튼
  const handleCancel = () => {
    setEditorValue(ilsangData?.[1]?.content || '');
    setIsActiveEditImage(false);
    setIsActiveEditArticle(false);
    setKey(Math.random());
  };

  // ilsang 전체 데이터 조회
  useEffect(() => {
    handleQueryArticle();
  }, []);

  useEffect(() => {}, [editorValue, fileUrl]);

  return (
    <>
      <Helmet>
        <title>관리자 | About — 일상</title>
      </Helmet>

      <Container>
        <DescriptionInnerWrapper>
          <IlsangSectionWrapper>
            <DescriptionWrapper
              isHover={isHoverArticle}
              isActiveEditArticle={isActiveEditArticle}
              onMouseEnter={() => setIsHoverArticle(true)}
              onMouseLeave={() => setIsHoverArticle(false)}
            >
              {/* 폴더블 화면 */}
              {!isActiveEditArticle && !isActiveEditImage && isXs && (
                <StyledDescription dangerouslySetInnerHTML={{ __html: ilsangData?.[0]?.content || '' }} />
              )}

              {/* 모바일 화면 */}
              {!isActiveEditArticle && !isActiveEditImage && isSm && !isXs && (
                <StyledDescription dangerouslySetInnerHTML={{ __html: ilsangData?.[0]?.content || '' }} />
              )}

              {/* pc 화면 */}
              {!isActiveEditArticle && !isActiveEditImage && !isSm && isLg && (
                <>
                  {!isActiveEditArticle && !isVisible && (
                    <StyledDescription dangerouslySetInnerHTML={{ __html: ilsangData?.[0]?.content || '' }} />
                  )}
                  {!isActiveEditArticle && !isActiveEditImage && isHoverArticle && (
                    <ArticleEditButton onClick={() => setIsActiveEditArticle((prev) => !prev)}>수정</ArticleEditButton>
                  )}
                </>
              )}
              {/* 일상 글 수정 모드 */}
              {isActiveEditArticle && !isActiveEditImage && (
                <EditContainer>
                  <IlsangTitle>일상 글 수정</IlsangTitle>
                  <DescriptionSpan>About - ilsang 페이지의 글을 수정합니다.</DescriptionSpan>
                  <Description>
                    <TextEditor
                      className="form-control text-editor"
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      value={editorValue || ilsangData?.[0]?.content}
                      onChange={(content, delta, source, editor) => setEditorValue(editor.getHTML())}
                      style={{ width: '100%' }}
                      key={key}
                    />
                  </Description>
                  <EditButtonWrapper>
                    <CancelButton onClick={handleCancel}>취소</CancelButton>
                    <SaveButton onClick={handleUpdateArticle}>저장</SaveButton>
                  </EditButtonWrapper>
                </EditContainer>
              )}
              {!isActiveEditArticle && !isActiveEditImage && <MainLogo src="/images/logo.svg" alt="눈부신 일상 로고" />}
            </DescriptionWrapper>
            <ImageWrapper
              isHover={isHoverImage}
              isActiveEditImage={isActiveEditImage}
              onMouseEnter={() => setIsHoverImage(true)}
              onMouseLeave={() => setIsHoverImage(false)}
            >
              {!isActiveEditArticle && !isActiveEditImage && (
                <PortfolioImage src={fileUrl || ilsangData?.[1]?.img_url || ''} alt="아이가 소파 위를 뛰어 노는 사진" />
              )}
              {isHoverImage && !isActiveEditArticle && !isActiveEditImage && (
                <ImageEditButton onClick={() => setIsActiveEditImage((prev) => !prev)}>수정</ImageEditButton>
              )}
              {/* 일상 이미지 수정 모드 */}
              {isActiveEditImage && !isActiveEditArticle && (
                <EditContainer>
                  <IlsangTitle>일상 사진 수정</IlsangTitle>
                  <DescriptionSpan>About - ilsang 페이지의 사진을 수정합니다.</DescriptionSpan>
                  <UploadImage
                    onClick={onClickUpload}
                    src={fileUrl || ilsangData?.[1]?.img_url}
                    alt="일상 페이지 이미지"
                  />
                  {!fileUrl && !ilsangData?.[1]?.img_url && (
                    <UploadButton onClick={onClickUpload}>
                      <UploadIcon />
                    </UploadButton>
                  )}
                  <UploadFileHidden type="file" ref={imgRef} accept="image/*" onChange={onChangeFile} />
                  <EditButtonWrapper>
                    <CancelButton onClick={handleCancel}>취소</CancelButton>
                    <SaveButton onClick={handleUploadImage}>저장</SaveButton>
                  </EditButtonWrapper>
                </EditContainer>
              )}
            </ImageWrapper>
          </IlsangSectionWrapper>
          {/* {!isActiveEditArticle && !isActiveEditImage && (
            <UploadPostWrapper>
              <UploadPostButton onClick={handlePostArticle}>글쓰기</UploadPostButton>
            </UploadPostWrapper>
          )} */}
        </DescriptionInnerWrapper>
      </Container>
    </>
  );
};

export default AdminIlsangPage;

const Container = styled.div`
  color: #fff;
  height: 100%;
  background-color: #fff;
`;

const IlsangSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70rem;

  ${MEDIA_QUERY.sm} {
    flex-direction: column;
  }
`;

const DescriptionInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.xs} {
    padding: 1.5rem;
  }
`;

interface DescriptionWrapperProps {
  isHover: boolean;
  isActiveEditArticle: boolean;
}

// TODO: 나중에 투명도 설정..
const DescriptionWrapper = styled.div<DescriptionWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 44.7rem;
  gap: 10px;
  
  order: 1;
  transition: 0.3s ease;
  opacity: 1;

  ${(props) =>
    props.isHover &&
    !props.isActiveEditArticle &&
    css`
      opacity: 0.3;
    `}

  ${MEDIA_QUERY.sm} {
    width: 100%;
    order: 2;
  }

  ${MEDIA_QUERY.xs} {
    width: 100%;
  }
`;

const Description = styled.span`
  color: #1e1f18;
  font-size: 1.3rem;
  letter-spacing: -0.26px;
  line-height: 19px;
  margin: 0;
  margin-bottom: 20px;
  font-family: S-CoreDream-3Light;

  ${MEDIA_QUERY.sm} {
    display: block;
    margin: 1.4rem 0;
  }
`;

const MainLogo = styled.img`
  width: 13.9rem;
  height: 7.7rem;
  margin-top: 3.7rem;
`;

const PortfolioImage = styled.img`
  position: relative;
  width: 29.8rem;
  height: 44.7rem;
  margin-left: 3.9rem;

  ${MEDIA_QUERY.sm} {
    width: 100%;
    height: 48rem;
    margin: 0 0 5.6rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 100%;
    height: 100%;
    margin: 0 0 3.6rem;
  }
`;

// 관리자 스타일
const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IlsangTitle = styled.span`
  font-family: 'ArgentumSansRegular';
  font-size: 1.8rem;
  color: #000;
  text-align: left;
  margin: -2rem 0 2rem;
`;

const TextEditor = styled(ReactQuill)`
  .ql-container {
    width: 70rem;
    height: 40rem;
    font-family: 'Gowun Batang', serif;
  }
`;

const EditButtonWrapper = styled.div`
  width: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 2rem;
`;

const CancelButton = styled.button`
  width: 10rem;
  height: 3rem;
  border: 1px solid #000;
  margin-right: 2rem;
  color: #000;
  background-color: #fff;
  cursor: pointer;
`;

const SaveButton = styled.button`
  width: 10rem;
  height: 3rem;
  border: none;
  color: #fff;
  background-color: #000;
  cursor: pointer;
`;

const ArticleEditButton = styled.button`
  width: 10rem;
  height: 3rem;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  color: #fff;
  background-color: #000;
  opacity: 1;
  cursor: pointer;
`;

const ImageEditButton = styled.button`
  width: 10rem;
  height: 3rem;
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
  border: none;
  color: #fff;
  background-color: #000;
  opacity: 1;
  cursor: pointer;
`;

interface ImageWrapperProps {
  isHover: boolean;
  isActiveEditImage: boolean;
}

const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  width: 29.8rem;
  height: 44.7rem;
  order: 2;
  transition: 0.3s ease;
  opacity: 1;

  ${(props) =>
    props.isHover &&
    !props.isActiveEditImage &&
    css`
      opacity: 0.3;
    `}

  ${MEDIA_QUERY.sm} {
    order: 1;
  }
`;

const UploadImage = styled.img`
  width: 29.8rem;
  max-height: 44.7rem;
  cursor: pointer;

  ${MEDIA_QUERY.sm} {
    width: 100%;
    height: 48rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 100%;
    height: 100%;
  }
`;

const UploadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30rem;
  height: 30rem;

  outline: none;
  border: none;
  background-color: #ebebeb;
  cursor: pointer;

  ${MEDIA_QUERY.sm} {
    width: 100%;
    height: 48rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 100%;
    height: 100%;
  }
`;

const UploadFileHidden = styled.input`
  display: none;
`;

const UploadIcon = styled(CloudUploadOutlined)`
  color: #1e1f18;
  font-size: 3rem;
`;

const UploadPostWrapper = styled.div`
  display: flex;
  margin: 1.5rem 0 0 67rem;
`;

const UploadPostButton = styled.button`
  width: 6rem;
  height: 3rem;
  border: none;
  background-color: #000;
  color: #fff;
  cursor: pointer;
`;

const StyledDescription = styled(Description)`
  p {
    margin: 0;
  }
`;

const DescriptionSpan = styled.span`
  color: #000;
  font-family: ArgentumSansRegular;
  font-size: 1.3rem;
  margin-bottom: 1rem;
`;
