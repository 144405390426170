import styled from 'styled-components';
import MEDIA_QUERY from '../../constants/mediaQuery';
import { Helmet } from 'react-helmet-async';
import client from '../../api/axios';
import { useEffect, useState } from 'react';
import { Pagination } from 'antd';

const BranchesPage = () => {
  const [branchesData, setBranchesData] = useState<{ results: { results: any[] } } | null>(null);

  const [totalBranches, setTotalBranches] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Branches 데이터 조회
  const handleQueryBranches = async () => {
    try {
      const response = await client.get(`api/v1/branches/branches?page=${currentPage}`, {
        headers: {
          'Content-Type': 'application/json',
          //Authorization: `Token 71c1e61404bcf64bc69306e0e6eeb60cd47ae766`,
          Authorization: `Token 71c1e61404bcf64bc69306e0e6eeb60cd47ae766`,
        },
        withCredentials: true,
      });

      setBranchesData(response.data);
      setTotalBranches(response.data.length);
    } catch (error) {
      console.error(error);
    }
  };

  // 페이지가 변하면 리렌더링
  useEffect(() => {
    handleQueryBranches();
  }, [totalBranches, currentPage]);

  return (
    <>
      <Helmet>
        <title>Branches — 눈부신 일상</title>
      </Helmet>

      <Container>
        <OuterWrapper>
          <BranchWrapper>
            <BranchTitle>BRANCHES</BranchTitle>
            <BranchGridWrapper>
              {branchesData?.results.results.map((item: any, index: number) => (
                <>
                  <BranchImagesWrapper key={index}>
                    <BranchImage src={item.img_url} alt={`${item.office_name} 이미지`} />
                    <BranchInfoWrapper>
                      <BranchName>{item.office_name}</BranchName>
                      <BranchAddress>{item.address}</BranchAddress>
                      <BranchDigit className={index === 0 ? 'hidden' : ''}>{item.digit}</BranchDigit>
                      <BranchSnsWrapper>
                        <a href={item?.ins_link_url}>
                          <SnsImage src="/images/insta.svg" alt={`${item.office_name} 인스타그램 바로가기 버튼`} />
                        </a>
                        <a href={item?.item?.you_link_url}>
                          <SnsImage src="/images/youtube.svg" alt={`${item.office_name} 유튜브 바로가기 버튼`} />
                        </a>
                        <a href={item?.fac_link_url}>
                          <SnsImage src="/images/facebook.svg" alt={`${item.office_name} 페이스북 바로가기 버튼`} />
                        </a>
                        <a href={item?.sms_link_url}>
                          <SnsImage src="/images/mail.svg" alt={`${item.office_name} 메일 바로가기 버튼`} />
                        </a>
                      </BranchSnsWrapper>
                    </BranchInfoWrapper>
                  </BranchImagesWrapper>
                </>
              ))}
            </BranchGridWrapper>

            {/* 페이지네이션 */}
            <Pagination defaultPageSize={4} current={currentPage} total={totalBranches} onChange={handlePageChange} />
          </BranchWrapper>
        </OuterWrapper>
      </Container>
    </>
  );
};

export default BranchesPage;

const Container = styled.div`
  color: #fff;
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-top: -3rem;

  ${MEDIA_QUERY.sm} {
    margin-bottom: -6rem;
  }
`;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BranchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.lg} {
    width: 67.5rem;
    margin-bottom: 12rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 32rem;
    margin-bottom: 0;
  }

  ${MEDIA_QUERY.xs} {
    padding: 0 1rem;
  }
`;

const BranchTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.7rem;
  letter-spacing: 6.8px;
  line-height: 21px;
  color: #1e1f18;

  ${MEDIA_QUERY.lg} {
    margin-bottom: 7.4rem;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 2.6px;
    margin: 7.8rem 0 5.8rem;
  }
`;

const BranchGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${MEDIA_QUERY.sm} {
    grid-template-columns: 1fr;
  }
`;

const BranchImagesWrapper = styled.div`
  display: flex;
  padding: 1.5rem;

  ${MEDIA_QUERY.xs} {
    padding: 1rem;
  }
`;

const BranchInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${MEDIA_QUERY.lg} {
    width: 16rem;
    height: 16.5rem;
    padding: 2.4rem 2.8rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 14rem;
    height: 14.5rem;
    padding: 0 0 1.8rem 2.4rem;
  }
`;

const BranchImage = styled.img`
  width: 16rem;
  height: 16.5rem;

  ${MEDIA_QUERY.xs} {
    width: 12rem;
    height: 12.5rem;
  }
`;

const BranchName = styled.span`
  font-family: 'NanumSquareNeoBold';

  font-weight: 700;
  color: #424530;

  ${MEDIA_QUERY.lg} {
    font-size: 1.2rem;
    margin-bottom: 2.5rem;
  }
`;

const BranchAddress = styled.p`
  font-family: 'NanumSquareNeoRegular';
  font-size: 1rem;
  line-height: 1.5rem;
  color: #1e1f18;

  ${MEDIA_QUERY.sm} {
    margin: 0 0 1.7rem;
  }
`;

const BranchDigit = styled.p`
  font-family: 'NanumSquareNeoRegular';
  font-size: 1rem;
  line-height: 1.5rem;
  color: #1e1f18;

  &.hidden {
    display: none;
  }

  ${MEDIA_QUERY.sm} {
    margin: 0 0 1.7rem;
  }
`;


const BranchSnsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const SnsImage = styled.img`
  margin-right: 1.2rem;

  ${MEDIA_QUERY.lg} {
    width: 1.2rem;
    height: 1.2rem;
  }

  ${MEDIA_QUERY.sm} {
    margin-right: 1.1rem;
  }
`;
