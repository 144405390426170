import styled from 'styled-components';
import MEDIA_QUERY from '../../../constants/mediaQuery';
import useMediaQuery from '../../../hook/useMediaQuery';
import { Helmet } from 'react-helmet-async';
import client from '../../../api/axios';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { mainTokenAtom } from '../../../stores';

type IlsangDataType = {
  content?: string;
  created_at: string;
  id?: string;
  img_url?: string;
  is_cancel?: string;
};

const IlsangPage = () => {
  const { isXs, isSm, isLg } = useMediaQuery();

  const [ilsangData, setIlsangData] = useState<IlsangDataType[] | undefined>([]);

  // 전체 데이터 조회
  const handleQueryArticle = async () => {
    try {
      const response = await client.get('api/v1/articles/ilsang/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token 71c1e61404bcf64bc69306e0e6eeb60cd47ae766`,
        },
        withCredentials: true,
      });

      // 마지막 포스팅된 글을 기준으로 수정하기 위
      setIlsangData(response.data.results);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleQueryArticle();
  }, []);

  // 화면마다 줄바꿈이 다르게 처리됨
  return (
    <>
      <Helmet>
        <title>About — 눈부신 일상</title>
      </Helmet>

      <Container>
        <DescriptionInnerWrapper>
          <IlsangSectionWrapper>
            <DescriptionWrapper>
              {/* 폴더블 화면 */}
              {isXs && <StyledDescription dangerouslySetInnerHTML={{ __html: ilsangData?.[0]?.content || '' }} />}

              {/* 모바일 화면 */}
              {isSm && !isXs && (
                <StyledDescription dangerouslySetInnerHTML={{ __html: ilsangData?.[0]?.content || '' }} />
              )}

              {/* pc 화면 */}
              {!isSm && isLg && (
                <StyledDescription dangerouslySetInnerHTML={{ __html: ilsangData?.[0]?.content || '' }} />
              )}
              <MainLogo src="/images/logo.svg" alt="눈부신 일상 로고" />
            </DescriptionWrapper>
            <PortfolioImage src={ilsangData?.[0]?.img_url || ''} alt="아이가 소파 위를 뛰어 노는 사진" />
          </IlsangSectionWrapper>
        </DescriptionInnerWrapper>
      </Container>
    </>
  );
};

export default IlsangPage;

const Container = styled.div`
  color: #fff;
  height: 100%;
  background-color: #fff;
`;

const IlsangSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70rem;

  ${MEDIA_QUERY.sm} {
    flex-direction: column;
  }
`;

const DescriptionInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.xs} {
    padding: 1.5rem;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 44.7rem;
  gap: 10px;
  
  order: 1;

  ${MEDIA_QUERY.sm} {
    width: 32rem;
    order: 2;
  }

  ${MEDIA_QUERY.xs} {
    width: 100%;
  }
`;

const Description = styled.p`
  color: #1e1f18;
  font-size: 1.3rem;
  letter-spacing: -0.26px;
  line-height: 19px;
  margin: 0;
  margin-bottom: 20px;
  font-family: S-CoreDream-3Light;
  ${MEDIA_QUERY.sm} {
    margin: 1.4rem 0;
  }
`;

const MainLogo = styled.img`
  width: 13.9rem;
  height: 7.7rem;
  margin-top: 0.5rem;
`;

const PortfolioImage = styled.img`
  width: 29.8rem;
  height: 44.7rem;
  margin-left: 3.9rem;

  order: 2;

  ${MEDIA_QUERY.sm} {
    width: 32rem;
    height: 48rem;
    margin: 0 0 5.6rem;

    order: 1;
  }

  ${MEDIA_QUERY.xs} {
    width: 100%;
    height: 100%;
    margin: 0 0 3.6rem;
  }
`;

const StyledDescription = styled(Description)`
  p {
    margin: 0;
    font-family: ArgentumSansRegular;
  }
`;
