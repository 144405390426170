export type ScreenBreakpoint = 'xs' | 'sm' | 'md' | 'lg';

const SCREEN_BREAKPOINT = {
  xs: 320, // foldable max-width
  sm: 830, // 모바일 max-width
  md: 1024, // 태블릿 max-width
  lg: 10000, // pc max-width
} as const;

export default SCREEN_BREAKPOINT;
