import styled from 'styled-components';
import MEDIA_QUERY from '../../../constants/mediaQuery';
import { NavLink } from 'react-router-dom';

interface DropdownMenuProps {
  dropdownMenu: any[];
  setOpenMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setIsVisibleDropdownMenu: React.Dispatch<React.SetStateAction<string>>;
}

const HamburgerDropdownMenu = ({ dropdownMenu, setOpenMobileMenu, setIsVisibleDropdownMenu }: DropdownMenuProps) => {
  return (
    <Container>
      <TapMenuWrapper>
        <TapMenuHeader>
          <TapMenuCloseBtn
            src="/images/HamburgerBtn_close.svg"
            alt="메뉴 닫기 버튼"
            onClick={() => setOpenMobileMenu((prev) => !prev)}
          />
        </TapMenuHeader>
        <TapMenuBackBtnWrapper onClick={() => setIsVisibleDropdownMenu('')}>
          <TapMenuBackBtnIcon src="/images/HamburgerBtn_arrow2.svg" alt="메뉴 이동 아이콘" />
          <TapMenuBackBtnText>Back</TapMenuBackBtnText>
        </TapMenuBackBtnWrapper>
        <DropdownMenuWrapper>
          {dropdownMenu.map((menu) => {
            const menuName = Object.keys(menu)[0];
            const menuItems = menu[menuName];

            return (
              <>
                {/* 한글 폰트는 다른 서체여야해서 분기처리 */}
                {menuName !== 'contact' && (
                  <div key={menuName}>
                    {menuItems.map((item: { name: string; url: string }) => (
                      <DropdownMenus key={item.name}>
                        <DropdownMenu to={item.url} onClick={() => setOpenMobileMenu((prev) => !prev)}>
                          {item.name}
                        </DropdownMenu>
                      </DropdownMenus>
                    ))}
                  </div>
                )}
                {menuName === 'contact' && (
                  <div key={menuName}>
                    {menuItems.map((item: { name: string; url: string }) => (
                      <DropdownMenus key={item.name}>
                        <DropdownContactMenu to={item.url} onClick={() => setOpenMobileMenu((prev) => !prev)}>
                          {item.name}
                        </DropdownContactMenu>
                      </DropdownMenus>
                    ))}
                  </div>
                )}
              </>
            );
          })}
        </DropdownMenuWrapper>
      </TapMenuWrapper>
    </Container>
  );
};

export default HamburgerDropdownMenu;

const Container = styled.div`
  ${MEDIA_QUERY.md} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 3.6rem;
    background-color: #fff;
    opacity: 1;
    z-index: 1001;
  }
`;

const TapMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TapMenuHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TapMenuCloseBtn = styled.img``;

const TapMenuBackBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${MEDIA_QUERY.md} {
    width: 6.5rem;
  }
`;

const TapMenuBackBtnText = styled.span`
  color: #424530;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5rem;
  font-weight: 400;
  letter-spacing: -1.25px;

  ${MEDIA_QUERY.md} {
    margin-left: 0.76rem;
    margin-top: 0.65rem;
  }
`;

const TapMenuBackBtnIcon = styled.img`
  margin-top: 0.4rem;
`;

const DropdownMenuWrapper = styled.div`
  display: flex;

  ${MEDIA_QUERY.md} {
    margin-top: 7rem;
  }
`;

const DropdownMenus = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropdownMenu = styled(NavLink)`
  color: #424530;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 1.3px;
  text-decoration: none;

  ${MEDIA_QUERY.md} {
    margin-bottom: 2.2rem;
  }
`;

const DropdownContactMenu = styled(NavLink)`
  color: #424530;
  font-family: 'NanumSquareNeoRegular';
  font-size: 1.4rem;
  letter-spacing: 1.3px;
  text-decoration: none;

  ${MEDIA_QUERY.md} {
    margin-bottom: 2.2rem;
  }
`;
