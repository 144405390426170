import styled from 'styled-components';
import MEDIA_QUERY from '../../../constants/mediaQuery';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import HamburgerDropdownMenu from '../HamburgerDropdownMenu';

interface TapMenuProps {
  setOpenMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

type DropdownMenu = {
  name: string;
  url: string;
};

type Menu = {
  about?: DropdownMenu[];
  portfolio?: DropdownMenu[];
  contact?: DropdownMenu[];
};

const HamburgerTapMenu = ({ setOpenMobileMenu }: TapMenuProps) => {
  const [isVisibleDropdownMenu, setIsVisibleDropdownMenu] = useState('');
  const [dropdownMenu, setDropdownMenu] = useState<Menu[]>([]);

  const dropdownMenus = [
    {
      about: [
        { name: 'ILSANG', url: '/about/ilsang' },
        { name: 'TIPS', url: '/about/tips' },
      ],
    },
    {
      portfolio: [
        { name: 'MATERNITY/NEWBORN', url: '/portfolio/maternity/newborn' },
        { name: 'BABY/FAMILY', url: '/portfolio/baby/family' },
        { name: 'PROFILE/JUNIOR', url: '/portfolio/profile/junior' },
        { name: 'FAMILY', url: '/portfolio/family' },
        { name: 'EVENT PHOTOGRAPHY', url: 'portfolio/profile/event/photography' },
        { name: 'PRODUCT', url: 'portfolio/product' },
      ],
    },
    {
      contact: [
        { name: '예약 및 문의', url: '/contact' },
        { name: '제휴문의', url: '/contact/partnerships' },
        { name: '가맹', url: '/contact/franchisee' },
      ],
    },
  ];

  useEffect(() => {
    const updatedDropdownMenu: Menu[] = [];

    if (isVisibleDropdownMenu === 'about') {
      const aboutMenu = dropdownMenus.find((menu) => 'about' in menu);
      aboutMenu && updatedDropdownMenu.push(aboutMenu);
    }

    if (isVisibleDropdownMenu === 'portfolio') {
      const portfolioMenu = dropdownMenus.find((menu) => 'portfolio' in menu);
      portfolioMenu && updatedDropdownMenu.push(portfolioMenu);
    }

    if (isVisibleDropdownMenu === 'contact') {
      const contactMenu = dropdownMenus.find((menu) => 'contact' in menu);
      contactMenu && updatedDropdownMenu.push(contactMenu);
    }

    setDropdownMenu(updatedDropdownMenu);
  }, [isVisibleDropdownMenu]);

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed;
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;
    `;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  });

  return (
    <>
      <Container>
        <TapMenuWrapper>
          <TapMenuHeader>
            <TapMenuCloseBtn
              src="/images/HamburgerBtn_close.svg"
              alt="메뉴 닫기 버튼"
              onClick={() => setOpenMobileMenu((prev) => !prev)}
            />
          </TapMenuHeader>
          <MenuItemWrapper>
            <MenuItem onClick={() => setIsVisibleDropdownMenu('about')}>
              <MenuName>ABOUT</MenuName>
              <MenuArrowImage src="/images/hamburgerBtn_arrow.svg" alt="메뉴 이동 아이콘" />
            </MenuItem>
            <MenuItem onClick={() => setIsVisibleDropdownMenu('portfolio')}>
              <MenuName>PORTFOLIO</MenuName>
              <MenuArrowImage src="/images/hamburgerBtn_arrow.svg" alt="메뉴 이동 아이콘" />
            </MenuItem>
            <MenuItem>
              <MenuNavLink to="/price" onClick={() => setOpenMobileMenu((prev) => !prev)}>
                <MenuName>PRICE</MenuName>
              </MenuNavLink>
              <MenuArrowImage src="/images/hamburgerBtn_arrow.svg" alt="메뉴 이동 아이콘" />
            </MenuItem>
            <MenuItem>
              <MenuNavLink to="/branches/korea" onClick={() => setOpenMobileMenu((prev) => !prev)}>
                <MenuName>BRANCHES</MenuName>
              </MenuNavLink>
              <MenuArrowImage src="/images/hamburgerBtn_arrow.svg" alt="메뉴 이동 아이콘" />
            </MenuItem>
            <MenuItem onClick={() => setIsVisibleDropdownMenu('contact')}>
              <MenuName>CONTACT</MenuName>
              <MenuArrowImage src="/images/hamburgerBtn_arrow.svg" alt="메뉴 이동 아이콘" />
            </MenuItem>
          </MenuItemWrapper>
        </TapMenuWrapper>
      </Container>

      {isVisibleDropdownMenu && (
        <HamburgerDropdownMenu
          setOpenMobileMenu={setOpenMobileMenu}
          dropdownMenu={dropdownMenu}
          setIsVisibleDropdownMenu={setIsVisibleDropdownMenu}
        />
      )}
    </>
  );
};

export default HamburgerTapMenu;

const Container = styled.div`
  ${MEDIA_QUERY.md} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 3.6rem;
    background-color: #fff;
    opacity: 1;
    z-index: 1000;
  }
`;

const TapMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TapMenuHeader = styled.div`
  display: flex;
  justify-content: flex-end;

  ${MEDIA_QUERY.md} {
    margin-bottom: 1.7rem;
  }
`;

const TapMenuCloseBtn = styled.img``;

const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;

  ${MEDIA_QUERY.md} {
    width: 6.5rem;
    margin-bottom: 2.2rem;
  }
`;

const MenuName = styled.span`
  ${MEDIA_QUERY.md} {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 1.12px;
    color: #424530;
    margin-right: 0.74rem;
  }
`;

const MenuNavLink = styled(NavLink)`
  font-family: 'ArgentumSans';
  color: #424530;
  text-decoration: none;

  ${MEDIA_QUERY.md} {
    font-size: 1.4rem;
    letter-spacing: 1.12px;
    margin-right: 0.74rem;
  }
`;

const MenuArrowImage = styled.img``;
