import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import useMediaQuery from '../../../hook/useMediaQuery';
import MEDIA_QUERY from '../../../constants/mediaQuery';
import client from '../../../api/axios';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../../stores';
import { Button, Form, Input, message } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { checkValidationImage } from '../../../utils';

export type PriceTitleDataType = {
  id?: number;
  title?: string;
  content?: string;
  event?: string;
  event_content?: string;
  is_cancel?: string;
  created_at?: string;
};

export type ProductTitleDataType = {
  id?: number;
  title?: string;
  content?: string;
  category?: string;
  is_cancel?: string;
  created_at?: string;
};

export type DetailProductDataType = {
  id?: number;
  title?: string;
  content: string;
  price?: string;
  times?: string;
  thema: string;
  album: string;
  photoframe?: string;
  filmingversion?: string;
  etc?: string;
  img_url?: string;
  is_cancel?: string;
  created_at?: string;
  priceproduct_id?: number;
};

const AdminPricePage = () => {
  const { isXs, isSm, isLg } = useMediaQuery();

  const [userToken] = useAtom(tokenAtom);

  // 텍스트 에디터
  const [editorValue, setEditorValue] = useState('');
  const [key, setKey] = useState(Math.random());

  const modules = {
    toolbar: [[{ header: [1, 2, 3, false] }], ['bold', 'italic', 'underline', 'strike'], [{ list: 'bullet' }]],
  };
  const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'list'];

  // 이미지 검증할 때 formData를 위한 state
  const [image, setImage] = useState<any>();

  const [firstFileUrl, setFirstFileUrl] = useState('');
  const [secondeFileUrl, setSecondFileUrl] = useState('');

  const imgRef = useRef<HTMLInputElement>(null);

  // price title 전체 데이터
  const [priceTitleData, setPriceTitleData] = useState<PriceTitleDataType[]>([]);

  // price product 전체 데이터
  const [simpleTitleData, setSimpleTitleData] = useState<ProductTitleDataType[]>([]);

  // price detail product 전체 데이터
  const [priceDetailProductData, setPriceDetailProductData] = useState<DetailProductDataType[]>([]);

  const [isEditPriceTitle, setIsEditPriceTitle] = useState(false);

  const [priceTitleEditMode, setPriceTitleEditMode] = useState(false);

  const [isEditEventTitle, setIsEditEventTitle] = useState(false);

  const [eventTitleEditMode, setEventTitleEditMode] = useState(false);

  // 심플 성장
  const [isSimpleSectionEdit, setIsSimpleSectionEdit] = useState(false);
  const [simpleSectionEditMode, setSimpleSectionEditMode] = useState(false);

  // 시그니처 성장
  const [isSignatureSectionEdit, setIsSignatureSectionEdit] = useState(false);
  const [signatureSectionEditMode, setSignatureSectionEditMode] = useState(false);

  // 스페셜 성장
  const [isSpecialSectionEdit, setIsSpecialSectionEdit] = useState(false);
  const [specialSectionEditMode, setSpecialSectionEditMode] = useState(false);

  // 베이직 단품
  const [isBasicSectionEdit, setIsBasicSectionEdit] = useState(false);
  const [basicSectionEditMode, setBasicSectionEditMode] = useState(false);

  // 파일이 바뀌었는지
  const [ischange, setIschange] = useState(false);

  // Price product 전체 데이터 조회
  const handleQueryProductList = async () => {
    try {
      const response = await client.get('api/v1/prices/priceproduct/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      setSimpleTitleData(response.data.results);
    } catch (error) {
      console.error(error);
    }
  };

  // product 타이틀 Post (Ex. 심플 성장)
  const handlePostProductTitle = async (id: number, data: ProductTitleDataType, category: string) => {
    const newData = { category, ...data };
    console.log(newData)
    if (data.title === '') {
      delete data.title;
    }
    if (data.content === '') {
      delete data.content;
    }

    try {
      await client.post(`api/v1/prices/priceproduct/${id}/`, newData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      message.success({ content: 'price 페이지의 미니 타이틀과 컨텐츠가 수정되었습니다.' });
    } catch (error) {
      console.error(error);
    }
  };

  // Detail product Post (Ex. 심플 성장)
  const handlePostDetailProduct = async (data: DetailProductDataType, id: number) => {
    // 이미지가 존재할 때만 이미지 검증 로직을 탈 수 있게
    //if (data.hasOwnProperty('img_url')) {
      if (ischange) {
      console.log(data)
      const formData = new FormData();
      formData.append('image', image);
      formData.append('tag', 'PRICE'); // tag는 페이지에 따라 다르게 입력

      // 이미지 검증 및 스토리지 저장
      const response = await client.post('api/v1/images/image/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      data.img_url = response.data.img_url;
    }

    try {
      await client.post(`api/v1/prices/pricedetailproduct/${id}/`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      message.success({ content: 'price 페이지의 패키지 상품이 수정되었습니다.' });
      setIschange(false);
    } catch (error) {
      console.error(error);
      setIschange(false);
    }
  };

  // Price product 전체 데이터 조회
  const handleQueryDetailProductList = async () => {
    try {
      const response = await client.get('api/v1/prices/pricedetailproduct/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });
      setPriceDetailProductData(response.data.results);
      console.log(response.data.results)
    } catch (error) {
      console.error(error);
    }
  };

  // Price title 전체 데이터 조회
  const handleQueryPriceTitle = async () => {
    try {
      const response = await client.get('/api/v1/prices/pricetitle/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      setPriceTitleData(response.data.results);
    } catch (error) {
      console.error(error);
    }
  };

  // Price title 데이터 Post
  const handlePostPriceTitle = async (data: PriceTitleDataType, id: any) => {
    try {
      await client.post(`/api/v1/prices/pricetitle/${id}/`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      setIsEditEventTitle(false);
      setEventTitleEditMode(false);

      handleQueryPriceTitle();

      message.success({ content: 'price 페이지의 컨텐츠가 수정되었습니다.' });
    } catch (error) {
      console.error(error);
    }
  };

  // price title 수정 취소
  const handlePriceTitleCancel = () => {
    setIsEditPriceTitle(false);
    setPriceTitleEditMode(false);
    setKey(Math.random());
  };

  // event title 수정 취소
  const handleEventTitleCancel = () => {
    setIsEditPriceTitle(false);
    setEventTitleEditMode(false);
    setKey(Math.random());
  };

  // price 첫번째 섹션 수정 취소
  const handlePriceSimpleSectionCancel = () => {
    setIsSimpleSectionEdit(false);
    setSimpleSectionEditMode(false);
    setKey(Math.random());
  };

  // price 두번째 섹션 수정 취소
  const handlePriceSignatureSectionCancel = () => {
    setIsSignatureSectionEdit(false);
    setSignatureSectionEditMode(false);
    setKey(Math.random());
  };

  // price 세번째 섹션 수정 취소
  const handlePriceSpecialSectionCancel = () => {
    setIsSpecialSectionEdit(false);
    setSpecialSectionEditMode(false);
    setKey(Math.random());
  };

  // price 마지막 섹션 수정 취소
  const handlePriceBasicSectionCancel = () => {
    setIsBasicSectionEdit(false);
    setBasicSectionEditMode(false);
    setKey(Math.random());
  };

  const onChangeSingleFile = (orderNum: number) => (e: ChangeEvent<HTMLInputElement>) => {
    setIschange(true);
    const isValid = checkValidationImage(e.target.files?.[0]);
    if (!isValid) return;

    try {
      if (e.target.files) {
        const file = e.target.files?.[0];
        setImage(file);
        const reader = new FileReader();
        if (e.target.files) {
          reader.readAsDataURL(e.target.files[0]);
          reader.onloadend = () => {
            if (typeof reader.result === 'string') {
              if (orderNum === 1) {
                setFirstFileUrl(reader.result);
              } else {
                setSecondFileUrl(reader.result);
              }
            }
          };
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const img = new Image();
    img.src = 'images/price/price_1.png';
  }, []);

  useEffect(() => {
    handleQueryProductList();
    handleQueryPriceTitle();
    handleQueryDetailProductList();
  }, []);

  return (
    <>
      <Helmet>
        <title>관리자 | Price — 눈부신 일상</title>
      </Helmet>

      <Container>
        <OuterWrapper>
          <PriceTitleWrapper
            onMouseEnter={() => setIsEditPriceTitle(true)}
            onMouseLeave={() => setIsEditPriceTitle(false)}
            isEdit={isEditPriceTitle}
            isEditMode={priceTitleEditMode}
          >
            {!priceTitleEditMode && (
              <>
                <PriceTitle>{priceTitleData[0]?.title}</PriceTitle>
                <PriceParagraph dangerouslySetInnerHTML={{ __html: priceTitleData[0]?.content || '' }} />
              </>
            )}
            {isEditPriceTitle && !priceTitleEditMode && (
              <EditButton onClick={() => setPriceTitleEditMode(true)}>수정</EditButton>
            )}
            {/* price title 수정 모드 */}
            {priceTitleEditMode && (
              <>
                <Form
                  layout="vertical"
                  initialValues={{ title: priceTitleData[0]?.title, content: priceTitleData[0]?.content }}
                  onFinish={(data) => handlePostPriceTitle(data, 1)}
                >
                  <Form.Item label="Price 메인 타이틀" name="title">
                    <TitleEditor placeholder="Price 메인 타이틀을 입력해주세요" />
                  </Form.Item>
                  <Form.Item label="Price 메인 컨텐츠" name="content">
                    <TextEditor
                      className="form-control text-editor"
                      theme="snow"
                      placeholder="Price 메인 컨텐츠를 입력해주세요"
                      modules={modules}
                      formats={formats}
                      value={editorValue}
                      onChange={(content, delta, source, editor) => setEditorValue(editor.getHTML())}
                      style={{ width: '100%' }}
                      key={key}
                    />
                  </Form.Item>
                  <EditButtonWrapper>
                    <CancelButton onClick={handlePriceTitleCancel}>취소</CancelButton>
                    <SaveButton htmlType="submit">저장</SaveButton>
                  </EditButtonWrapper>
                </Form>
              </>
            )}
          </PriceTitleWrapper>

          {/* 심플 성장 */}
          <SimpleSectionWrapper
            onMouseEnter={() => setIsSimpleSectionEdit(true)}
            onMouseLeave={() => setIsSimpleSectionEdit(false)}
            isEdit={isSimpleSectionEdit}
            isEditMode={simpleSectionEditMode}
          >
            {!simpleSectionEditMode && (
              <>
                <SimpleSectionOnlyOne>
                  <SimpleTitle>심플 성장</SimpleTitle>
                  {/* <SimpleTitleWrapper>
                    <SimpleTitle>심플 성장</SimpleTitle>
                  </SimpleTitleWrapper> */}
                  {/* <SimpleParagraph>
                    심플 성장 패키지 상품은 돌사진 촬영 시 엄마, 아빠, 아기가 함께 촬영합니다. <br />
                    가족사진과 위드 컷은 원본과 함께 기본 수정본(피부, 몸매보정)이 제공됩니다.
                  </SimpleParagraph> */}
                </SimpleSectionOnlyOne>
                <SimpleAlbumPriceWrapper>
                  <AlbumMarginPriceImage src={priceDetailProductData[0]?.img_url} alt="소소한 일상 앨범 이미지" />
                  <AlbumDescritionWrapper style={{ margin: '0 0 4rem' }}>
                    <AlbumDescriptionTitleWrapper>
                      <AlbumDescritionSubTitle>{priceDetailProductData[0]?.title}</AlbumDescritionSubTitle>
                      <AlbumDescritionTitle>{priceDetailProductData[0]?.content}</AlbumDescritionTitle>
                    </AlbumDescriptionTitleWrapper>
                    {isLg && !isSm && (
                      <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[0]?.album || '' }} />
                    )}
                    {isSm && (
                      <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[0]?.album || '' }} />
                    )}
                  </AlbumDescritionWrapper>
                </SimpleAlbumPriceWrapper>
                {isLg && !isSm && (
                  <>
                    <DivideLine style={{ margin: 0 }} />
                    <SimpleAlbumPriceWrapper>
                      <AlbumLeftDescriptionWrapper style={{ marginBottom: '3rem' }}>
                        <AlbumDescriptionTitleWrapper>
                          <AlbumDescritionSubTitle>{priceDetailProductData[1]?.title}</AlbumDescritionSubTitle>
                          <AlbumDescritionTitle>{priceDetailProductData[1]?.content}</AlbumDescritionTitle>
                        </AlbumDescriptionTitleWrapper>
                        <StyledDescription
                          dangerouslySetInnerHTML={{ __html: priceDetailProductData[1]?.album || '' }}
                        />
                      </AlbumLeftDescriptionWrapper>
                      <AlbumPriceImage src={priceDetailProductData[1]?.img_url} alt="달콤한 일상 앨범 이미지" />
                    </SimpleAlbumPriceWrapper>
                  </>
                )}
                {/* {isSm && (
                  <>
                    <DivideLine />
                    <SimpleAlbumPriceWrapper>
                      <AlbumMarginPriceImage src="/images/price/price_2.png" alt="달콤한 일상 앨범 이미지" />
                      <AlbumDescritionWrapper>
                        <AlbumDescriptionTitleWrapper>
                          <AlbumDescritionSubTitle>심플성장</AlbumDescritionSubTitle>
                          <AlbumDescritionTitle>달콤한 일상</AlbumDescritionTitle>
                        </AlbumDescriptionTitleWrapper>
                        <AlbumDescrition style={{ marginBottom: '3.6rem' }}>
                          <AlbumDescriptionList>
                            백일 2테마 + <br /> 돌 3테마(가족 1테마 촬영 가능)
                          </AlbumDescriptionList>
                          <br /> <br />
                          <AlbumDescriptionList>20x25cm 에코블럭 액자 2점</AlbumDescriptionList>
                          <AlbumDescriptionList>액자2점(20x25cm 데코블럭)</AlbumDescriptionList>
                          <br />
                          <AlbumDescriptionList>촬영 원본</AlbumDescriptionList>
                          <AlbumDescriptionList>₩1,390,000(30분/60분)</AlbumDescriptionList>
                        </AlbumDescrition>
                      </AlbumDescritionWrapper>
                    </SimpleAlbumPriceWrapper>
                  </>
                )} */}
                 {isSm && (
                  <SpecialAlubmPriceWrapper
                    style={{
                      padding: '0 0 3.5rem',
                    }}
                  >
                    {isSm && !isXs && (
                      <AlbumMarginPriceImage src={priceDetailProductData[1]?.img_url} alt="우리의 일상 앨범 이미지" />
                    )}
                    {isXs && (
                      <AlbumFordablePriceImage src={priceDetailProductData[1]?.img_url} alt="우리의 일상 앨범 이미지" />
                    )}
                    <AlbumDescritionWrapper>
                      <AlbumDescriptionTitleWrapper>
                        <AlbumDescritionSubTitle>{priceDetailProductData[1]?.title}</AlbumDescritionSubTitle>
                        <AlbumDescritionTitle>{priceDetailProductData[1]?.content}</AlbumDescritionTitle>
                      </AlbumDescriptionTitleWrapper>

                      <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[1]?.album || '' }} />
                    </AlbumDescritionWrapper>
                  </SpecialAlubmPriceWrapper>
                )}
                <DivideLine style={{ margin: 0 }} />
              </>
            )}
            {isSimpleSectionEdit && !simpleSectionEditMode && (
              <EditButton onClick={() => setSimpleSectionEditMode(true)}>수정</EditButton>
            )}
            {/* 심플 성장 수정 모드 */}
            {simpleSectionEditMode && (
              <>
                <DescriptionSpan>
                  기존 패키지 상품의 정렬대로 수정하시고, 바꾸고 싶은 컨텐츠만 수정하시면 됩니다.
                </DescriptionSpan>

                <Form
                  layout="vertical"
                  onFinish={(data) => handlePostProductTitle(1, data, 'section1')}
                  style={{
                    marginTop: '3rem',
                  }}
                >
                  <Form.Item
                    label="첫번째 섹션 미니 타이틀"
                    name="title"
                    initialValue={simpleTitleData[0]?.title || ''}
                  >
                    <Input placeholder="심플 성장" autoComplete="off" />
                  </Form.Item>
                  <Form.Item
                    label="첫번째 섹션 미니 컨텐츠"
                    name="content"
                    initialValue={simpleTitleData[0]?.content || ''}
                  >
                    <MiniTitleTextEditor
                      className="form-control text-editor"
                      theme="snow"
                      placeholder="ex) 백일 2테마 + 돌 2테마(독사진)"
                      modules={modules}
                      formats={formats}
                      value={editorValue}
                      onChange={(content, delta, source, editor) => setEditorValue(editor.getHTML())}
                      style={{ width: '100%' }}
                      key={key}
                    />
                  </Form.Item>

                  <SimpleSectionEditButtonWrapper>
                    <SimpleSectionEditButton htmlType="submit">저장</SimpleSectionEditButton>
                  </SimpleSectionEditButtonWrapper>
                </Form>

                <DivideLine style={{ width: '100%' }} />

                <Form
                  layout="vertical"
                  onFinish={(data) => handlePostDetailProduct(data, 1)}
                  style={{ display: 'flex', flexDirection: 'column', marginTop: '3rem' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Item name="img_url" initialValue={priceDetailProductData[0]?.img_url || ''}>
                      <CreateImage
                        src={
                          firstFileUrl
                            ? firstFileUrl
                            : priceDetailProductData[0]?.img_url
                            ? priceDetailProductData[0].img_url
                            : image
                        }
                      />
                      <ImageCreateInput type="file" ref={imgRef} accept="image/*" onChange={onChangeSingleFile(1)} />
                      <DescriptionSpan style={{ fontSize: '1.2rem' }}>
                        [유의 사항] 올리신 이미지는 자동으로 360x275 사이즈로 맞춰집니다.
                      </DescriptionSpan>
                    </Form.Item>

                    <div>
                      <Form.Item
                        label="패키지 상품 미니 타이틀"
                        name="title"
                        initialValue={priceDetailProductData[0]?.title || ''}
                      >
                        <Input placeholder="심플 성장" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 타이틀"
                        name="content"
                        initialValue={priceDetailProductData[0]?.content || ''}
                      >
                        <Input placeholder="소소한 일상" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 설명"
                        name="album"
                        initialValue={priceDetailProductData[0]?.album || ''}
                      >
                        <TextEditor
                          className="form-control text-editor"
                          theme="snow"
                          placeholder="ex) 백일 2테마 + 돌 2테마(독사진)"
                          modules={modules}
                          formats={formats}
                          value={editorValue}
                          onChange={(content, delta, source, editor) => setEditorValue(editor.getHTML())}
                          style={{ width: '100%' }}
                          key={key}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <SimpleSectionEditButtonWrapper style={{ display: 'flex', justifyContent: 'right' }}>
                    <SimpleSectionEditButton htmlType="submit">저장</SimpleSectionEditButton>
                  </SimpleSectionEditButtonWrapper>
                </Form>

                <DivideLine style={{ width: '100%' }} />

                <Form
                  layout="vertical"
                  onFinish={(data) => handlePostDetailProduct(data, 2)}
                  style={{ display: 'flex', flexDirection: 'column', marginTop: '3rem' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <Form.Item
                        label="패키지 상품 미니 타이틀"
                        name="title"
                        initialValue={priceDetailProductData[1]?.title || ''}
                      >
                        <Input placeholder="심플 성장" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 타이틀"
                        name="content"
                        initialValue={priceDetailProductData[1]?.content || ''}
                      >
                        <Input placeholder="소소한 일상" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 설명"
                        name="album"
                        initialValue={priceDetailProductData[1]?.album || ''}
                      >
                        <TextEditor
                          className="form-control text-editor"
                          theme="snow"
                          placeholder="ex) 백일 2테마 + 돌 2테마(독사진)"
                          modules={modules}
                          formats={formats}
                          value={editorValue}
                          onChange={(content, delta, source, editor) => setEditorValue(editor.getHTML())}
                          style={{ width: '100%' }}
                          key={key}
                        />
                      </Form.Item>
                    </div>

                    <Form.Item name="img_url" initialValue={priceDetailProductData[1]?.img_url || ''}>
                      <CreateImage
                        src={
                          secondeFileUrl
                            ? secondeFileUrl
                            : priceDetailProductData[1]?.img_url
                            ? priceDetailProductData[1].img_url
                            : image
                        }
                      />
                      <ImageCreateInput type="file" ref={imgRef} accept="image/*" onChange={onChangeSingleFile(2)} />
                      <DescriptionSpan style={{ fontSize: '1.2rem' }}>
                        [유의 사항] 올리신 이미지는 자동으로 360x275 사이즈로 맞춰집니다.
                      </DescriptionSpan>
                    </Form.Item>
                  </div>

                  <SimpleSectionEditButtonWrapper style={{ display: 'flex', justifyContent: 'right' }}>
                    <SimpleSectionCancelButton onClick={handlePriceSimpleSectionCancel}>취소</SimpleSectionCancelButton>
                    <SimpleSectionEditButton htmlType="submit">저장</SimpleSectionEditButton>
                  </SimpleSectionEditButtonWrapper>
                </Form>
              </>
            )}
          </SimpleSectionWrapper>

          {/* 시그니처 성장 */}
          <SignatureContainer
            onMouseEnter={() => setIsSignatureSectionEdit(true)}
            onMouseLeave={() => setIsSignatureSectionEdit(false)}
            isEdit={isSignatureSectionEdit}
            isEditMode={signatureSectionEditMode}
          >
            {!signatureSectionEditMode && (
              <div>
                {isSm && (
                  <MobileSignatureWrapper>
                    <MobileSinatureTitle>{simpleTitleData[1]?.title}</MobileSinatureTitle>
                    <MobileSignatureDescription>{simpleTitleData[1]?.content}</MobileSignatureDescription>
                  </MobileSignatureWrapper>
                )}
                <SimpleSection style={{ margin: '11rem 0 0' }}>
                  <SimpleTitleWrapper>
                    <SimpleTitle>{simpleTitleData[1]?.title}</SimpleTitle>
                  </SimpleTitleWrapper>
                  <SimpleParagraph style={{ textAlign: 'left' }}>{simpleTitleData[1]?.content}</SimpleParagraph>
                </SimpleSection>
                <AlbumPriceWrapper
                  style={{ marginTop: 0, padding: '4.1rem 0 3.5rem', borderBottom: '1px solid #b9b9b9' }}
                >
                  {!isXs && (
                    <AlbumMarginPriceImage src={priceDetailProductData[2]?.img_url} alt="눈부신 일상 앨범 이미지" />
                  )}
                  {isXs && (
                    <AlbumFordablePriceImage src={priceDetailProductData[2]?.img_url} alt="눈부신 일상 앨범 이미지" />
                  )}
                  <SignatureAlbumDescriptionWrapper>
                    <AlbumDescriptionTitleWrapper>
                      <AlbumDescritionSubTitle>{priceDetailProductData[2]?.title}</AlbumDescritionSubTitle>
                      <AlbumDescritionTitle>{priceDetailProductData[2]?.content}</AlbumDescritionTitle>
                    </AlbumDescriptionTitleWrapper>
                    <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[2]?.album || '' }} />
                  </SignatureAlbumDescriptionWrapper>
                </AlbumPriceWrapper>
                {isLg && !isSm && (
                  <AlbumPriceWrapper
                    style={{
                      backgroundColor: '#FAFAFA',
                      marginTop: 0,
                      padding: '4rem 0 3.5rem',
                      borderBottom: '1px solid #b9b9b9',
                      boxSizing: 'content-box',
                    }}
                  >
                    <AlbumLeftDescriptionWrapper style={{ marginLeft: '1.5rem' }}>
                      <AlbumDescriptionTitleWrapper>
                        <AlbumDescritionSubTitle>{priceDetailProductData[3]?.title}</AlbumDescritionSubTitle>
                        <AlbumDescritionTitle>{priceDetailProductData[3]?.content}</AlbumDescritionTitle>
                      </AlbumDescriptionTitleWrapper>
                      <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[3]?.album || '' }} />
                    </AlbumLeftDescriptionWrapper>
                    <AlbumPriceImage src={priceDetailProductData[3]?.img_url} alt="빛나는 일상 앨범 이미지" />
                  </AlbumPriceWrapper>
                )}
                {isSm && (
                  <AlbumPriceWrapper
                    style={{
                      marginTop: 0,
                      padding: '4rem 0 3.5rem',
                      borderBottom: '1px solid #b9b9b9',
                      boxSizing: 'content-box',
                    }}
                  >
                    {isSm && !isXs && (
                      <AlbumMarginPriceImage src={priceDetailProductData[3]?.img_url} alt="빛나는 일상 앨범 이미지" />
                    )}
                    {isXs && (
                      <AlbumFordablePriceImage src={priceDetailProductData[3]?.img_url} alt="빛나는 일상 앨범 이미지" />
                    )}
                    <SignatureAlbumDescriptionWrapper>
                      <AlbumDescriptionTitleWrapper>
                        <AlbumDescritionSubTitle>{priceDetailProductData[3]?.title}</AlbumDescritionSubTitle>
                        <AlbumDescritionTitle>{priceDetailProductData[3]?.content}</AlbumDescritionTitle>
                      </AlbumDescriptionTitleWrapper>
                      <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[3]?.album || '' }} />
                    </SignatureAlbumDescriptionWrapper>
                  </AlbumPriceWrapper>
                )}
                {isLg && !isSm && (
                  <ChoiceSection style={{ backgroundColor: '#fafafa' }}>
                    <ChoiceDescription>
                      <PriceTitleBold>* {priceTitleData[2]?.title}</PriceTitleBold> - {priceTitleData[2]?.content}
                    </ChoiceDescription>
                  </ChoiceSection>
                )}
                {isSm && !isXs && (
                  <ChoiceSection>
                    <ChoiceDescription>
                      <PriceTitleBold>* {priceTitleData[2]?.title}</PriceTitleBold>
                      <br /> {priceTitleData[2]?.content}
                    </ChoiceDescription>
                  </ChoiceSection>
                )}
                {isXs && (
                  <ChoiceSection>
                    <ChoiceDescription>
                      <PriceTitleBold>* {priceTitleData[2]?.title}</PriceTitleBold>
                      <br /> {priceTitleData[2]?.content}
                    </ChoiceDescription>
                  </ChoiceSection>
                )}
              </div>
            )}
            {isSignatureSectionEdit && !signatureSectionEditMode && (
              <EditButton onClick={() => setSignatureSectionEditMode(true)}>수정</EditButton>
            )}

            {/* 시그니처 성장 수정 모드 */}
            {signatureSectionEditMode && (
              <div style={{ width: '100%', marginTop: '5rem' }}>
                <DescriptionSpan>
                  기존 패키지 상품의 정렬대로 수정하시고, 바꾸고 싶은 컨텐츠만 수정하시면 됩니다.
                </DescriptionSpan>

                <Form
                  layout="vertical"
                  onFinish={(data) => handlePostProductTitle(2, data, 'section2')}
                  style={{
                    marginTop: '3rem',
                  }}
                >
                  <Form.Item
                    label="두번째 섹션 미니 타이틀"
                    name="title"
                    initialValue={simpleTitleData[1]?.title || ''}
                  >
                    <Input placeholder="시그니처 성장" autoComplete="off" />
                  </Form.Item>
                  <Form.Item
                    label="두번째 섹션 미니 컨텐츠"
                    name="content"
                    initialValue={simpleTitleData[1]?.content || ''}
                  >
                    <MiniTitleTextEditor
                      className="form-control text-editor"
                      theme="snow"
                      placeholder="ex) 백일 2테마 + 돌 2테마(독사진)"
                      modules={modules}
                      formats={formats}
                      value={editorValue}
                      onChange={(content, delta, source, editor) => setEditorValue(editor.getHTML())}
                      style={{ width: '100%' }}
                      key={key}
                    />
                  </Form.Item>

                  <SimpleSectionEditButtonWrapper>
                    <SimpleSectionEditButton htmlType="submit">저장</SimpleSectionEditButton>
                  </SimpleSectionEditButtonWrapper>
                </Form>

                <DivideLine style={{ width: '100%' }} />

                <Form
                  layout="vertical"
                  onFinish={(data) => handlePostDetailProduct(data, 3)}
                  style={{ display: 'flex', flexDirection: 'column', marginTop: '3rem' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Item name="img_url" initialValue={priceDetailProductData[2]?.img_url || ''}>
                      <CreateImage
                        src={
                          firstFileUrl
                            ? firstFileUrl
                            : priceDetailProductData[2]?.img_url
                            ? priceDetailProductData[2].img_url
                            : image
                        }
                      />
                      <ImageCreateInput type="file" ref={imgRef} accept="image/*" onChange={onChangeSingleFile(1)} />
                      <DescriptionSpan style={{ fontSize: '1.2rem' }}>
                        [유의 사항] 올리신 이미지는 자동으로 360x275 사이즈로 맞춰집니다.
                      </DescriptionSpan>
                    </Form.Item>

                    <div>
                      <Form.Item
                        label="패키지 상품 미니 타이틀"
                        name="title"
                        initialValue={priceDetailProductData[2]?.title || ''}
                      >
                        <Input placeholder="시그니처 성장" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 타이틀"
                        name="content"
                        initialValue={priceDetailProductData[2]?.content || ''}
                      >
                        <Input placeholder="꿈꾸는 일상" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 설명"
                        name="album"
                        initialValue={priceDetailProductData[2]?.album || ''}
                      >
                        <TextEditor
                          className="form-control text-editor"
                          theme="snow"
                          placeholder="ex) 백일 2테마 + 돌 2테마(독사진)"
                          modules={modules}
                          formats={formats}
                          value={editorValue}
                          onChange={(content, delta, source, editor) => setEditorValue(editor.getHTML())}
                          style={{ width: '100%' }}
                          key={key}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <SimpleSectionEditButtonWrapper style={{ display: 'flex', justifyContent: 'right' }}>
                    <SimpleSectionEditButton htmlType="submit">저장</SimpleSectionEditButton>
                  </SimpleSectionEditButtonWrapper>
                </Form>

                <DivideLine style={{ width: '100%' }} />

                <Form
                  layout="vertical"
                  onFinish={(data) => handlePostDetailProduct(data, 4)}
                  style={{ display: 'flex', flexDirection: 'column', marginTop: '3rem' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <Form.Item
                        label="패키지 상품 미니 타이틀"
                        name="title"
                        initialValue={priceDetailProductData[3]?.title || ''}
                      >
                        <Input placeholder="시그니처 성장" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 타이틀"
                        name="content"
                        initialValue={priceDetailProductData[3]?.content || ''}
                      >
                        <Input placeholder="빛나는 일상" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 설명"
                        name="album"
                        initialValue={priceDetailProductData[3]?.album || ''}
                      >
                        <TextEditor
                          className="form-control text-editor"
                          theme="snow"
                          placeholder="ex) 백일 2테마 + 돌 2테마(독사진)"
                          modules={modules}
                          formats={formats}
                          value={editorValue}
                          onChange={(content, delta, source, editor) => setEditorValue(editor.getHTML())}
                          style={{ width: '100%' }}
                          key={key}
                        />
                      </Form.Item>
                    </div>

                    <Form.Item name="img_url" initialValue={priceDetailProductData[3]?.img_url || ''}>
                      <CreateImage
                        src={
                          secondeFileUrl
                            ? secondeFileUrl
                            : priceDetailProductData[3]?.img_url
                            ? priceDetailProductData[3].img_url
                            : image
                        }
                      />
                      <ImageCreateInput type="file" ref={imgRef} accept="image/*" onChange={onChangeSingleFile(2)} />
                      <DescriptionSpan style={{ fontSize: '1.2rem' }}>
                        [유의 사항] 올리신 이미지는 자동으로 360x275 사이즈로 맞춰집니다.
                      </DescriptionSpan>
                    </Form.Item>
                  </div>

                  <SimpleSectionEditButtonWrapper style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
                    <SimpleSectionEditButton htmlType="submit">저장</SimpleSectionEditButton>
                  </SimpleSectionEditButtonWrapper>
                </Form>

                <DivideLine style={{ width: '100%' }} />

                <Form
                  layout="vertical"
                  onFinish={(data) => handlePostPriceTitle(data, 3)}
                  style={{
                    marginTop: '3rem',
                  }}
                >
                  <Form.Item
                    label="두번째 섹션 하단 타이틀(* 표시는 빼셔도 됩니다)"
                    name="title"
                    initialValue={simpleTitleData[3]?.title || ''}
                  >
                    <Input placeholder="눈부신 초이스" autoComplete="off" />
                  </Form.Item>
                  <Form.Item
                    label="두번째 섹션 하단 컨텐츠(- 표시는 빼셔도 됩니다)"
                    name="content"
                    initialValue={simpleTitleData[3]?.content || ''}
                  >
                    <Input placeholder="백일상｜복고의자｜몬나니｜인형친구" autoComplete="off" />
                  </Form.Item>

                  <SimpleSectionEditButtonWrapper style={{ width: '100%' }}>
                    <SimpleSectionCancelButton onClick={handlePriceSignatureSectionCancel}>
                      취소
                    </SimpleSectionCancelButton>
                    <SimpleSectionEditButton htmlType="submit">저장</SimpleSectionEditButton>
                  </SimpleSectionEditButtonWrapper>
                </Form>
              </div>
            )}
          </SignatureContainer>

          {/* 스페셜 성장 */}
          <SpecitalContainerBox
            onMouseEnter={() => setIsSpecialSectionEdit(true)}
            onMouseLeave={() => setIsSpecialSectionEdit(false)}
            isEdit={isSpecialSectionEdit}
            isEditMode={specialSectionEditMode}
          >
            {!specialSectionEditMode && (
              <div>
                <SpecialContainer>
                  {isLg && !isSm && (
                    <SimpleSection style={{ marginBottom: 0 }}>
                      <SimpleTitleWrapper>
                        <SimpleTitle>{simpleTitleData[2]?.title}</SimpleTitle>
                      </SimpleTitleWrapper>
                      <SimpleParagraph>{simpleTitleData[2]?.content}</SimpleParagraph>
                    </SimpleSection>
                  )}
                  {isSm && (
                    <MobileSpecialWrapper>
                      <MobileSpecialTitle>{simpleTitleData[2]?.title}</MobileSpecialTitle>
                      <MobileSpecialDescription>{simpleTitleData[2]?.content}</MobileSpecialDescription>
                    </MobileSpecialWrapper>
                  )}
                  {isLg && !isSm && (
                    <SpecialAlubmPriceWrapper>
                      <AlbumMarginPriceImage src={priceDetailProductData[4]?.img_url} alt="우리의 일상 앨범 이미지" />
                      <AlbumLeftDescriptionWrapper>
                        <AlbumDescriptionTitleWrapper>
                          <AlbumDescritionSubTitle>{priceDetailProductData[4]?.title}</AlbumDescritionSubTitle>
                          <AlbumDescritionTitle>{priceDetailProductData[4]?.content}</AlbumDescritionTitle>
                        </AlbumDescriptionTitleWrapper>

                        <StyledDescription
                          dangerouslySetInnerHTML={{ __html: priceDetailProductData[4]?.album || '' }}
                        />
                      </AlbumLeftDescriptionWrapper>
                    </SpecialAlubmPriceWrapper>
                  )}
                  {isSm && (
                    <SpecialAlubmPriceWrapper
                      style={{
                        padding: '0 0 3.5rem',
                      }}
                    >
                      {isSm && !isXs && (
                        <AlbumMarginPriceImage src={priceDetailProductData[4]?.img_url} alt="우리의 일상 앨범 이미지" />
                      )}
                      {isXs && (
                        <AlbumFordablePriceImage
                          src={priceDetailProductData[4]?.img_url}
                          alt="우리의 일상 앨범 이미지"
                        />
                      )}
                      <AlbumDescritionWrapper>
                        <AlbumDescriptionTitleWrapper>
                          <AlbumDescritionSubTitle>{priceDetailProductData[4]?.title}</AlbumDescritionSubTitle>
                          <AlbumDescritionTitle>{priceDetailProductData[4]?.content}</AlbumDescritionTitle>
                        </AlbumDescriptionTitleWrapper>

                        <StyledDescription
                          dangerouslySetInnerHTML={{ __html: priceDetailProductData[4]?.album || '' }}
                        />
                      </AlbumDescritionWrapper>
                    </SpecialAlubmPriceWrapper>
                  )}
                  {isLg && !isSm && (
                    <>
                      <DivideLine />
                      <SpecialAlubmPriceWrapper style={{ borderBottom: '1px solid #b9b9b9', padding: '4rem 0 3.5rem' }}>
                        <AlbumLeftDescriptionWrapper>
                          <AlbumDescriptionTitleWrapper>
                            <AlbumDescritionSubTitle>{priceDetailProductData[5]?.title}</AlbumDescritionSubTitle>
                            <AlbumDescritionTitle>{priceDetailProductData[5]?.content}</AlbumDescritionTitle>
                          </AlbumDescriptionTitleWrapper>

                          <StyledDescription
                            dangerouslySetInnerHTML={{ __html: priceDetailProductData[5]?.album || '' }}
                          />
                        </AlbumLeftDescriptionWrapper>
                        <AlbumPriceImage src={priceDetailProductData[5]?.img_url} alt="눈부신 일상 앨범 이미지" />
                      </SpecialAlubmPriceWrapper>
                    </>
                  )}
                  {isSm && (
                    <SpecialAlubmPriceWrapper
                      style={{
                        width: '32rem',
                        borderBottom: '1px solid #b9b9b9',
                        margin: '0 auto',
                        marginTop: '5rem',
                      }}
                    >
                      {isSm && !isXs && (
                        <AlbumMarginPriceImage src={priceDetailProductData[5]?.img_url} alt="눈부신 일상 앨범 이미지" />
                      )}
                      {isXs && (
                        <AlbumFordablePriceImage
                          src={priceDetailProductData[5]?.img_url}
                          alt="눈부신 일상 앨범 이미지"
                        />
                      )}
                      <AlbumDescritionWrapper>
                        <AlbumDescriptionTitleWrapper>
                          <AlbumDescritionSubTitle>{priceDetailProductData[5]?.title}</AlbumDescritionSubTitle>
                          <AlbumDescritionTitle>{priceDetailProductData[5]?.content}</AlbumDescritionTitle>
                        </AlbumDescriptionTitleWrapper>

                        <StyledDescription
                          dangerouslySetInnerHTML={{ __html: priceDetailProductData[5]?.album || '' }}
                        />
                      </AlbumDescritionWrapper>
                    </SpecialAlubmPriceWrapper>
                  )}
                </SpecialContainer>
                {isLg && !isSm && (
                  <ChoiceSection style={{ padding: '1.6rem 2rem' }}>
                    <ChoiceDescription style={{ fontSize: '1.1rem' }}>
                      <PriceTitleBold>* {priceTitleData[3]?.title}</PriceTitleBold> - {priceTitleData[3]?.content}
                    </ChoiceDescription>
                    <br />
                    <ChoiceDescription style={{ fontSize: '1.1rem' }}>
                      <PriceTitleBold>** {priceTitleData[3]?.event}</PriceTitleBold> -{priceTitleData[3]?.event_content}
                      <br />
                    </ChoiceDescription>
                  </ChoiceSection>
                )}
                {isSm && !isXs && (
                  <ChoiceSection>
                    <ChoiceDescription style={{ fontSize: '1.2rem' }}>
                      <PriceTitleBold>* {priceTitleData[3]?.title}</PriceTitleBold> - <br />
                      {priceTitleData[3]?.content}
                    </ChoiceDescription>
                    <br />
                    <ChoiceDescription style={{ fontSize: '1.2rem' }}>
                      <PriceTitleBold>** {priceTitleData[3]?.event}</PriceTitleBold> - <br />
                      {priceTitleData[3]?.event_content}
                    </ChoiceDescription>
                  </ChoiceSection>
                )}
                {isXs && (
                  <ChoiceSection>
                    <ChoiceDescription style={{ fontSize: '1.2rem' }}>
                      <PriceTitleBold>* {priceTitleData[3]?.title}</PriceTitleBold> - <br />
                      {priceTitleData[3]?.content}
                    </ChoiceDescription>
                    <br />
                    <ChoiceDescription style={{ fontSize: '1.2rem' }}>
                      <PriceTitleBold>** {priceTitleData[3]?.event}</PriceTitleBold> - <br />
                      {priceTitleData[3]?.event_content}
                    </ChoiceDescription>
                  </ChoiceSection>
                )}
              </div>
            )}
            {isSpecialSectionEdit && !specialSectionEditMode && (
              <EditButton onClick={() => setSpecialSectionEditMode(true)}>수정</EditButton>
            )}

            {/* 스페셜 성장 수정 모드 */}
            {specialSectionEditMode && (
              <div style={{ width: '100%', marginTop: '5rem' }}>
                <DescriptionSpan>
                  기존 패키지 상품의 정렬대로 수정하시고, 바꾸고 싶은 컨텐츠만 수정하시면 됩니다.
                </DescriptionSpan>

                <Form
                  layout="vertical"
                  onFinish={(data) => handlePostProductTitle(3, data, 'section3')}
                  style={{
                    marginTop: '3rem',
                  }}
                >
                  <Form.Item
                    label="세번째 섹션 미니 타이틀"
                    name="title"
                    initialValue={simpleTitleData[2]?.title || ''}
                  >
                    <Input placeholder="스페셜 성장" autoComplete="off" />
                  </Form.Item>

                  {/* TODO : api 500 */}
                  <Form.Item
                    label="세번째 섹션 미니 컨텐츠"
                    name="content"
                    initialValue={simpleTitleData[2]?.content || ''}
                  >
                    <MiniTitleTextEditor
                      className="form-control text-editor"
                      theme="snow"
                      placeholder="ex) 백일 2테마 + 돌 2테마(독사진)"
                      modules={modules}
                      formats={formats}
                      value={editorValue}
                      onChange={(content, delta, source, editor) => setEditorValue(editor.getHTML())}
                      style={{ width: '100%' }}
                      key={key}
                    />
                  </Form.Item>

                  <SimpleSectionEditButtonWrapper style={{ width: '100%' }}>
                    <SimpleSectionEditButton htmlType="submit">저장</SimpleSectionEditButton>
                  </SimpleSectionEditButtonWrapper>
                </Form>

                <DivideLine style={{ width: '100%' }} />

                <Form
                  layout="vertical"
                  onFinish={(data) => handlePostDetailProduct(data, 5)}
                  style={{ display: 'flex', flexDirection: 'column', marginTop: '3rem' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Item name="img_url" initialValue={priceDetailProductData[4]?.img_url || ''}>
                      <CreateImage
                        src={
                          firstFileUrl
                            ? firstFileUrl
                            : priceDetailProductData[4]?.img_url
                            ? priceDetailProductData[4].img_url
                            : image
                        }
                      />
                      <ImageCreateInput type="file" ref={imgRef} accept="image/*" onChange={onChangeSingleFile(1)} />
                      <DescriptionSpan style={{ fontSize: '1.2rem' }}>
                        [유의 사항] 올리신 이미지는 자동으로 360x275 사이즈로 맞춰집니다.
                      </DescriptionSpan>
                    </Form.Item>

                    <div>
                      <Form.Item
                        label="패키지 상품 미니 타이틀"
                        name="title"
                        initialValue={priceDetailProductData[4]?.title || ''}
                      >
                        <Input placeholder="스페셜 성장" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 타이틀"
                        name="content"
                        initialValue={priceDetailProductData[4]?.content || ''}
                      >
                        <Input placeholder="꿈꾸는 일상" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 설명"
                        name="album"
                        initialValue={priceDetailProductData[4]?.album || ''}
                      >
                        <TextEditor
                          className="form-control text-editor"
                          theme="snow"
                          placeholder="ex) 백일 2테마 + 돌 2테마(독사진)"
                          modules={modules}
                          formats={formats}
                          value={editorValue}
                          onChange={(content, delta, source, editor) => setEditorValue(editor.getHTML())}
                          style={{ width: '100%' }}
                          key={key}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <SimpleSectionEditButtonWrapper style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
                    <SimpleSectionEditButton htmlType="submit">저장</SimpleSectionEditButton>
                  </SimpleSectionEditButtonWrapper>
                </Form>

                <DivideLine style={{ width: '100%' }} />

                <Form
                  layout="vertical"
                  onFinish={(data) => handlePostDetailProduct(data, 6)}
                  style={{ display: 'flex', flexDirection: 'column', marginTop: '3rem' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <Form.Item
                        label="패키지 상품 미니 타이틀"
                        name="title"
                        initialValue={priceDetailProductData[5]?.title || ''}
                      >
                        <Input placeholder="스페셜 성장" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 타이틀"
                        name="content"
                        initialValue={priceDetailProductData[5]?.content || ''}
                      >
                        <Input placeholder="빛나는 일상" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 설명"
                        name="album"
                        initialValue={priceDetailProductData[5]?.album || ''}
                      >
                        <TextEditor
                          className="form-control text-editor"
                          theme="snow"
                          placeholder="ex) 백일 2테마 + 돌 2테마(독사진)"
                          modules={modules}
                          formats={formats}
                          value={editorValue}
                          onChange={(content, delta, source, editor) => setEditorValue(editor.getHTML())}
                          style={{ width: '100%' }}
                          key={key}
                        />
                      </Form.Item>
                    </div>

                    <Form.Item name="img_url" initialValue={priceDetailProductData[5]?.img_url || ''}>
                      <CreateImage
                        src={
                          secondeFileUrl
                            ? secondeFileUrl
                            : priceDetailProductData[5]?.img_url
                            ? priceDetailProductData[5].img_url
                            : image
                        }
                      />
                      <ImageCreateInput type="file" ref={imgRef} accept="image/*" onChange={onChangeSingleFile(2)} />
                      <DescriptionSpan style={{ fontSize: '1.2rem' }}>
                        [유의 사항] 올리신 이미지는 자동으로 360x275 사이즈로 맞춰집니다.
                      </DescriptionSpan>
                    </Form.Item>
                  </div>

                  <SimpleSectionEditButtonWrapper style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
                    <SimpleSectionEditButton htmlType="submit">저장</SimpleSectionEditButton>
                  </SimpleSectionEditButtonWrapper>
                </Form>

                <DivideLine style={{ width: '100%' }} />

                <Form
                  layout="vertical"
                  onFinish={(data) => handlePostPriceTitle(data, 4)}
                  style={{
                    marginTop: '3rem',
                  }}
                >
                  <Form.Item
                    label="세번째 섹션 하단 타이틀(* 표시는 빼셔도 됩니다)"
                    name="title"
                    initialValue={priceTitleData[3]?.title || ''}
                  >
                    <Input placeholder="눈부신 초이스" autoComplete="off" />
                  </Form.Item>
                  <Form.Item
                    label="세번째 섹션 하단 컨텐츠(- 표시는 빼셔도 됩니다)"
                    name="content"
                    initialValue={priceTitleData[3]?.content || ''}
                  >
                    <Input placeholder="백일상｜복고의자｜몬나니｜인형친구" autoComplete="off" />
                  </Form.Item>

                  <DivideLine style={{ width: '100%', margin: '2rem 0' }} />

                  <Form.Item
                    label="세번째 섹션 하단 타이틀(** 표시는 빼셔도 됩니다)"
                    name="event"
                    initialValue={priceTitleData[3]?.event || ''}
                  >
                    <Input placeholder="스페셜초이스" autoComplete="off" />
                  </Form.Item>
                  <Form.Item
                    label="세번째 섹션 하단 컨텐츠(- 표시는 빼셔도 됩니다)"
                    name="event_content"
                    initialValue={priceTitleData[3]?.event_content || ''}
                  >
                    <Input
                      placeholder="돌잔치스냅｜대가족 6인 미만 1테마｜리마인드웨딩 1테마(헤메 포함)"
                      autoComplete="off"
                    />
                  </Form.Item>

                  <SimpleSectionEditButtonWrapper style={{ width: '100%' }}>
                    <SimpleSectionCancelButton onClick={handlePriceSpecialSectionCancel}>
                      취소
                    </SimpleSectionCancelButton>
                    <SimpleSectionEditButton htmlType="submit">저장</SimpleSectionEditButton>
                  </SimpleSectionEditButtonWrapper>
                </Form>
              </div>
            )}
          </SpecitalContainerBox>

          {/* 베이직 단품 */}
          <BasicAlbumContainer
            onMouseEnter={() => setIsBasicSectionEdit(true)}
            onMouseLeave={() => setIsBasicSectionEdit(false)}
            isEdit={isBasicSectionEdit}
            isEditMode={basicSectionEditMode}
          >
            {!basicSectionEditMode && (
              <div>
                {isLg && !isSm && (
                  <BasicAlbumPriceWrapper>
                    <BasicImage src={priceDetailProductData[6]?.img_url} alt="베이직 단품 앨범 이미지" />
                    <BasicDescriptionWrapper>
                      <MobileBasicAlbumWrapper style={{ margin: 0 }}>
                        <AlbumDescriptionTitleWrapper>
                          <AlbumDescritionSubTitle>{priceDetailProductData[6]?.title}</AlbumDescritionSubTitle>
                          <AlbumDescritionTitle>{priceDetailProductData[6]?.content}</AlbumDescritionTitle>
                        </AlbumDescriptionTitleWrapper>
                    
                      <StyledDescription dangerouslySetInnerHTML={{ __html: priceDetailProductData[6]?.album || '' }} />
                      <AlbumDescritionWrapper>
                        <AlbumDescriptionTitleWrapper>
                          <AlbumDescritionSubTitle>{priceDetailProductData[7]?.title}</AlbumDescritionSubTitle>
                          <AlbumDescritionTitle>{priceDetailProductData[7]?.content}</AlbumDescritionTitle>
                        </AlbumDescriptionTitleWrapper>
                        <StyledDescription
                          dangerouslySetInnerHTML={{ __html: priceDetailProductData[7]?.album || '' }}
                        />
                      </AlbumDescritionWrapper>
                      <AlbumDescritionWrapper>
                        <AlbumDescriptionTitleWrapper>
                          <AlbumDescritionSubTitle>{priceDetailProductData[8]?.title}</AlbumDescritionSubTitle>
                          <AlbumDescritionTitle>{priceDetailProductData[8]?.content}</AlbumDescritionTitle>
                        </AlbumDescriptionTitleWrapper>
                        <StyledDescription
                          dangerouslySetInnerHTML={{ __html: priceDetailProductData[8]?.album || '' }}
                        />
                      </AlbumDescritionWrapper>
                      </MobileBasicAlbumWrapper>
                    </BasicDescriptionWrapper>
                  </BasicAlbumPriceWrapper>
                )}
                {isSm && !isXs && (
                  <BasicAlbumPriceWrapper style={{ border: 'none' }}>
                    <BasicImage src={priceDetailProductData[6]?.img_url} alt="베이직 단품 앨범 이미지" />
                    <BasicDescriptionWrapper>
                      <MobileBasicAlbumWrapper>
                        <AlbumDescriptionTitleWrapper>
                          <AlbumDescritionSubTitle>{priceDetailProductData[6]?.title}</AlbumDescritionSubTitle>
                          <AlbumDescritionTitle>{priceDetailProductData[6]?.content}</AlbumDescritionTitle>
                        </AlbumDescriptionTitleWrapper>
                        <StyledDescription
                          dangerouslySetInnerHTML={{ __html: priceDetailProductData[6]?.album || '' }}
                        />
                      
                      <AlbumDescritionWrapper style={{ padding: 0 }}>
                        <AlbumDescriptionTitleWrapper>
                          <AlbumDescritionSubTitle>{priceDetailProductData[7]?.title}</AlbumDescritionSubTitle>
                          <AlbumDescritionTitle>{priceDetailProductData[7]?.content}</AlbumDescritionTitle>
                        </AlbumDescriptionTitleWrapper>
                        <StyledDescription
                          dangerouslySetInnerHTML={{ __html: priceDetailProductData[7]?.album || '' }}
                        />
                      </AlbumDescritionWrapper>
                      <AlbumDescritionWrapper style={{ padding: 0 }}>
                        <AlbumDescriptionTitleWrapper>
                          <AlbumDescritionSubTitle>{priceDetailProductData[8]?.title}</AlbumDescritionSubTitle>
                          <AlbumDescritionTitle>{priceDetailProductData[8]?.content}</AlbumDescritionTitle>
                        </AlbumDescriptionTitleWrapper>
                        <StyledDescription
                          dangerouslySetInnerHTML={{ __html: priceDetailProductData[8]?.album || '' }}
                        />
                      </AlbumDescritionWrapper>
                      </MobileBasicAlbumWrapper>
                    </BasicDescriptionWrapper>
                  </BasicAlbumPriceWrapper>
                )}
                {isXs && (
                  <BasicAlbumPriceWrapper style={{ border: 'none' }}>
                    <BasicImage src={priceDetailProductData[6]?.img_url} alt="베이직 단품 앨범 이미지" />
                    <BasicDescriptionWrapper>
                      <MobileBasicAlbumWrapper>
                        <AlbumDescriptionTitleWrapper>
                          <AlbumDescritionSubTitle>{priceDetailProductData[6]?.title}</AlbumDescritionSubTitle>
                          <AlbumDescritionTitle>{priceDetailProductData[6]?.content}</AlbumDescritionTitle>
                        </AlbumDescriptionTitleWrapper>
                        <StyledDescription
                          dangerouslySetInnerHTML={{ __html: priceDetailProductData[6]?.album || '' }}
                        />
                      </MobileBasicAlbumWrapper>
                      <AlbumDescritionWrapper style={{ padding: 0 }}>
                        <AlbumDescriptionTitleWrapper>
                          <AlbumDescritionSubTitle>{priceDetailProductData[6]?.title}</AlbumDescritionSubTitle>
                          <AlbumDescritionTitle>{priceDetailProductData[6]?.content}</AlbumDescritionTitle>
                        </AlbumDescriptionTitleWrapper>
                        <StyledDescription
                          dangerouslySetInnerHTML={{ __html: priceDetailProductData[6]?.album || '' }}
                        />
                      </AlbumDescritionWrapper>
                    </BasicDescriptionWrapper>
                  </BasicAlbumPriceWrapper>
                )}
              </div>
            )}
            {isBasicSectionEdit && !basicSectionEditMode && (
              <EditButton onClick={() => setBasicSectionEditMode(true)}>수정</EditButton>
            )}
            {/* 베이직 단품 수정 모드 */}
            {basicSectionEditMode && (
              <>
                <DescriptionSpan>
                  기존 패키지 상품의 정렬대로 수정하시고, 바꾸고 싶은 컨텐츠만 수정하시면 됩니다. <br />
                  (마지막 섹션의 패키지 상품은 이미지가 하나만 추가됩니다.)
                </DescriptionSpan>

                <Form
                  layout="vertical"
                  onFinish={(data) => handlePostDetailProduct(data, 7)}
                  style={{ display: 'flex', flexDirection: 'column', marginTop: '3rem' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Item name="img_url" initialValue={priceDetailProductData[6]?.img_url || ''}>
                      <CreateImage
                        src={
                          firstFileUrl
                            ? firstFileUrl
                            : priceDetailProductData[6]?.img_url
                            ? priceDetailProductData[6].img_url
                            : image
                        }
                      />
                      <ImageCreateInput type="file" ref={imgRef} accept="image/*" onChange={onChangeSingleFile(1)} />
                      <DescriptionSpan style={{ fontSize: '1.2rem' }}>
                        [유의 사항] 올리신 이미지는 자동으로 360x275 사이즈로 맞춰집니다.
                      </DescriptionSpan>
                    </Form.Item>

                    <div>
                      <Form.Item
                        label="패키지 상품 미니 타이틀"
                        name="title"
                        initialValue={priceDetailProductData[6]?.title || ''}
                      >
                        <Input placeholder="심플 성장" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 타이틀"
                        name="content"
                        initialValue={priceDetailProductData[6]?.content || ''}
                      >
                        <Input placeholder="소소한 일상" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 설명"
                        name="album"
                        initialValue={priceDetailProductData[6]?.album || ''}
                      >
                        <TextEditor
                          className="form-control text-editor"
                          theme="snow"
                          placeholder="ex) 백일 2테마 + 돌 2테마a(독사진)"
                          modules={modules}
                          formats={formats}
                          value={editorValue}
                          onChange={(content, delta, source, editor) => setEditorValue(editor.getHTML())}
                          style={{ width: '100%' }}
                          key={key}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <SimpleSectionEditButtonWrapper style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
                    <SimpleSectionEditButton htmlType="submit">저장</SimpleSectionEditButton>
                  </SimpleSectionEditButtonWrapper>
                </Form>

                <DivideLine style={{ width: '100%' }} />

                <Form
                  layout="vertical"
                  onFinish={(data) => handlePostDetailProduct(data, 8)}
                  style={{ display: 'flex', flexDirection: 'column', marginTop: '3rem' }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div>
                      <Form.Item
                        label="패키지 상품 미니 타이틀"
                        name="title"
                        initialValue={priceDetailProductData[7]?.title || ''}
                      >
                        <Input placeholder="심플 성장" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 타이틀"
                        name="content"
                        initialValue={priceDetailProductData[7]?.content || ''}
                      >
                        <Input placeholder="소소한 일상" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 설명"
                        name="album"
                        initialValue={priceDetailProductData[7]?.album || ''}
                      >
                        <TextEditor
                          className="form-control text-editor"
                          theme="snow"
                          placeholder="ex) 백일 2테마 + 돌 2테마b(독사진)"
                          modules={modules}
                          formats={formats}
                          value={editorValue}
                          onChange={(content, delta, source, editor) => setEditorValue(editor.getHTML())}
                          style={{ width: '100%' }}
                          key={key}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <SimpleSectionEditButtonWrapper style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
                    <SimpleSectionCancelButton onClick={handlePriceBasicSectionCancel}>취소</SimpleSectionCancelButton>
                    <SimpleSectionEditButton htmlType="submit">저장</SimpleSectionEditButton>
                  </SimpleSectionEditButtonWrapper>
                </Form>

                <Form
                  layout="vertical"
                  onFinish={(data) => handlePostDetailProduct(data, 9)}
                  style={{ display: 'flex', flexDirection: 'column', marginTop: '3rem' }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div>
                      <Form.Item
                        label="패키지 상품 미니 타이틀"
                        name="title"
                        initialValue={priceDetailProductData[8]?.title || ''}
                      >
                        <Input placeholder="심플 성장" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 타이틀"
                        name="content"
                        initialValue={priceDetailProductData[8]?.content || ''}
                      >
                        <Input placeholder="소소한 일상" autoComplete="off" />
                      </Form.Item>

                      <Form.Item
                        label="패키지 상품 설명"
                        name="album"
                        initialValue={priceDetailProductData[8]?.album || ''}
                      >
                        <TextEditor
                          className="form-control text-editor"
                          theme="snow"
                          placeholder="ex) 백일 2테마 + 돌 2테마c(독사진)"
                          modules={modules}
                          formats={formats}
                          value={editorValue}
                          onChange={(content, delta, source, editor) => setEditorValue(editor.getHTML())}
                          style={{ width: '100%' }}
                          key={key}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <SimpleSectionEditButtonWrapper style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
                    <SimpleSectionCancelButton onClick={handlePriceBasicSectionCancel}>취소</SimpleSectionCancelButton>
                    <SimpleSectionEditButton htmlType="submit">저장</SimpleSectionEditButton>
                  </SimpleSectionEditButtonWrapper>
                </Form>
              </>
            )}
          </BasicAlbumContainer>

          {isLg && !isSm && !eventTitleEditMode && (
            <>
              <EventWrapper
                onMouseEnter={() => setIsEditEventTitle(true)}
                onMouseLeave={() => setIsEditEventTitle(false)}
                isEdit={isEditEventTitle}
                isEditMode={eventTitleEditMode}
              >
                <EventTitle>{priceTitleData[1]?.event}</EventTitle>
                <EventDescription dangerouslySetInnerHTML={{ __html: priceTitleData[1]?.event_content || '' }} />
              </EventWrapper>
              {isEditEventTitle && !eventTitleEditMode && (
                <FilmEventWrapper
                  onMouseEnter={() => setIsEditEventTitle(true)}
                  onMouseLeave={() => setIsEditEventTitle(false)}
                >
                  <FilmEventEditButton onClick={() => setEventTitleEditMode(true)}>수정</FilmEventEditButton>
                </FilmEventWrapper>
              )}
            </>
          )}

          {isSm && !isXs && !eventTitleEditMode && (
            <EventWrapper>
              <EventTitle>평일촬영 이벤트</EventTitle>
              <EventDescription>
                <AlbumDescriptionList>만삭 아버님 스타일링</AlbumDescriptionList>
                <AlbumDescriptionList>50일 파노라마 테마 추가 촬영</AlbumDescriptionList>
                <AlbumDescriptionList>100일/200일/돌 스케치 영상</AlbumDescriptionList>
                <br />
                <AlbumDescriptionList>
                  상품구매후 모든촬영 평일이벤트 미션성공시
                  <br /> 일상 굿즈 1종 선택 <br />
                  (여권케이스,아크릴블록,일상트리액자,텀블러, <br />
                  머그잔2p, ......)
                </AlbumDescriptionList>
              </EventDescription>
            </EventWrapper>
          )}
          {isXs && !eventTitleEditMode && (
            <EventWrapper>
              <EventTitle>평일촬영 이벤트</EventTitle>
              <EventDescription>
                <AlbumDescriptionList>만삭 아버님 스타일링</AlbumDescriptionList>
                <AlbumDescriptionList>50일 파노라마 테마 추가 촬영</AlbumDescriptionList>
                <AlbumDescriptionList>100일/200일/돌 스케치 영상</AlbumDescriptionList>
                <br />
                <AlbumDescriptionList>
                  상품구매후 모든촬영 평일이벤트 <br />
                  미션성공시
                </AlbumDescriptionList>
                <AlbumDescriptionList>
                  일상 굿즈 1종 선택 <br /> (여권케이스,아크릴블록, <br />
                  일상트리액자, 텀블러,
                  <br /> 머그잔2p, ......)
                </AlbumDescriptionList>
              </EventDescription>
            </EventWrapper>
          )}

          {/* event title 수정 모드 */}
          {eventTitleEditMode && (
            <EventTitleEditWrapper>
              <Form
                layout="vertical"
                initialValues={{ event: priceTitleData[1]?.event, event_content: priceTitleData[1]?.event_content }}
                onFinish={(data) => handlePostPriceTitle(data, 2)}
              >
                <Form.Item label="Event 타이틀" name="event">
                  <EventTitleEditor placeholder="Event 타이틀을 입력해주세요" />
                </Form.Item>
                <Form.Item label="Event 컨텐츠" name="event_content">
                  <EventContentEditor
                    className="form-control text-editor"
                    theme="snow"
                    placeholder="Event 컨텐츠를 입력해주세요"
                    modules={modules}
                    formats={formats}
                    value={editorValue}
                    onChange={(content, delta, source, editor) => setEditorValue(editor.getHTML())}
                    style={{ width: '100%' }}
                    key={key}
                  />
                </Form.Item>
                <EditButtonWrapper style={{ width: '100%' }}>
                  <CancelButton onClick={handleEventTitleCancel}>취소</CancelButton>
                  <SaveButton htmlType="submit">저장</SaveButton>
                </EditButtonWrapper>
              </Form>
            </EventTitleEditWrapper>
          )}
        </OuterWrapper>
      </Container>
    </>
  );
};

export default AdminPricePage;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;

  ${MEDIA_QUERY.sm} {
    margin: 0;
  }

  ${MEDIA_QUERY.xs} {
    padding: 0 1.5rem;
  }
`;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.sm} {
    width: 100%;
  }
`;

interface PriceTitleWrapperProps {
  isEdit: boolean;
  isEditMode: boolean;
}

const PriceTitleWrapper = styled.div<PriceTitleWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.isEdit &&
    !props.isEditMode &&
    css`
      opacity: 0.3;
    `}

  ${MEDIA_QUERY.lg} {
    width: 67.5rem;
    margin-bottom: 12rem;
    margin-top: -5rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 32rem;
    margin-bottom: 4rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 23rem;
    margin-bottom: 4rem;
  }
`;

const PriceTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.7rem;
  font-weight: 400;

  letter-spacing: 6.8px;
  color: #474747;

  ${MEDIA_QUERY.sm} {
    margin: 12.8rem 0 5.2rem;
    font-weight: 500;
    font-size: 1.2rem;
    letter-spacing: 2.6px;
  }

  ${MEDIA_QUERY.xs} {
    margin-bottom: 4rem;
  }
`;

const PriceParagraph = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: #1e1f18;
  line-height: 2.5rem;
  margin: 3.5rem 0 0;
  font-family: MapoFlowerIsland;

  ${MEDIA_QUERY.sm} {
    line-height: 1.8rem;
    margin: 0;
  }

  ${MEDIA_QUERY.xs} {
    font-size: 1.2rem;
  }
`;

const SimpleSection = styled.div`
display: flex;
justify-content: space-evenly;
align-items: center;
background-color: rgb(247, 246, 244);
padding:0px 0;
white-space: pre-wrap;

  ${MEDIA_QUERY.lg} {
    width: 65.1rem;
    height: 4.7rem;
    padding: 1rem;
    margin-bottom: 1.5rem;
  }

  ${MEDIA_QUERY.sm} {
    display: none;
  }
`;

const SimpleSectionOnlyOne = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: rgb(247, 246, 244);
  padding: 20px 30px;
  white-space: pre-wrap;

  ${MEDIA_QUERY.sm} {
   display: none;
  }
`;
const SimpleTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 12rem;
  border-right: 1px solid #707070;
`;

const SimpleTitle = styled.span`
  font-family: 'NanumSquareNeoBold';
  font-size: 1.2rem;
  line-height: 13px;
  letter-spacing: 2.2px;
  color: #424530;
`;

const SimpleParagraph = styled.p`
  font-family: 'NanumSquareNeoLight';
  font-size: 1.1rem;
  font-weight: 200;
  color: #424530;
  text-align: left;
  max-width: 320px;
  ${MEDIA_QUERY.lg} {
    line-height: 13px;
  }
`;

const AlbumPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${MEDIA_QUERY.lg} {
    max-width: 65rem;
    background-color: #fafafa;
  }

  ${MEDIA_QUERY.sm} {
    flex-direction: column;
    align-items: flex-start;
    width: 32rem;
    height: 100%;
    margin: 0 auto;
    border-bottom: 1px solid #b9b9b9;
  }
`;

const SpecialAlubmPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${MEDIA_QUERY.lg} {
    width: 68rem;
    background-color: #fff;
    padding: 4rem 0 3.5rem;
  }

  ${MEDIA_QUERY.sm} {
    flex-direction: column;
    align-items: flex-start;
    width: 32rem;
    height: 100%;
    margin: 0 auto;
    border-bottom: 1px solid #b9b9b9;
  }
`;

const AlbumMarginPriceImage = styled.img`
  ${MEDIA_QUERY.lg} {
    width: 36rem;
    height: 27.5rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 32rem;
    height: 21.5rem;
    margin: 0 auto;
    margin-bottom: 5.5rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 25rem;
    margin: 0 0 3.5rem;
  }
`;

const AlbumFordablePriceImage = styled.img`
  ${MEDIA_QUERY.xs} {
    width: 25rem;
    margin: 0 1rem 3.5rem;
  }
`;

const AlbumPriceImage = styled.img`
  ${MEDIA_QUERY.lg} {
    width: 36rem;
    height: 27.5rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 32rem;
    height: 21.5rem;
    margin: 0 auto;
  }
`;

const SimpleAlbumPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  white-space: pre-wrap;
  
  ${MEDIA_QUERY.lg} {
    width: 65rem;
    padding: 3.5rem 0;
  }

  ${MEDIA_QUERY.sm} {
    flex-direction: column;
    width: 32rem;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    border-bottom: 1px solid #b9b9b9;
    background-color: #fff;
    padding-bottom:3.5rem;
  }
`;

const AlbumDescritionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  ${MEDIA_QUERY.lg} {
  }

  ${MEDIA_QUERY.sm} {
    padding: 0 2.6rem;
    margin-right: 0;
  }

  ${MEDIA_QUERY.xs} {
    padding: 0 3.6rem;
  }
`;

const AlbumLeftDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;

  ${MEDIA_QUERY.lg} {
    margin-top: 1rem;
  }

  ${MEDIA_QUERY.sm} {
    padding: 0 2.6rem;
  }
`;

const SignatureAlbumDescriptionWrapper = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  white-space: pre-wrap;

  ${MEDIA_QUERY.lg} {
    margin: 1rem 3.5rem 0 2rem;
  }

  ${MEDIA_QUERY.xs} {
    margin: 1rem 5rem 0 4rem;
  }
`;

const AlbumDescriptionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  ${MEDIA_QUERY.lg} {
   // margin: 0 0 1.2rem 0rem;
  }

  ${MEDIA_QUERY.sm} {
    margin-bottom: 0.1rem;
  }
`;

const AlbumDescritionSubTitle = styled.span`
  font-family: 'NanumSquareLight';
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 2.2px;
  color: #424530;

  ${MEDIA_QUERY.lg} {
  //시안 변경으로 임시로 부제 가려놓음
    display:none;
    margin-bottom: 0.4rem;
  }
`;

const AlbumDescritionTitle = styled.span`
  font-family: 'NanumSquareNeoExtraBold';
  color: #424530;
  font-size: 1.6rem;
`;

const AlbumDescrition = styled.ul`
  color: #1e1f18;
  font-family: 'S-CoreDream-3Light';
  font-weight: 200;
  font-size: 1.2rem;
  white-space: pre-wrap;

  list-style-position: inside;
  padding-left: 0;
  
  strong{
    font-family: NanumSquareNeoExtraBold;
  }

  ${MEDIA_QUERY.lg} {
    line-height: 16px;
    letter-spacing: -0.2px;
    margin: 0;
    margin-bottom:20px;

    h3{
    margin-block-start: 0;
    }
  }
`;

const AlbumDescriptionList = styled.li`
  position: relative;
  list-style: none;
  padding-left: 0;
`;

const DivideLine = styled.div`
  ${MEDIA_QUERY.lg} {
    width: 65rem;
    border-top: 1px solid #b9b9b9;
    margin-top: 1.8rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 32rem;
    border: 0;
    margin: 0 auto;
    margin-bottom: 5rem;
  }
`;

const ChoiceSection = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #b9b9b9;

  ${MEDIA_QUERY.lg} {
    width: 68rem;
    padding: 1.4rem 2rem;
    margin-bottom: 11rem;
    line-height: 16px;
  }

  ${MEDIA_QUERY.sm} {
    width: 90%;
    max-width:320px;
    padding: 2.9rem 0rem;
    margin:0 auto;
  }
`;

const ChoiceDescription = styled.div`
  color: #424530;
  font-family: 'S-CoreDream-3Light';
  font-size: 1.2rem;
  font-weight: 200;
  word-break: keep-all;

  ${MEDIA_QUERY.sm} {
    font-size: 1.2rem;
    line-height: 10px;
    text-align: center;
  }
`;

const BasicAlbumPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${MEDIA_QUERY.lg} {
    width: 65rem;
  }

  ${MEDIA_QUERY.sm} {
    flex-direction: column;
    align-items: flex-start;
    width: 32rem;
    height: 100%;
    margin: 0 auto;
    border-bottom: 1px solid #b9b9b9;
  }
`;

const BasicImage = styled.img`
  ${MEDIA_QUERY.lg} {
    width: 25.5rem;
    height: 37rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 32rem;
    height: 48rem;
    margin: 11rem 0 5.5rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 26rem;
    margin: 9rem 0 5.5rem;
  }
`;

const BasicDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${MEDIA_QUERY.lg} {
    margin-right: 2.2rem;
  }

  ${MEDIA_QUERY.sm} {
    margin: 0 0 0 2rem;
  }

  ${MEDIA_QUERY.xs} {
    margin: 0 0 0 3rem;
  }
`;

interface EventWrapperProps {
  isEdit?: boolean;
  isEditMode?: boolean;
}

const EventWrapper = styled.div<EventWrapperProps>`
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;

  ${(props) =>
    props.isEdit &&
    !props.isEditMode &&
    css`
      opacity: 0.3;
    `}

  ${MEDIA_QUERY.lg} {
    width: 65rem;
    margin-top: 12rem;
    padding: 3.9rem 7.6rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 100vw;
    height: 23.1rem;
    padding: 3.4rem 5rem;
    margin-top: 6.9rem;
  }

  ${MEDIA_QUERY.xs} {
    height: 25rem;
  }
`;

const EventTitle = styled.span`
  font-family: 'NanumSquareNeoExtraBold';
  font-size: 1.4rem;
  letter-spacing: 2.8px;

  ${MEDIA_QUERY.lg} {
    line-height: 20px;
  }
`;

const EventDescription = styled.div`
  font-family: 'S-CoreDream-3Light';
  font-size: 1.2rem;
  letter-spacing: -0.2px;
  color: #1e1f18;
  white-space: pre-wrap;

  ${MEDIA_QUERY.lg} {
    line-height: 16px;
  }
`;

// 모바일용 섹션 설명
const MobileSignatureWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 4rem 0;
  background-color: #fafafa;
`;

const MobileSinatureTitle = styled.span`
  color: #424530;
  font-family: 'NanumSquareNeoBold';
  font-size: 1.2rem;
  letter-spacing: 2.6px;
  line-height: 1.4rem;
  text-align: center;
  margin-bottom: 2rem;
`;

const MobileSignatureDescription = styled.p`
  color: #424530;
  font-family: 'NanumSquareNeoLight';
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 2rem;
  text-align: center;
  margin: 0;
  ${MEDIA_QUERY.lg} {
    width:90%;
    max-width:320px;
    margin:0 auto;
  }
`;

interface SignatureContainerProps {
  isEdit: boolean;
  isEditMode: boolean;
}

const SignatureContainer = styled.div<SignatureContainerProps>`
  position: relative;

  ${(props) =>
    props.isEdit &&
    !props.isEditMode &&
    css`
      opacity: 0.3;
    `}

  ${MEDIA_QUERY.sm} {
    width: 100vw;
    background-color: #fafafa;
    margin-top: 5rem;
  }
`;

const SpecialContainer = styled.div`
  ${MEDIA_QUERY.sm} {
    width: 100vw;
  }
`;

const MobileSpecialWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 4rem 0;

  ${MEDIA_QUERY.sm} {
    padding-top: 8rem;
  }
`;

const MobileSpecialTitle = styled.span`
  color: #424530;
  font-family: 'NanumSquareNeoBold';
  font-size: 1.2rem;
  letter-spacing: 2.6px;
  line-height: 1.4rem;
  text-align: center;
  margin-bottom: 2rem;
`;

const MobileSpecialDescription = styled.p`
  color: #424530;
  font-family: 'NanumSquareNeoLight';
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 2rem;
  text-align: center;
  margin: 0;
  ${MEDIA_QUERY.lg} {
    width:90%;
    max-width:320px;
    margin:0 auto;
  }
`;

const MobileBasicAlbumWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2.6rem;

  ${MEDIA_QUERY.lg} {
    padding: 0;
  }
`;

const EditButton = styled.button`
  width: 5rem;
  height: 2.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  color: #fff;
  background-color: #000;
  opacity: 1;
  cursor: pointer;
`;

const TitleEditor = styled(Input)`
  font-family: Montserrat, sans-serif;
  font-size: 1.7rem;
  font-weight: 400;
  letter-spacing: 6.8px;
  color: #474747;
`;

const EditButtonWrapper = styled.div`
  width: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 2rem;
`;

const CancelButton = styled.button`
  width: 10rem;
  height: 3rem;
  border: 1px solid #000;
  margin-right: 2rem;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  border-radius:4px;
`;

const SaveButton = styled(Button)`
  width: 10rem;
  height: 3rem;
  border: none;
  color: #fff;
  background-color: #000;
  cursor: pointer;
  border-radius:4px;
`;

const FilmEventWrapper = styled.div`
  position: relative;
`;

const FilmEventEditButton = styled.button`
  width: 5rem;
  height: 2.5rem;
  position: absolute;
  top: -10rem;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  color: #fff;
  background-color: #000;
  opacity: 1;
  cursor: pointer;
`;

const EventTitleEditWrapper = styled.div`
  width: 100%;
  margin-top: 4rem;
`;

const EventTitleEditor = styled(Input)`
  font-family: NanumSquareNeoExtraBold;
  font-size: 1.4rem;
  letter-spacing: -0.8px;
`;

const EventContentEditor = styled(ReactQuill)`
  .ql-container {
    height: 30rem;
    font-family: S-CoreDream-3Light;
    font-size: 1.2rem;
    letter-spacing: -0.2px;
    color: rgb(30, 31, 24);
    white-space: pre-wrap;
  }
`;

interface SimpleSectionWapperProps {
  isEdit: boolean;
  isEditMode: boolean;
}

const SimpleSectionWrapper = styled.div<SimpleSectionWapperProps>`
  width: 100%;
  position: relative;
  ${(props) =>
    props.isEdit &&
    !props.isEditMode &&
    css`
      opacity: 0.3;
    `}
`;

const SimpleSectionEditButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 0 auto;
  margin-top: 2rem;
`;

const SimpleSectionEditButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 2.5rem;
  border: none;
  color: #fff;
  background-color: #000;
  opacity: 1;
  cursor: pointer;
`;

const SimpleSectionCancelButton = styled.button`
  width: 5rem;
  height: 3rem;
  border: 1px solid #000;
  margin-right: 1rem;
  color: #000;
  background-color: #fff;
  cursor: pointer;
`;

const DescriptionSpan = styled.span`
  color: #000;
  font-family: ArgentumSansRegular;
  font-weight: 700;
  font-size: 1.5rem;
`;

const CreateImage = styled.img`
  width: 32rem;
`;

const ImageCreateInput = styled.input`
  cursor: pointer;
`;

interface SpecitalContainerBoxProps {
  isEdit: boolean;
  isEditMode: boolean;
}

const SpecitalContainerBox = styled.div<SpecitalContainerBoxProps>`
  position: relative;

  ${(props) =>
    props.isEdit &&
    !props.isEditMode &&
    css`
      opacity: 0.3;
    `}
`;

interface BasicAlbumContainerProps {
  isEdit: boolean;
  isEditMode: boolean;
}

const BasicAlbumContainer = styled.div<BasicAlbumContainerProps>`
  position: relative;

  ${(props) =>
    props.isEdit &&
    !props.isEditMode &&
    css`
      opacity: 0.3;
    `}
`;

const StyledDescription = styled(AlbumDescrition)`
  p {
    margin: 0;
  }
`;

const TextEditor = styled(ReactQuill)`
  .ql-container {
    width: 70rem;
    height: 40rem;
    color: #1e1f18;
    font-family: 'Gowun Batang', serif;
    font-size: 1.2rem;
    line-height: 2.5rem;
    white-space: pre-wrap;
  }
`;

const MiniTitleTextEditor = styled(ReactQuill)`
  .ql-container {
    width: 100%;
    height: 10rem;
    color: #1e1f18;
    font-family: 'Gowun Batang', serif;
    font-size: 1.2rem;
    line-height: 2.5rem;
    white-space: pre-wrap;
  }
`;

const PriceTitleBold = styled.b`
  font-weight: 700;
`;
