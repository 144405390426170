import styled from 'styled-components';
import MEDIA_QUERY from '../../constants/mediaQuery';
import { Helmet } from 'react-helmet-async';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import { useAtom } from 'jotai';
import { isAdminModeAtom, tokenAtom } from '../../stores';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import client from '../../api/axios';
import { message } from 'antd';
import { checkValidationImage } from '../../utils';
import { PortfolioDataType, ResultsType } from './Portfolio/babyFamily';

const AdminHomePage = () => {
  const [userToken] = useAtom(tokenAtom);
  const [, setIsAdminMode] = useAtom(isAdminModeAtom);

  const imgRef = useRef<HTMLInputElement>(null);

  // 전체 Home 슬라이드 데이터
  const [homeSlideImageData, setHomeSlideImageData] = useState<ResultsType | null>(null);

  // 이미지 추가 모드
  const [isCreateSlideImages, setIsCreateSlideImages] = useState(false);
  const [isVisibleCreateInput, setIsVisibleCreateInput] = useState(false);

  // 이미지 수정 모드
  const [isEditSlideImages, setIsEditSlideImages] = useState(false);
  const [selectedEditImageId, setSelectedEditImageId] = useState<number | null>(null);

  // 이미지 삭제 모드
  const [isDeleteSlideImages, setIsDeleteSlideImages] = useState(false);
  const [selectedDeleteId, setSelectedDeltedId] = useState<number | null>(null);

  // 이미지 미리보기 (이미지 하나일 때)
  const [singleFileUrl, setSingleFileUrl] = useState<string>('');

  // 이미지 검증할 때 formData를 위한 state
  const [image, setImage] = useState<any>();
  const [fileUrl, setFileUrl] = useState<string[]>([]);

  // 슬라이드 이미지 불러오기
  const handleQueryHomeImages = async () => {
    try {
      const response = await client.get('api/v1/portfolios/portfolio/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      let resultsWithMCategories: ResultsType = {};

      Object.keys(response.data.results2).forEach((key: string) => {
        let filteredResults = response.data.results2[key].filter(
          (item: PortfolioDataType) => item.portfolio_id === 1 && item.is_cancel === 'N',
        ); // 카테고리마다 portfolio_id가 다름
        if (filteredResults.length > 0) {
          resultsWithMCategories[key] = filteredResults;
        }
      });

      setHomeSlideImageData(resultsWithMCategories);
    } catch (error) {
      console.error(error);
    }
  };

  // 슬라이드 이미지 업로드
  const handleCreateImage = async () => {
    try {
      const formData = new FormData();
      formData.append('image', image);
      formData.append('tag', 'HOME'); // tag는 페이지에 따라 다르게 입력

      // 이미지 검증 및 스토리지 저장
      const response = await client.post('api/v1/images/image/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      const newFileUrl = response.data.img_url;
      setFileUrl(newFileUrl);

      const createImageData = {
        category: 'HOME',
        img_url: newFileUrl,
        direction: 'left',
      };

      // 이미지 추가
      await client.post(`api/v1/portfolios/portfolio/`, createImageData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      setIsCreateSlideImages(false);
      handleQueryHomeImages(); // 이미지 추가 후 전체 이미지 불러오기

      message.success({ content: '슬라이드 이미지 추가가 완료되었습니다.' });
    } catch (error) {
      console.error(error);
    }
  };

  // 이미지 미리보기 (이미지 하나일 때 => 추가)
  const onChangeCreateFile = (e: ChangeEvent<HTMLInputElement>) => {
    const isValid = checkValidationImage(e.target.files?.[0]);
    if (!isValid) return;

    try {
      if (imgRef.current?.files) {
        const file = imgRef.current.files[0];
        setImage(file);
        const reader = new FileReader();
        if (e.target.files) {
          reader.readAsDataURL(e.target.files[0]);
          reader.onloadend = () => {
            if (typeof reader.result === 'string') {
              setSingleFileUrl(reader.result);
            }
          };
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 슬라이드 이미지 변경
  const handleHomeImageChange = async () => {
    try {
      const formData = new FormData();
      formData.append('image', image);
      formData.append('tag', 'HOME'); // tag는 페이지에 따라 다르게 입력

      // 이미지 검증 및 스토리지 저장
      const response = await client.post('api/v1/images/image/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      const newFileUrl = response.data.img_url;

      const data = {
        img_url: newFileUrl,
      };

      // 이미지 수정
      await client.post(`api/v1/portfolios/portfolio/${selectedEditImageId}/`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      handleQueryHomeImages();
      setIsEditSlideImages(false);

      message.success({ content: '이미지 수정이 완료되었습니다.' });
    } catch (error) {
      console.error(error);
    }
  };

  // 슬라이드 이미지 삭제
  const handleImageDelete = async () => {
    try {
      await client.delete(`api/v1/portfolios/portfolio/${selectedDeleteId}/`, {
        data: { id: selectedDeleteId },
        headers: {
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      setIsDeleteSlideImages(false);
      handleQueryHomeImages();

      message.success({ content: '선택하신 이미지가 삭제되었습니다.' });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsAdminMode('true');
  }, []);

  // 페이지 진입 시 데이터 불러오기
  useEffect(() => {
    handleQueryHomeImages();
  }, []);

  return (
    <>
      <Helmet>
        <title>눈부신 일상</title>
      </Helmet>

      {!isCreateSlideImages && !isEditSlideImages && !isDeleteSlideImages && (
        <Containter>
          <MainImageWrapper>
            {homeSlideImageData && homeSlideImageData[1] ? (
              <SwiperWrapper
                centeredSlides={true}
                modules={[Autoplay, EffectFade]}
                spaceBetween={50}
                slidesPerView={1}
                speed={2000}
                autoplay={{
                  delay: 1000,
                }}
                effect={'fade'}
                fadeEffect={{ crossFade: true }}
              >
                {homeSlideImageData[1].map((item: PortfolioDataType) => (
                  <SwiperSlide key={item.id}>
                    <MainImage src={item.img_url} alt="홈페이지 이미지" />
                  </SwiperSlide>
                ))}
              </SwiperWrapper>
            ) : (
              <p>슬라이드 이미지가 없습니다.</p>
            )}
          </MainImageWrapper>
          <ButtonWrapper>
            <ImageCreateButton onClick={() => setIsCreateSlideImages(true)}>이미지 추가</ImageCreateButton>
            <ImageEditButton onClick={() => setIsEditSlideImages(true)}>이미지 수정</ImageEditButton>
            <ImageDeleteButton onClick={() => setIsDeleteSlideImages(true)}>이미지 삭제</ImageDeleteButton>
          </ButtonWrapper>
        </Containter>
      )}

      {/* 이미지 추가 모드 */}
      {isCreateSlideImages && !isEditSlideImages && !isDeleteSlideImages && (
        <CreateModeContainer>
          <CreateModeWrapper>
            <h2>Home 슬라이드 이미지 추가</h2>
            <DescriptionSpan>
              슬라이드에 추가할 이미지를 선택해주세요. 저장 시 슬라이드에 바로 적용됩니다.
            </DescriptionSpan>

            <CreateImage src={singleFileUrl ? singleFileUrl : image} />
            <ImageCreateInput
              type="file"
              ref={imgRef}
              accept="image/*"
              onChange={onChangeCreateFile}
              onClick={() => setIsVisibleCreateInput(false)}
            />
            <ButtonWrapper style={{ width: '15rem' }}>
              <ImageCreateButton
                onClick={handleCreateImage}
                style={{ width: '6rem', backgroundColor: '#000', color: '#fff' }}
              >
                저장
              </ImageCreateButton>
              <ImageCancelButton
                onClick={() => {
                  setIsCreateSlideImages(false);
                  // 이미지 input 비우기
                  setImage(null);
                  setSingleFileUrl('');
                  if (imgRef.current) {
                    imgRef.current.value = '';
                  }
                }}
              >
                취소
              </ImageCancelButton>
            </ButtonWrapper>
          </CreateModeWrapper>
        </CreateModeContainer>
      )}

      {/* 이미지 수정 모드 */}
      {isEditSlideImages && !isCreateSlideImages && !isDeleteSlideImages && (
        <EditModeContainer>
          <EditModeWrapper>
            <div>
              <h2>Home 슬라이드 이미지 수정</h2>
              <DescriptionSpan>
                현재 슬라이드에 등록되어 있는 이미지들입니다. 수정할 이미지를 클릭해주세요.
              </DescriptionSpan>
            </div>

            {homeSlideImageData &&
              homeSlideImageData[1].map((item: any) => (
                <SlideImage
                  key={item.id}
                  src={item.img_url}
                  onClick={() => setSelectedEditImageId(item.id)}
                  style={selectedEditImageId === item.id ? { border: '2px solid red' } : {}}
                />
              ))}

            {selectedEditImageId && (
              <EditImageWrapper>
                <EditImageTitle>변경할 이미지</EditImageTitle>
                <SlideImage src={singleFileUrl ? singleFileUrl : image} />
                <input type="file" ref={imgRef} accept="image/*" onChange={onChangeCreateFile} />
              </EditImageWrapper>
            )}

            <ButtonWrapper style={{ width: '15rem' }}>
              <ImageCreateButton
                onClick={handleHomeImageChange}
                style={{ width: '6rem', backgroundColor: '#000', color: '#fff' }}
              >
                저장
              </ImageCreateButton>
              <ImageCancelButton
                onClick={() => {
                  setIsEditSlideImages(false);
                  setSingleFileUrl('');
                  setSelectedEditImageId(null);
                }}
              >
                취소
              </ImageCancelButton>
            </ButtonWrapper>
          </EditModeWrapper>
        </EditModeContainer>
      )}

      {/* 이미지 삭제 모드 */}
      {isDeleteSlideImages && !isCreateSlideImages && !isEditSlideImages && (
        <EditModeContainer>
          <EditModeWrapper>
            <div>
              <h2>Home 슬라이드 이미지 삭제</h2>
              <DescriptionSpan>
                현재 슬라이드에 등록되어 있는 이미지들입니다. 삭제할 이미지를 클릭해주세요.
              </DescriptionSpan>
            </div>

            {homeSlideImageData &&
              homeSlideImageData[1].map((item: any) => (
                <SlideImage
                  key={item.id}
                  src={item.img_url}
                  onClick={() => setSelectedDeltedId(item.id)}
                  style={selectedDeleteId === item.id ? { border: '2px solid red' } : {}}
                />
              ))}

            <ButtonWrapper style={{ width: '15rem' }}>
              <ImageDeleteModeButton onClick={handleImageDelete}>삭제</ImageDeleteModeButton>
              <ImageCancelButton
                onClick={() => {
                  setIsDeleteSlideImages(false);
                  setSingleFileUrl('');
                  setSelectedDeltedId(null);
                }}
              >
                취소
              </ImageCancelButton>
            </ButtonWrapper>
          </EditModeWrapper>
        </EditModeContainer>
      )}
    </>
  );
};

export default AdminHomePage;

const Containter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const SwiperWrapper = styled(Swiper)`
  display: flex;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.lg} {
    width: 69rem;
    height: 43rem;
  }

  ${MEDIA_QUERY.md} {
    width: 69rem;
    height: 43rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 33.4rem;
    height: 20.8rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 25rem;
    height: 15.6rem;
  }
`;

const MainImage = styled.img`
  ${MEDIA_QUERY.lg} {
    width: 69rem;
    height: 43rem;
  }

  ${MEDIA_QUERY.md} {
    width: 69rem;
    height: 43rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 33.4rem;
    height: 20.8rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 25rem;
    height: 15.6rem;
  }
`;

const ButtonWrapper = styled.div`
  width: 26rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 3rem;
`;

const ImageCreateButton = styled.button`
  width: 8rem;
  height: 3rem;
  border: 1px solid #000;
  color: #000;
  font-size: 1.2rem;
  background-color: #fff;
  opacity: 1;
  cursor: pointer;
`;

const ImageEditButton = styled.button`
  width: 8rem;
  height: 3rem;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  background-color: #000;
  opacity: 1;
  cursor: pointer;
`;

const ImageDeleteButton = styled.button`
  width: 8rem;
  height: 3rem;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  background-color: #ce2c30;
  opacity: 1;
  cursor: pointer;
`;

const ImageCancelButton = styled.button`
  width: 6rem;
  height: 3rem;
  border: 1px solid #000;
  margin-right: 1.5rem;
  color: #000;
  background-color: #fff;
  cursor: pointer;
`;

const CreateModeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
`;

const EditModeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
`;

const CreateModeWrapper = styled.div`
  width: 57rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

const EditModeWrapper = styled.div``;

const CreateImage = styled.img`
  width: 32rem;
`;

const ImageCreateInput = styled.input`
  cursor: pointer;
`;

const DescriptionSpan = styled.span`
  margin-bottom: 1rem;
`;

const SlideImage = styled.img`
  width: 15rem;
  margin-right: 1rem;
  cursor: pointer;
`;

const ImageDeleteModeButton = styled.button`
  width: 6rem;
  height: 3rem;
  background-color: #ce2c30;
  color: #fff;
  border: none;
  cursor: pointer;
`;

const EditImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditImageTitle = styled.span`
  margin: 3rem 0 1rem;
  font-family: 'ArgentumSansRegular';
  font-size: 1.4rem;
  font-weight: 700;
`;
