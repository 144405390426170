import React, { useState, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';

const MainPopup = styled.div`
  position: fixed;
  top: 18vh;
  left: 10%;
  background-color: white;
  border: 1px solid #cccccc78;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
  box-sizing: border-box;
  max-width:400px;
`;

const CloseButton = styled.button`
    font-family: 'NanumSquare';
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    cursor: pointer;
    flex: 1;
`;

const PopupContent = styled.div`
`;

const CheckboxLabel = styled.label`
    font-family: 'NanumSquare';
    display: flex;
    gap: 10px;
    align-items: center;
    flex: 5;
    justify-content: center;
`;

const MainImg = styled.div`
    width: 100%;
    max-width: 300px;
    overflow: hidden;

    img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
`;


const FlexWrap = styled.div`
    display: flex;
    align-item: center;
    justify-content: space-around;
    border-top: 1px solid #ccc;
    padding: 4px 10px;
    font-size: 1.1rem;
`;

interface MainPopupProps {
    img: string;
}

const MainPopupComponent: React.FC<MainPopupProps> = ({ img }) => {
  const [showPopup, setShowPopup] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const hidePopupPreference = localStorage.getItem('nonePopup');
    if (hidePopupPreference) {
      const hidePopupUntil = new Date(hidePopupPreference);
      if (hidePopupUntil > new Date()) {
        setShowPopup(false);
      }
    }
  }, []);

  const handleClose = () => {
    setShowPopup(false);
    if (isChecked) {
      const OneDaysFromNow = new Date();
      OneDaysFromNow.setDate(OneDaysFromNow.getDate() + 1);
      localStorage.setItem('nonePopup', OneDaysFromNow.toISOString());
    }
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  return (
    <>
      {showPopup && (
        <MainPopup>
            <PopupContent>
                <MainImg>{img ? <img src={img} alt={img} /> : ""}</MainImg>
            </PopupContent>
            <FlexWrap>
                <CheckboxLabel>
                    <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    />
                    하루 동안 안보기
                </CheckboxLabel>
                <CloseButton onClick={handleClose}>닫기</CloseButton>
            </FlexWrap>
        </MainPopup>
      )}
    </>
  );
};

export default MainPopupComponent;
