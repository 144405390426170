import { Routes, Route } from 'react-router-dom';
import { withAuth } from '../components/common/withAuth';

import HomePage from '../pages/Home';
import IlsangPage from '../pages/About/Ilsang';
import PageMountTransition from '../components/common/PageMountTransition';
import TipsPage from '../pages/About/tips';
import MaternityNewbornPage from '../pages/Portfolio/maternityNewborn';
import BabyFamilyPage from '../pages/Portfolio/babyFamily';

import ProfileJuniorPage from '../pages/Portfolio/profileJunior';
import FamilyPage from '../pages/Portfolio/family';
import ProfileEventPhotographyPage from '../pages/Portfolio/profileEventPhotography';
import ProductPage from '../pages/Portfolio/product';
import PricePage from '../pages/Price';
import ContactPage from '../pages/Contact';
import PartnershipsPage from '../pages/Contact/partnerships';

import AdminLoginForm from '../components/admin/Login';
import AdminHomePage from '../pages/admin';
import AdminSignUpPage from '../pages/Signup';
import AdminTipsPage from '../pages/admin/About/tips';
import AdminPricePage from '../pages/admin/Price';

import AdminIlsangPage from '../pages/admin/About/ilsang';
import BranchesPage from '../pages/Branches';
import AdminBranchesPage from '../pages/admin/Branches';
import AdminFranchiseePage from '../pages/admin/Contact/franchisee';
import FranchiseePage from '../pages/Contact/franchisee';
import AdminContactPage from '../pages/admin/Contact';
import AdminPartnershipsPage from '../pages/admin/Contact/partnerships';
import ContractDetailPage from '../components/admin/ContractDetail';
import ReservationDetailPage from '../components/admin/ReservationDetail';

import AdminBabyFamilyPage from '../pages/admin/Portfolio/babyFamily';
import AdminMaternityNewbornPage from '../pages/admin/Portfolio/maternityNewborn';
import AdminFamilyPage from '../pages/admin/Portfolio/family';
import AdminProfileJuniorPage from '../pages/admin/Portfolio/profileJunior';
import AdminEventPhotographyPage from '../pages/admin/Portfolio/eventPhotography';
import AdminProductPage from '../pages/admin/Portfolio/product';
import FranchiseeMainPage from '../pages/Contact/franchisee/main';
import FranchiseeMain from '../pages/Contact/franchisee/FranchiseeMainPage';


const MainRoutes = () => {
  /* <-----메인 Routes-----> */

  const HomePageWithTransition = PageMountTransition(HomePage);

  // About
  const IlsangPageWithTransition = PageMountTransition(IlsangPage);
  const TipsPageWithTransition = PageMountTransition(TipsPage);

  // Portfolio
  const BabyFamilyPageWithTransition = PageMountTransition(BabyFamilyPage);
  const MaternityNewbornPageWithTransition = PageMountTransition(MaternityNewbornPage);
  const ProfileJuniorPageWithTransition = PageMountTransition(ProfileJuniorPage);
  const FamilyPageWithTransition = PageMountTransition(FamilyPage);
  const ProfileEventPhotographyPageWithTransition = PageMountTransition(ProfileEventPhotographyPage);
  const ProductPageWithTransition = PageMountTransition(ProductPage);

  // price
  const PricePageWithTransition = PageMountTransition(PricePage);

  // branches
  const BranchesPageWithTransition = PageMountTransition(BranchesPage);

  // contact
  const ContactPageWithTransition = PageMountTransition(ContactPage);
  const PartnershipsPageWithTransition = PageMountTransition(PartnershipsPage);

  /* <-----관리자 Routes-----> */

  // About
  const AdminIlsangPageWithTransition = withAuth(AdminIlsangPage);
  const AdminTipsPageWithTransition = withAuth(AdminTipsPage);

  // Portfolio
  const AdminBabyFamilyPageWithTransition = withAuth(AdminBabyFamilyPage);
  const AdminMaternityNewbornPageWithTransition = withAuth(AdminMaternityNewbornPage);
  const AdminProfileJuniorPageWithTransition = withAuth(AdminProfileJuniorPage);
  const AdminFamilyPageWithTransition = withAuth(AdminFamilyPage);
  const AdminEventPhotographyPageWithTransition = withAuth(AdminEventPhotographyPage);
  const AdminProductPageWithTransition = withAuth(AdminProductPage);

  // price
  const AdminPricePageWithTransition = withAuth(AdminPricePage);

  // branches
  const AdminBranchesPageWithTransition = withAuth(AdminBranchesPage);

  // contact
  const AdminContactPageWithTransition = withAuth(AdminContactPage);
  const AdminPartnershipsPageWithTransition = withAuth(AdminPartnershipsPage);
  const AdminFranchiseePageWithTransition = withAuth(AdminFranchiseePage);

  const AdminContractDetailPageWithTransition = withAuth(ContractDetailPage);
  const AdminReservationDetailPageWithTransition = withAuth(ReservationDetailPage);

  return (
    <Routes>
      <Route path="/" element={<HomePageWithTransition />} />
      <Route path="/about/ilsang" element={<IlsangPageWithTransition />} />
      <Route path="/about/tips" element={<TipsPageWithTransition />} />
      <Route path="/portfolio/baby/family" element={<BabyFamilyPageWithTransition />} />
      <Route path="/portfolio/maternity/newborn" element={<MaternityNewbornPageWithTransition />} />
      <Route path="/portfolio/profile/junior" element={<ProfileJuniorPageWithTransition />} />
      <Route path="/portfolio/family" element={<FamilyPageWithTransition />} />
      <Route path="/portfolio/profile/event/photography" element={<ProfileEventPhotographyPageWithTransition />} />
      <Route path="/portfolio/product" element={<ProductPageWithTransition />} />
      <Route path="/price" element={<PricePageWithTransition />} />
      <Route path="/branches/korea" element={<BranchesPageWithTransition />} />
      <Route path="/contact" element={<ContactPageWithTransition />} />
      <Route path="/contact/partnerships" element={<PartnershipsPageWithTransition />} />
      <Route path="/contact/franchisee" element={<FranchiseePage />} />

      {/* 관리자 */}
      <Route path="/ilsang-admin" element={<AdminLoginForm />} />
      <Route path="/ilsang-admin/home" element={<AdminHomePage />} />
      <Route path="/ilsang-admin/signup" element={<AdminSignUpPage />} />
      <Route path="/ilsang-admin/about/ilsang" element={<AdminIlsangPageWithTransition />} />
      <Route path="/ilsang-admin/about/tips" element={<AdminTipsPageWithTransition />} />
      <Route path="/ilsang-admin/portfolio/baby/family" element={<AdminBabyFamilyPageWithTransition />} />
      <Route path="/ilsang-admin/portfolio/maternity/newborn" element={<AdminMaternityNewbornPageWithTransition />} />
      <Route path="/ilsang-admin/portfolio/profile/junior" element={<AdminProfileJuniorPageWithTransition />} />
      <Route path="/ilsang-admin/portfolio/family" element={<AdminFamilyPageWithTransition />} />
      <Route
        path="/ilsang-admin/portfolio/profile/event/photography"
        element={<AdminEventPhotographyPageWithTransition />}
      />
      <Route path="/ilsang-admin/portfolio/product" element={<AdminProductPageWithTransition />} />
      <Route path="/ilsang-admin/price" element={<AdminPricePageWithTransition />} />
      <Route path="/ilsang-admin/branches/korea" element={<AdminBranchesPageWithTransition />} />
      <Route path="/ilsang-admin/contact" element={<AdminContactPageWithTransition />} />
      <Route path="/ilsang-admin/contact/detail" element={<AdminReservationDetailPageWithTransition />} />
      <Route path="/ilsang-admin/contact/partnerships" element={<AdminPartnershipsPageWithTransition />} />
      <Route path="/ilsang-admin/contact/partnerships/detail" element={<AdminContractDetailPageWithTransition />} />
      <Route path="/ilsang-admin/contact/franchisee" element={<AdminFranchiseePageWithTransition />} />
    
    </Routes>
  );
};

export default MainRoutes;
